<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/*@font-face {
  font-family: iranYekan_medium;
  src: url("/src/assets/fonts/iranyekanwebregularfanum.ttf");
}*/
body {
  background: #f6f7fb !important;
  font-family: iranYekan_medium !important;
  font-size: 16px;
  font-weight: 400;
  color: #34495e !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  direction: rtl;
}

/*** global button style begin ***/

textarea:hover,
.form-control:hover,
textarea:active,
.form-control:active,
textarea:focus,
.form-control:focus,
button:focus,
button:active,
button:hover,
label:focus,
form select:hover,
form select:active,
form select:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
input[type="tel"]{
 border : none; 
 border-bottom : 2px solid #A3A3A3 !important;
 width:50px;
 transition : all 0.3s;
}
input[type="tel"]:focus{
  border : none; 
  border-bottom : 2px solid #1abc9c !important;
}
input[type="tel"]:focus-visible{
  outline:none;
}
.resendCode-text span{
  cursor: pointer;
}
.row {
  margin-right: 0px !important;
}
.fit-size{
    width: fit-content;
    height: fit-content;
    cursor: pointer;
}
.attach-preview-box{
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    margin: 25px 0;
}
.rounded-min {
  border-radius: 1.3rem !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}
form .desc-alert-box button[aria-label="Close"],
.noData-alert-box button[aria-label="Close"]{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


form .desc-alert-box{
  padding: 12px;
  font-size: 13px;
}
.first-style {
  background-color: #1abc9c !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.first-style-off {
  background-color: #dbdbdb !important;
  color: #828282 !important;
  border-radius: 5px !important;
}
.noneBg-1-style {
  background-color: transparent !important;
  color: #1abc9c !important;
  border: 2px solid #1abc9c !important;
  border-radius: 5px;
}
.noneBg-2-style {
  background-color: transparent !important;
  color: #1abc9c !important;
  border: 2px solid #1abc9c !important;
  border-radius: 5px;
}
.noneBg-2-err-style {
  background-color: transparent !important;
  color: #FF6D7A !important;
  border: 2px solid #FF6D7A !important;
  border-radius: 5px;
}
.disabled-exportBtn{
    pointer-events: none; 
    cursor: default; 
    opacity: 0.6;
}
.break-passage{
  word-break: break-all;
}
.cancel-btn {
  background-color: transparent !important;
  border: 1.5px solid #34495e !important;
  color: #34495e !important;
  border-radius: 5px !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 991px){
  html,body{
    overflow-x: hidden;
  }
}
/*** global button style end ***/
</style>
