<template>
  <div class="container-fluid" dir="rtl">
    <div class="row ms-0">
      <dashBoard_sideBar />
      <div class="col-lg-9 col-12 main-content bg-white mt-3 px-0 rounded-min" :style="(noData) ? 'height : 95vh' : false">
        <!--no data alert box begin-->
        <b-alert v-if="noData" show dismissible fade variant="primary" class="d-flex align-items-center noData-alert-box m-3">
            <p class="mb-0">داده ای برای نمایش وجود ندارد</p>
        </b-alert>
        <!--no data alert box end-->
        <div v-for="(req, index) in users_request" :key="index"
        class="row border-1 align-items-center rounded-row p-2 mx-1 mx-lg-5 my-3"
        :class="(req.status == 'support_reply') ? 'unread-ticket' : false" v-show="users_request.length != 0">
            <div class="col-12 col-md-1 text-center text-md-end  my-2">
                <img v-if="req.createdBy.avatar" class="profile-img" :src="req.createdBy.avatar" />
                <img v-if="!req.createdBy.avatar" class="profile-img" :src="require('@/assets/images/forgotPassword-idPic.png')" />
            </div>
            <div class="col-12 col-md-3 text-center text-md-end px-0 my-2">
                <div class="border-start">
                    <span class="d-inline-block fs-12 fw-300">تاریخ:</span>
                    <span class="d-inline-block fs-12 fw-500 me-1">{{ new Date(req.createdAt).toLocaleString('fa-IR') }}</span>
                </div>
            </div>
            <div class="col-12 col-md-3 text-center text-md-end px-1 my-3">
                <div class="border-start">
                    <span class="d-inline-block fs-12 fw-300">دسته بندی:</span>
                    <span class="d-inline-block fs-12 fw-500 me-1">{{ req.category.title }}</span>
                </div>
            </div>
            <div class="col-12 col-md-3 my-3 text-center text-md-end">
                <p class="text fs-12 fw-500">
                    <span class="fs-12 fw-300">موضوع:</span>
                    {{ req.title }}
                </p>
                <p class="text fs-12 fw-500">
                    <span class="fs-12 fw-300">شناسه:</span>
                    {{ req.id }}
                </p>
            </div>
            <div class="col-12 col-md-2 text-center">
                <router-link :to="{ name : 'supportMesaasgeDetailPageRoute', params: { msg_id : req.id } }">
                    <button class="btn btn-primary border-0">مشاهده تیکت</button>
                </router-link>
            </div>
        </div>
      <b-pagination v-if="paginationFlag" class=" justify-content-center" pills v-model="currentPage" :total-rows="pages" :per-page="perPage" first-number last-number @change="changePage" ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";

export default {
  name: "supportMessagesPage",
  components: {
    dashBoard_sideBar,
  },
  data() {
    return {
      pages: 0,
      perPage: 0,
      currentPage: 0,
      users_request : [],
      noData:false,
      paginationFlag:false,
    }
  },
  methods: {
      async getData(page){

      const jwt = localStorage.getItem('jwt');
      let params = {
          pageIndex: page,
          
        };
      let self = this;
      await this.$http({
        method: 'get',
        url: '/api/ticket',
        params: params,
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.users_request = data.data;
              if(this.users_request.length == 0){
                this.noData = true;
                this.paginationFlag = false;
              }else{
                self.currentPage = data.pageIndex;
                self.perPage = data.pageSize;
                self.pages = data.itemsCount;
                this.paginationFlag = true;
              }
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    changePage(page){
      this.getData(page);
      this.currentPage = page;
    }
  },
  beforeMount() {
      this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fs-12 {
  font-size: 0.75rem !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-800 {
  font-weight: 800;
}
</style>
<style scoped>
.rounded-min {
  border-radius: 1.25rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.unread-ticket{
  background-color: #1abc9c5c;
}

.rounded-row {
  border: 1.5px solid #f0f0f0 !important;
  border-radius: 5px;
}

.btn-load-outline,
.btn-load-outline:hover {
  border-color: #1abc9c;
  color: #1abc9c;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.btn-primary {
  background-color: #1abc9c !important;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px !important;
}

.profile-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}

@media (max-width: 768px) {
  div.border-start {
    border: none !important;
  }
}
</style>
