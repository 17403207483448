<template>

<div class="container-fluid px-0">
    <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
        <p class="mb-0">
            {{ $t('serverMessages.'+alertMessage) }}
        </p>
    </div>
    <div class="row align-items-center">
        
        <!--click rate input begin-->
        <div class="col-12 col-md-6">
            <div class="row">
                <h3 class="h3 me-1">نرخ کلیک</h3>
            </div>

            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.click_price.optionValue,formsDefault_values.click_price.id);
                    sendData(formsDefault_values.view_price.optionValue,formsDefault_values.view_price.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <label for="clickrateId" class="mb-2">نرخ کلیک</label>

                                <div class="input-group mb-3">
                                    <input type="text" id="clickrateId" class="form-control" dir="ltr"
                                    @input="float_number_validation($refs.AS_click,$refs.AS_click_error,$i18n.locale)"
                                    @focus="float_number_validation($refs.AS_click,$refs.AS_click_error,$i18n.locale)"
                                    ref="AS_click" v-model="formsDefault_values.click_price.optionValue"/>
                                    <span class="inline-right-icons border-0">تتر</span>
                                </div>
                                <span class="text-danger" ref="AS_click_error"></span>
                            </div>

                            <div class="form-group col-12">
                                <label for="visitrateId" class="mb-2">نرخ بازدید</label>

                                <div class="input-group mb-2">
                                    <input id="visitrateId" type="text" class="form-control" dir="ltr"
                                    @input="number_validation($refs.AS_view,$refs.AS_view_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_view,$refs.AS_view_error,$i18n.locale)"
                                    ref="AS_view" v-model="formsDefault_values.view_price.optionValue"
                                    />
                                    <span class="inline-right-icons border-0">تتر</span>
                                </div>
                                <span class="text-danger" ref="AS_view_error"></span>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--click rate input end-->
        
        <!--visit input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4 mt-sm-0">
                <h3 class="h3 me-1">بازدید</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.min_view.optionValue,formsDefault_values.min_view.id)">
                        <div class="row mt-4">
                            <div class="form-group col-12">
                                <div class="input-group mt-5">
                                    <select class="form-control mb-3" id="selecttimeId" disabled>
                                        <option value="month">ماهانه</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="visitminId" class="mb-2">حداقل بازدید</label>

                                <div class="input-group mb-2">
                                    <input type="text" id="visitminId" class="form-control" dir="ltr"
                                    @input="number_validation($refs.AS_minView,$refs.AS_minView_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_minView,$refs.AS_minView_error,$i18n.locale)"
                                    ref="AS_minView" v-model="formsDefault_values.min_view.optionValue"
                                    />
                                </div>
                                <span class="text-danger" ref="AS_minView_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--visit input end-->
        
        <!--harvest ceiling adjustment input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4">
                <h3 class="h3 me-1">تنظیم سقف برداشت</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.max_withdraw.optionValue,formsDefault_values.max_withdraw.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <div class="input-group mt-5">
                                    <select class="form-control mb-3" id="selecttimeId" disabled>
                                        <option value="month">ماهانه</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="maxwithdrawId" class="mb-2">سقف برداشت</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="maxwithdrawId"
                                    @input="number_validation($refs.AS_maxHarvest,$refs.AS_maxHarvest_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_maxHarvest,$refs.AS_maxHarvest_error,$i18n.locale)"
                                    ref="AS_maxHarvest" v-model="formsDefault_values.max_withdraw.optionValue"
                                    />
                                    <span class="inline-right-icons border-0">تتر</span>
                                </div>
                                <span class="text-danger" ref="AS_maxHarvest_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" id="submitmaxwithdrawId" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--harvest ceiling adjustment input end-->

        <!--harvest floor adjustment input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4">
                <h3 class="h3 me-1">تنظیم کف برداشت</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.min_withdraw.optionValue,formsDefault_values.min_withdraw.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <div class="input-group mt-5">
                                    <select class="form-control mb-3" id="selecttimeId" disabled>
                                        <option value="month">ماهانه</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="maxwithdrawId" class="mb-2">کف برداشت</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="maxwithdrawId"
                                    @input="number_validation($refs.AS_minHarvest,$refs.AS_minHarvest_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_minHarvest,$refs.AS_minHarvest_error,$i18n.locale)"
                                    ref="AS_minHarvest" v-model="formsDefault_values.min_withdraw.optionValue"
                                    />
                                    <span class="inline-right-icons border-0">تتر</span>
                                </div>
                                <span class="text-danger" ref="AS_minHarvest_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--harvest floor adjustment input end-->

        <!--taxation adjustment input begin-->
<!--        <div class="col-12 col-md-6">-->
<!--            <div class="row mt-4">-->
<!--                <h3 class="h3 me-1">تنظیم مالیات</h3>-->
<!--            </div>-->
<!--            <div class="container bg-white rounded-min m-1 align-center">-->
<!--                <div class="row justify-content-center">-->
<!--                    <form class="w-75" @submit.prevent="-->
<!--                    sendData(formsDefault_values.wage_percent.optionValue,formsDefault_values.wage_percent.id);-->
<!--                    sendData(formsDefault_values.tax_percent.optionValue,formsDefault_values.tax_percent.id)">-->
<!--                        <div class="row mt-5">-->
<!--                            <div class="form-group col-12">-->
<!--                                <label for="hireId" class="mb-2">اجرت</label>-->

<!--                                <div class="input-group mb-2">-->
<!--                                    <input type="text" class="form-control" dir="ltr" id="hireId"-->
<!--                                    @input="percent_validation($refs.AS_hire,$refs.AS_hire_error,$i18n.locale)"-->
<!--                                    @focus="percent_validation($refs.AS_hire,$refs.AS_hire_error,$i18n.locale)"-->
<!--                                    ref="AS_hire" v-model="formsDefault_values.wage_percent.optionValue"-->
<!--                                    />-->
<!--                                    <span class="inline-right-icons border-0">درصد</span>-->
<!--                                </div>-->
<!--                                <span class="text-danger" ref="AS_hire_error"></span>-->
<!--                            </div>-->

<!--                            <div class="form-group col-12">-->
<!--                                <label for="taxId" class="mb-2">مالیات</label>-->

<!--                                <div class="input-group mb-2">-->
<!--                                    <input type="text" class="form-control" dir="ltr" id="taxId"-->
<!--                                    @input="percent_validation($refs.AS_taxAdjust,$refs.AS_taxAdjust_error,$i18n.locale)"-->
<!--                                    @focus="percent_validation($refs.AS_taxAdjust,$refs.AS_taxAdjust_error,$i18n.locale)"-->
<!--                                    ref="AS_taxAdjust" v-model="formsDefault_values.tax_percent.optionValue"-->
<!--                                    />-->
<!--                                    <span class="inline-right-icons border-0">درصد</span>-->
<!--                                </div>-->
<!--                                <span class="text-danger" ref="AS_taxAdjust_error"></span>-->
<!--                            </div>-->

<!--                            <div class="col-12">-->
<!--                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">-->
<!--                                    ثبت-->
<!--                                </button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!--taxation adjustment input end-->

        <!--time difference of ads input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4">
                <h3 class="h3 me-1">اختلاف زمانی تبلیغ ها</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.distance_view_add.optionValue,formsDefault_values.distance_view_add.id);
                    sendData(formsDefault_values.distance_click_add.optionValue,formsDefault_values.distance_click_add.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <label for="distance_viewId" class="mb-2">براساس بازدید</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="distance_viewId"
                                    @input="number_validation($refs.AS_distView,$refs.AS_distView_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_distView,$refs.AS_distView_error,$i18n.locale)"
                                    ref="AS_distView" v-model="formsDefault_values.distance_view_add.optionValue"
                                    />
                                    <span class="inline-right-icons border-0">دقیقه</span>
                                </div>
                                <span class="text-danger" ref="AS_distView_error"></span>
                            </div>

                            <div class="form-group col-12">
                                <label for="distance_clickId" class="mb-2">براساس کلیک</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="distance_clickId"
                                    @input="percent_validation($refs.AS_distClick,$refs.AS_distClick_error,$i18n.locale)"
                                    @focus="percent_validation($refs.AS_distClick,$refs.AS_distClick_error,$i18n.locale)"
                                    ref="AS_distClick" v-model="formsDefault_values.distance_click_add.optionValue"
                                    />
                                    <span class="inline-right-icons border-0">دقیقه</span>
                                </div>
                                <span class="text-danger" ref="AS_distClick_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--time difference of ads input end-->

        <!--maximum ad views input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4">
                <h3 class="h3 me-1">حداکثر بازدید تبلیغ ها</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.max_view_ad.optionValue,formsDefault_values.max_view_ad.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <div class="input-group mt-5">
                                    <select class="form-control mb-3" id="selecttimeId" disabled>
                                        <option value="month">ماهانه</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="distance_clickId" class="mb-2">حداکثر بازدید</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="distance_clickId"
                                    @input="number_validation($refs.AS_maxView,$refs.AS_maxView_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_maxView,$refs.AS_maxView_error,$i18n.locale)"
                                    ref="AS_maxView" v-model="formsDefault_values.max_view_ad.optionValue"
                                    />
                                </div>
                                <span class="text-danger" ref="AS_maxView_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--maximum ad views input end-->

        <!--maximum ad clicks input begin-->
        <div class="col-12 col-md-6">
            <div class="row mt-4">
                <h3 class="h3 me-1">حداکثر کلیک تبلیغ ها</h3>
            </div>
            <div class="container bg-white rounded-min m-1 align-center">
                <div class="row justify-content-center">
                    <form class="w-75" @submit.prevent="
                    sendData(formsDefault_values.max_click_ad.optionValue,formsDefault_values.max_click_ad.id)">
                        <div class="row mt-5">
                            <div class="form-group col-12">
                                <div class="input-group mt-5">
                                    <select class="form-control mb-3" id="selecttimeId" disabled>
                                        <option value="month">ماهانه</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="distance_clickId" class="mb-2">حداکثر کلیک</label>

                                <div class="input-group mb-2">
                                    <input type="text" class="form-control" dir="ltr" id="distance_clickId"
                                    @input="number_validation($refs.AS_maxClick,$refs.AS_maxClick_error,$i18n.locale)"
                                    @focus="number_validation($refs.AS_maxClick,$refs.AS_maxClick_error,$i18n.locale)"
                                    ref="AS_maxClick" v-model="formsDefault_values.max_click_ad.optionValue"
                                    />
                                </div>
                                <span class="text-danger" ref="AS_maxClick_error"></span>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn py-2 my-2 my-md-0 text-white btn-primary float-start border-0 px-3">
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--maximum ad clicks input end-->
    </div>
</div>

</template>

<script>
import formValidation from "@/../mixins/formValidation.js";

export default {
  name: "adsenseSettingsForm",
  mixins: [formValidation],
  data() {
      return {
          formsDefault_values : {},
          alertMessage:''
      }
  },
  methods: {
    async getData(){
      const jwt = localStorage.getItem('jwt');
      await this.$http({
        method: 'get',
        url: '/api/home/setting',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.formsDefault_values = data.data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    async sendData(optionValue,inputId){
      const jwt = localStorage.getItem('jwt');
      await this.$http({
        method: 'put',
        url: '/api/home/setting/'+inputId+'/edit',
        data: {
            "optionValue" : optionValue,
        },
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              this.alertMessage = '';
              alert("عملیات موفقیت آمیز بود");
            }
          })
          .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
          });
    }
  },
  beforeMount() {
      this.getData();
  }
};
</script>

<style scoped>
.rounded-min {
  border-radius: 1.3rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.form-control {
  /* user-select: none; */
  cursor: pointer;
}

.content-wrapper .form-group .form-control {
  border: 1.5px solid #8d939a;
  box-sizing: border-box;
  border-radius: 5px;
}

form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form .form-group input,
textarea,
select {
  border: 1.5px solid #8d939a !important;
  border-radius: 5px !important;
}

.inline-left-icons {
  margin: 0 0 0 0.7rem !important;
  position: absolute;
  left: 0;
  top: 0.5rem;
  z-index: 10;
  border-right: 1.5px solid #8d939a;
  padding-right: 0.3rem;
}

.inline-right-icons {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  right: 0;
  top: 0.5rem;
  z-index: 10;
  border-left: 1.5px solid #8d939a;
  padding-left: 0.3rem;
}

form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 4rem 0 2.5rem 0 !important;
}

form input.activate {
  background: #f1f1f1;
}

.angle-icon-bottom {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  left: 0;
  top: 0.5rem;
  z-index: 1000;
  padding-left: 0.3rem;
}

.btn-screenshot,
.btn-screenshot:hover {
  background-color: #34495e;
  color: #fafafa;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.form-check-label-left {
  direction: rtl;
  text-align: right;
}


</style>
