<template>
    <div class="container form-container">
        <!--form col begin-->
        <div class="form-box w-50 mr-0 ml-auto">
            <div class="shape-under-form"></div>
            <div class="form">
                <!--forgotPassword form heading begin-->
                <div class="form-heading">
                  <router-link to="signin">
                    <div class="back-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.75 11.7257L4.75 11.7257" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                  </router-link>
                    <div class="title">
                        <h3>{{ $t('newPassword.formTitle') }}</h3>
                    </div>
                    <!--switchLanguage/-->
                </div>
                <div class="form-identifier-photo">
                    <img src="@/assets/images/forgotPassword-idPic.png" alt="forgot-password">
                </div>
                <!--forgotPassword form heading end-->

                <!--forgotPassword form main begin-->
                <form @submit.prevent="forgotPassword_submit($event)" class="form-main text-right">
                  <div class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="alertMessage">
                    <p class="m-2">
                      {{ $t("serverMessages."+alertMessage) }}
                    </p>
                  </div>
                    <div class="form-group">
                        <!--phone number input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline" for="phone-number">{{ $t('inputLabels.mobile') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="phone-number">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z" fill="#34495E"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="phoneNumber_validation($refs.FP_mobile,$refs.FP_mobile_error,$i18n.locale)"
                                @focus="phoneNumber_validation($refs.FP_mobile,$refs.FP_mobile_error,$i18n.locale)"
                                ref="FP_mobile" class="form-control form-input-style" type="text" name="phone-number" placeholder="09xxxxxxxxx" aria-label="Recipient's " aria-describedby="phone-number" v-model="recoveryPhone">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="FP_mobile_error">
                            </span>
                        </div>
                        <!--phone number input end-->

                        <!--forgotPassword btn begin-->
                        <div class="text-right mt-5">
                            <button class="btn btn-style" name="forgot-password" type="submit">{{ $t('newPassword.submitButton') }}</button>
                        </div>
                        <!--forgotPassword btn end-->
                    </div>
                    <!--help text begin-->
                    <p class="text-center help-text">{{ $t('newPassword.helpText_prepend') }}<router-link to="signup">{{ $t('newPassword.helpText_mainLink') }}</router-link> {{ $t('newPassword.helpText_append') }}</p>
                    <!--help text end-->
                </form>
                <!--forgotPassword form main end-->
            </div>
        </div>
        <!--form col end-->
    </div>
</template>

<script>
import './../../assets/scss/formClasses.css'
import './../../assets/scss/langStyle.css'
import formValidation from './../../../mixins/formValidation.js'
//import switchLanguage from './switchLanguage.vue'

export default {
  name: 'forgotPassword',
  mixins: [formValidation],
  components:{
       //switchLanguage,
  },
data() {
    return {
      recoveryPhone: '',
      alertMessage: ''
    }
},
  methods: {
    //================ SUBMIT NEW PASSWORD FORM BEGIN ================//
    forgotPassword_submit(){
        this.FP_inputs = document.querySelectorAll('input');
        this.FP_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.FP_mobile_error.innerText != "")
            return;

      // request with axios for login
      let self = this;
      this.$http({
        method: 'post',
        url: '/change_password/request',
        data: {
          mobile: this.recoveryPhone,
        }
      }).then(function (response) {
        if(response.status == 200) {
          self.alertMessage = '';
          self.$router.push({name:'verifyRoute', params:{phoneNumber:self.recoveryPhone, type: 'recovery'}})
        }
      })
          .catch(function (error) {
            let errors = error.response.data;
            self.alertMessage = errors.msg;
          });
    }
    //================ SUBMIT NEW PASSWORD FORM END ================//
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
