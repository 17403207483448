<template>
  <div class="container-fluid" dir="rtl">
    <div class="row">
      <dashBoard_sideBar/>
      <div class=" col-lg-9 col-12">
        <customTable
            tableType="simple"
            :datePicker="true"
            :pagination="true"
            :nameFilter="false"
            :searchTable="false"
            :showSubsetCount="false"/>
      </div>

    </div>

  </div>
</template>

<script>
import dashBoard_sideBar from './../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue'
import customTable from './../reusable-components/table/customTable.vue'

export default {
  name: 'activityReportPage',
  components: {
    dashBoard_sideBar,
    customTable
  },
  data() {
    return {

    }
  },
  beforeMount() {

  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
