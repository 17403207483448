export default {
    data() {
        return {
            //================ MULTI LANG ERRORS BEGIN ================//
            errors: {
                fa: {
                    notNull: 'این فیلد نباید خالی باشد',
                    invalidFormat: 'فرمت وارد شده صحیح نمی باشد',
                    overflowLength: 'تعداد کاراکتر وارد شده بیش از حد مجاز است',
                    min8Char: 'رمز عبور شما باید حداقل دارای 8 کاراکتر باشد',
                    sepcialChar: 'رمز عبور شما باید حداقل یک کاراکتر ویژه داشته باشد',
                    minOneDigit: 'رمز عبور شما باید حداقل یک رقم داشته باشد',
                    minOne_capitalAlphabet: 'رمز عبور شما باید حداقل دارای یک حرف بزرگ انگلیسی باشد',
                    incompatibility: 'رمز عبور مطابقت ندارد',
                    convertYour_keyboard: 'کیبورد خود را فارسی کنید',
                    unknown_nationalCode: 'کدملی صحیح نمی باشد',
                    mustBe_digit: 'مقدار وارد شده باید فقط عدد باشد',
                    copiedMsg: 'متن موردنظر کپی شد',
                    setPermission: 'حداقل یک دسترسی را باید انتخاب کنید',
                    percentBase: 'مقدار وارد شده باید برحسب درصد باشد(بین 0 تا 100)',
                    mustBe_id: 'کد معرف با 6 رقم خاتمه مییابد',
                    insert_english_num: 'اعداد را باید انگلیسی وارد کنید',
                    notChar: 'این فیلد نباید شامل کاراکترهای ویژه باشد',
                    truePointNum: "به جای / از . استفاده کنید",
                    notFloat: "مجاز به وارد کردن اعداد اعشاری نمی باشید",
                    notZero: "مقدار بیشتر از صفر باید وارد کنید"
                },
                en: {
                    notNull: 'This field should not be empty',
                    invalidFormat: 'The entered format is incorrect',
                    overflowLength: 'The number of characters entered is too much',
                    min8Char: 'Your password must be at least 8 characters long',
                    sepcialChar: 'Your password must have at least one special character',
                    minOneDigit: 'Your password must have at least one digit',
                    minOne_capitalAlphabet: 'Your password must contain at least one uppercase English letter',
                    incompatibility: 'password does not match',
                    convertYour_keyboard: 'Make your keyboard English',
                    unknown_nationalCode: 'The national code is incorrect',
                    mustBe_digit: 'The value entered should be just a number',
                    copiedMsg: 'The text was copied',
                    setPermission: 'You must select at least one access',
                    percentBase: 'The value entered must be in percentage (between 0 and 100)',
                    mustBe_id: 'The identifier code starts with ADS and ends with 6 digits',
                    insert_english_num: 'Numbers must be entered in English',
                    notChar: 'This field should not contain special characters',
                    truePointNum: "Use . instead of /",
                    notFloat: "You are not allowed to enter decimal numbers",
                    notZero: "You must enter a value greater than zero"
                }
            },
            //================ MULTI LANG ERRORS END ================//
            //================ PHONE EMAIL FIELD DATA BEGIN ================//

            phone_exp: new RegExp(/^(\+98|0098|98|0)?9\d{9}$/g),
            email_exp: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            digit_exp: new RegExp(/[0-9]/),
            float_exp: new RegExp(/^[+-]?\d+(\.\d+)?$/),
            persian_digit_exp: new RegExp(/[۰-۹]/),
            uppercase_exp: new RegExp(/[A-Z]/),
            cahr_exp: new RegExp(/\W|_/g),
            //================ PHONE EMAIL FIELD DATA END ================//
            //================ NAME FIELD DATA BEGIN ================//
            persianName_exp: new RegExp(/^[ آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]+$/),
            englishName_exp: new RegExp(/^[ a-zA-Z]+$/),
            alias_exp: new RegExp(/[a-zA-Z0-9آ-ی]/),
            //================ NAME FIELD DATA END ================//
            //================ ID CODE FIELD DATA BEGIN ================//
            number_exp: new RegExp(/^\d+$/),
            id_exp: new RegExp(/^[a-zA-Z]{3}[0-9]{6}$/),
            //================ ID CODE FIELD DATA END ================//
            //username_exp: new RegExp(/^[0-9]*[a-zA-Z0-9]+[0-9]+[a-zA-Z]*$/),
            //username_exp: new RegExp(/^[a-zA-Z0-9]+[a-zA-Z0-9@]*[a-zA-Z0-9.]+[a-zA-Z0-9]+$/),
            username_exp: new RegExp(/^([a-z0-9]|[-._](?![-._])){4,20}$/),
            links_exp: new RegExp(/(https?:\/\/[^ ]*)/)
        }
    },
    methods: {
        //================ BORDER COLOR CONTROLL BEGIN ================//
        switch_border(inputEl, colorFlag) {
            if (colorFlag) {
                // inputEl.parentElement.style.borderColor = "#8D939A";
                inputEl.parentElement.style.setProperty("border", "1px solid #8D939A", "important");
            } else {
                // inputEl.parentElement.style.borderColor = "#dc3545";
                inputEl.parentElement.style.setProperty("border", "1px solid #dc3545", "important");
            }
        },
        //================ BORDER COLOR CONTROLL END ================//
        //================ PHONE EMAIL FIELD BEGIN ================//
        phoneEmail_validate(inputVal, errorMsg, lang) {
            if (inputVal.value != "" && ((this.phone_exp.test(inputVal.value) && inputVal.value.length <= 11) || this.email_exp.test(String(inputVal.value).toLowerCase()))) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.length > 11) {
                errorMsg.innerText = this.errors[lang].overflowLength;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.phone_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            } else if (!this.email_exp.test(String(inputVal.value).toLowerCase()) && this.phone_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }

        },
        //================ PHONE EMAIL FIELD END ================//

        //================ MOBl FIELD BEGIN ================//
        // mobile_validate(inputVal, errorMsg, lang) {

        //     if (inputVal.value == "") {
        //         errorMsg.innerText = this.errors[lang].notNull;
        //         this.switch_border(inputVal, false);
        //     } else if (!this.phone_exp.test(inputVal.value)) {
        //         errorMsg.innerText = this.errors[lang].invalidFormat;
        //         this.switch_border(inputVal, false);
        //     } else if (inputVal.value.length > 11) {
        //         errorMsg.innerText = this.errors[lang].overflowLength;
        //         this.switch_border(inputVal, false);
        //     } else {
        //         errorMsg.innerText = "";
        //         this.switch_border(inputVal, true);
        //     }
        // },
        //================ MOBILE EMAIL FIELD END ================//

        //================ PASSWORD FIELD BEGIN ================//
        password_validate(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.length < 8) {
                errorMsg.innerText = this.errors[lang].min8Char;
                this.switch_border(inputVal, false);
            }
            /*else if (inputVal.value.search(this.cahr_exp) < 0) {
                           errorMsg.innerText = this.errors[lang].sepcialChar;
                           this.switch_border(inputVal, false);
                       }*/
            else if (inputVal.value.search(this.digit_exp) < 0) {
                errorMsg.innerText = this.errors[lang].minOneDigit;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.search(this.uppercase_exp) < 0) {
                errorMsg.innerText = this.errors[lang].minOne_capitalAlphabet;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ PASSWORD FIELD END ================//
        //================ CONFIRM PASSWORD FIELD BEGIN ================//
        confPassword_validate(confPass, pass, errorMsg, lang) {
            if (confPass.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(confPass, false);
            } else if (confPass.value != pass.value) {
                errorMsg.innerText = this.errors[lang].incompatibility;
                this.switch_border(confPass, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(confPass, true);
            }
        },
        //================ CONFIRM PASSWORD FIELD END ================//
        //================ NAME FIELD BEGIN ================//
        name_validate(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (!this.persianName_exp.test(inputVal.value) && lang == 'fa') {
                errorMsg.innerText = this.errors[lang].convertYour_keyboard;
                this.switch_border(inputVal, false);
            } else if (!this.englishName_exp.test(inputVal.value) && lang == 'en') {
                errorMsg.innerText = this.errors[lang].convertYour_keyboard;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ NAME FIELD END ================//
        //================ NATIONAL CODE FIELD BEGIN ================//

        nationalCode_validate(inputVal, errorMsg, lang) {
            // var L = inputVal.value.length;
            // var national_code = ('0000' + inputVal.value).substr(L + 4 - 10);
            // var c = parseInt(national_code.substr(9, 1), 10);
            // var s = 0;
            // for (var i = 0; i < 9; i++)
            //     s += parseInt(national_code.substr(i, 1), 10) * (10 - i);
            // s = s % 11;
            // var result = (s < 2 && c == s) || (s >= 2 && c == (11 - s));
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.number_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_digit;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ NATIONAL CODE FIELD END ================//
        //================ EMAIL FIELD BEGIN ================//
        email_validate(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.email_exp.test(String(inputVal.value).toLowerCase())) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        },
        //================ EMAIL FIELD END ================//
        //================ ID CODE FIELD BEGIN ================//
        idCode_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.id_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_id;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ ID CODE FIELD END ================//
        //================ DEPOSIT FLOAT AMOUNT FIELD BEGIN ================//
        float_number_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.includes('/')) {
                errorMsg.innerText = this.errors[lang].truePointNum;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.float_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_digit;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        float_number_withoutZero_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (Number(inputVal.value) == 0) {
                errorMsg.innerText = this.errors[lang].notZero;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.includes('/')) {
                errorMsg.innerText = this.errors[lang].truePointNum;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.float_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_digit;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ DEPOSIT FLOAT AMOUNT FIELD END ================//
        //================ DEPOSIT AMOUNT FIELD BEGIN ================//
        number_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.includes("/") || inputVal.value.includes(".")) {
                errorMsg.innerText = this.errors[lang].notFloat;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.number_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_digit;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ DEPOSIT AMOUNT FIELD END ================//
        //================ REQUIRED FIELD BEGIN ================//
        required_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ REQUIRED FIELD END ================//
        //================ PHONE NUMBER FIELD BEGIN ================//
        phoneNumber_validation(inputVal, errorMsg, lang) {
            if (inputVal.value != "" && this.phone_exp.test(inputVal.value) && inputVal.value.length <= 11) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (inputVal.value.length > 11) {
                errorMsg.innerText = this.errors[lang].overflowLength;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.phone_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        },
        //================ PHONE NUMBER FIELD END ================//
        //================ BIRTH DAY FIELD BEGIN ================//
        birthDay_validation(inputVal, errorMsg, lang) {
            if (inputVal == "" || inputVal == "انتخاب تاریخ تولد") {
                errorMsg.innerText = this.errors[lang].notNull;
                // this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                // this.switch_border(inputVal, true);
            }
        },
        //================ BIRTH DAY FIELD END ================//
        //================ COPY INPUT VALUE FIELD BEGIN ================//
        copyTextInput(inputVal, alertMsg, lang) {
            var copyText = inputVal;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            alertMsg.innerText = this.errors[lang].copiedMsg;
            setTimeout(() => {
                alertMsg.innerText = '';
            }, 2000);
        },
        //================ COPY INPUT VALUE FIELD END ================//
        //================ WALLET ADDRESS FIELD BEGIN ================//
        walletAddress_validation(inputVal, errorMsg, lang) {
            if (inputVal.value.substr(0, 1) == "T" && inputVal.value.length == 34) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        },
        //================ WALLET ADDRESS FIELD END ================//

        //================ WALLET ADDRESS(NOT REQUIRED) FIELD BEGIN ================//
        walletAddress_validation_optional(inputVal, errorMsg, lang) {
            if ((inputVal.value.substr(0, 1) == "T" && inputVal.value.length == 34) || (inputVal.value == "")) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        },
        //================ WALLET ADDRESS(NOT REQUIRED) FIELD END ================//

        //================ USERNAME FIELD BEGIN ================//
        username_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.username_exp.test(inputVal.value) || this.phone_exp.test(inputVal.value) || this.email_exp.test(String(inputVal.value).toLowerCase())) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        },
        //================ USERNAME FIELD END ================//
        //================ CHECK PERMISSION FIELD BEGIN ================//
        checkPermission(permissionsArray, errorMsg, lang) {
            if (permissionsArray.length == 0) {
                errorMsg.innerText = this.errors[lang].setPermission;
            } else {
                errorMsg.innerText = "";
            }
        },
        //================ CHECK PERMISSION FIELD END ================//
        //================ LINKS FIELD BEGIN ================//
        links_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (!this.links_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ LINKS FIELD END ================//
        //================ PERCENT FIELD BEGIN ================//
        percent_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            } else if (!this.number_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].mustBe_digit;
                this.switch_border(inputVal, false);
            } else if (parseInt(inputVal.value) > 100 || parseInt(inputVal.value) < 0) {
                errorMsg.innerText = this.errors[lang].percentBase;
                this.switch_border(inputVal, false);
            } else {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            }
        },
        //================ PERCENT FIELD END ================//
        //================ CHANGE PASSWORD STATUS BEGIN ================//
        changePasswordStatus(fieldVal, fieldBox, errorMsg) {
            fieldVal = '';
            errorMsg.innerText = "";
            this.switch_border(fieldBox, true);
            return true;
        },
        //================ CHANGE PASSWORD STATUS END ================//
        //================ ALIAS NAME INPUT BEGIN ================//
        aliasName_validation(inputVal, errorMsg, lang) {
            if (inputVal.value == "") {
                errorMsg.innerText = this.errors[lang].notNull;
                this.switch_border(inputVal, false);
            } else if (this.persian_digit_exp.test(inputVal.value)) {
                errorMsg.innerText = this.errors[lang].insert_english_num;
                this.switch_border(inputVal, false);
            }
            // else if (inputVal.value.match(this.number_exp) || inputVal.value.match(this.persianName_exp) || inputVal.value.match(this.englishName_exp)) {
            //     errorMsg.innerText = "";
            //     this.switch_border(inputVal, true);
            // }
            else if (this.alias_exp.test(inputVal.value)) {
                errorMsg.innerText = "";
                this.switch_border(inputVal, true);
            } else {
                errorMsg.innerText = this.errors[lang].invalidFormat;
                this.switch_border(inputVal, false);
            }
        }
        //================ ALIAS NAME INPUT END ================//
    }
}