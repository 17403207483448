<template>
  <div></div>
</template>

<script>
export default {
  name: 'logout',
  data() {
    return {

    }
  },
  mounted(){
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    this.$router.push('signin');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
