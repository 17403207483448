<template>
    <div class="container-fluid" dir="rtl">
        <div class="row">
            <dashBoard_sideBar />
            <div class="col-lg-9 mt-3 col-12 main-content">
                <div class="container-fluid">
                    <!--total users begin-->
                    <div class="row mx-0 mx-md-5" id="superstatuscontainerId">
                        <div class="col-12 col-md-5 bg-white rounded py-3 mb-3">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.459 14.5758C25.5954 14.5758 27.3285 12.8439 27.3285 10.7075C27.3285 8.57106 25.5954 6.83917 23.459 6.83917" stroke="#34495E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M25.2383 18.7797C25.8763 18.8237 26.5106 18.9141 27.1352 19.0547C28.0029 19.2246 29.0467 19.5802 29.4183 20.3588C29.6554 20.8574 29.6554 21.438 29.4183 21.9379C29.0479 22.7164 28.0029 23.0709 27.1352 23.2493"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path d="M8.38713 14.5758C6.25069 14.5758 4.51758 12.8439 4.51758 10.7075C4.51758 8.57106 6.25069 6.83917 8.38713 6.83917" stroke="#34495E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M6.60753 18.7797C5.96953 18.8237 5.33519 18.9141 4.71064 19.0547C3.84286 19.2246 2.79908 19.5802 2.42875 20.3588C2.19042 20.8574 2.19042 21.438 2.42875 21.9379C2.79786 22.7164 3.84286 23.0709 4.71064 23.2493"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.9162 19.6128C20.2453 19.6128 23.9438 20.2679 23.9438 22.8895C23.9438 25.51 20.2698 26.1895 15.9162 26.1895C11.5859 26.1895 7.88867 25.5344 7.88867 22.9128C7.88867 20.2911 11.5627 19.6128 15.9162 19.6128Z"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.9158 15.8732C13.0607 15.8732 10.7715 13.584 10.7715 10.7277C10.7715 7.87257 13.0607 5.58334 15.9158 5.58334C18.7709 5.58334 21.0602 7.87257 21.0602 10.7277C21.0602 13.584 18.7709 15.8732 15.9158 15.8732Z"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="fw-500">تعداد کل کاربران:</span>
                            <span class="fw-800 me-2">{{ systemStatus.total_users }} نفر</span>
                        </div>
                    </div>
                    <!--total users end-->

                    <div class="row text-white contain justify-content-center mx-0 mx-md-4">
                        <!--system benefits begin-->
                        <div class="col-12 col-md-6 fs-24 fw-700 text-center">
                            <div class="container gradiant-1 rounded-min py-5 m-1 mb-1 mb-md-3">
                                <div class="flex-column py-5 text-white justify-content-center">
                                    <div>سود سیستم</div>
                                    <div class="mt-3 lh-base">
                                        <span>{{ systemStatus.system_benefits }}</span>
                                        <span class="fs-12 fw-800">تتر</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--system benefits end-->

                        <!--total inventory begin-->
                        <div class="col-12 col-md-6 text-center">
                            <div class="container gradiant-2 rounded-min py-5 m-1 mb-1 mb-md-3">
                                <div class="flex-column py-5 text-white justify-content-center">
                                    <div>موجودی کل</div>
                                    <div class="mt-3 lh-base">
                                        <span>{{ systemStatus.total_inventory }}</span>
                                        <span class="fs-12 fw-800">تتر</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--total inventory end-->

                        <!--deposit balance begin-->
                        <div class="col-12 col-md-6 text-center">
                          <router-link :to="{ name : 'unpaidUsersPageRoute' }">                        
                            <div class="container gradiant-3 rounded-min py-5 m-1">
                                <div class="flex-column py-5 text-white justify-content-center">
                                    <div>مانده واریز</div>
                                    <div class="mt-3 lh-base">
                                        <span>{{ systemStatus.deposit_balance }}</span>
                                        <span class="fs-12 fw-800">تتر</span>
                                    </div>
                                </div>
                            </div>
                          </router-link>
                        </div>
                        <!--deposit balance end-->

                        <!--deposited begin-->
                        <div class="col-12 col-md-6 text-center">
                          <router-link :to="{ name : 'paidUsersPageRoute' }">
                            <div class="container gradiant-4 rounded-min py-5 m-1">
                                <div class="flex-column py-5 text-white">
                                    <div>واریز شده</div>
                                    <div class="mt-3 lh-base">
                                        <span>{{ systemStatus.deposited }}</span>
                                        <span class="fs-12 fw-800">تتر</span>
                                    </div>
                                </div>
                            </div>
                          </router-link>
                        </div>
                        <!--deposited end-->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";

export default {
  name: "totalIncomePage",
  components: {
    dashBoard_sideBar,
  },
  data() {
    return {
      systemStatus : {}
    }
  },
  methods: {
    async getData() {
      const jwt = localStorage.getItem('jwt');
      await this.$http({
        method: 'get',
        url: '/api/admin/statistics',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.systemStatus = data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }
  },
  beforeMount() {
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
  text-decoration: none;
}
.contain {
  font-size: 1.5rem;
  font-weight: 700;
}

.gradiant-1 {
  background: linear-gradient(
    131.08deg,
    #fc466b 0.75%,
    #ee8544 47.8%,
    #fc466b 100.03%
  );
}

.gradiant-2 {
  background: linear-gradient(256.14deg, #fc466b -0.52%, #3f5efb 98.8%);
}

.gradiant-3 {
  background: linear-gradient(131.53deg, #d7753f 2.94%, #f95064 99.02%);
}

.gradiant-4 {
  background: linear-gradient(254.34deg, #c4457d -1.09%, #455df6 98.12%);
}

.border-2 {
  border: 1.5px solid #8d939a;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.05));
}

@media (max-width: 800px) {
  .gradiant-1 {
    background: linear-gradient(
      188.92deg,
      #415df9 -1.49%,
      #a351af 93.22%
    ) !important;
  }

  .gradiant-2 {
    background: linear-gradient(
      188.92deg,
      #a351af -1.49%,
      #f8466e 93.22%
    ) !important;
  }

  .gradiant-3 {
    background: linear-gradient(
      188.92deg,
      #9d54af -1.49%,
      #f8466e -1.48%,
      #f1774c 93.22%
    ) !important;
  }

  .gradiant-4 {
    background: linear-gradient(
      188.92deg,
      #f1774c -1.49%,
      #fb4a68 93.22%
    ) !important;
  }
}
</style>
