import { render, staticRenderFns } from "./paidUsersPage.vue?vue&type=template&id=b1b0a3b2&scoped=true&"
import script from "./paidUsersPage.vue?vue&type=script&lang=js&"
export * from "./paidUsersPage.vue?vue&type=script&lang=js&"
import style0 from "./paidUsersPage.vue?vue&type=style&index=0&id=b1b0a3b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1b0a3b2",
  null
  
)

export default component.exports