<template>
  <div class="d-flex bg-white p-3 mb-3 rounded-min justify-content-center">
    <div class="row justify-content-center mt-3 pt-2 w-75">
      <form @submit.prevent="sendTicket($event)">
        <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
          <p class="mb-0">
              {{ $t('serverMessages.'+alertMessage) }}
          </p>
        </div>
        <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
            <p class="m-2">
                {{ item.err }}
            </p>
        </div>
        <div class="row mt-2">
          <!--subject input begin-->
          <div class="form-group pe-0 col-md-6">
            <svg
              height="14"
              viewbox="0 0 15 14"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"
                fill="#FF4E4E"
              ></path>
            </svg>
            <label class="mb-2" for="inputEmail4">موضوع</label>
            <div class="input-group mb-2">
              <input
                class="form-control bg-white"
                type="text"
                @input="
                  required_validation(
                    $refs.ST_reportTitle,
                    $refs.ST_reportTitle_error,
                    $i18n.locale
                  )
                "
                @focus="
                  required_validation(
                    $refs.ST_reportTitle,
                    $refs.ST_reportTitle_error,
                    $i18n.locale
                  )
                "
                ref="ST_reportTitle"
                v-model="title"
              />
            </div>
            <span
              class="text-danger inputStatus-msg"
              ref="ST_reportTitle_error"
            >
            </span>
          </div>
          <!--subject input end-->

                <!--choose category input begin-->
                <div class="form-group pe-0 col-md-6">
                    <svg height="14" viewbox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"></path>
                    </svg>
                    <label class="mb-2" for="inputEmail4">انتخاب دسته بندی</label>
                    <div class="input-group mb-2">
                        <select class="form-control bg-white mb-0" id="inlineFormCustomSelect"
                        @change="required_validation($refs.ST_selectAmount,$refs.ST_selectCategory_error,$i18n.locale)"
                        @focus="required_validation($refs.ST_selectAmount,$refs.ST_selectCategory_error,$i18n.locale)"
                        ref="ST_selectAmount" v-model="category">
                            <option v-for="(cat, index) in categories" :key="index" :value="cat.id">
                              {{ cat.title }}
                            </option>
                        </select>
                        <svg class="angle-icon-bottom" height="24" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
                                fill="#8D939A"
                            ></path>
                        </svg>
                    </div>
                    <span class="text-danger inputStatus-msg" ref="ST_selectCategory_error">
                    </span>
                </div>
                <!--choose category input end-->
            </div>

        <!--subject input end-->

        <!--report passage input begin111-->
        <div class="form-group col-12 mt-3 mt-lg-3">
          <svg
            height="14"
            viewbox="0 0 15 14"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z"
              fill="#FF4E4E"
            ></path>
          </svg>
          <label for="inputAddress">متن گزارش</label>

          <div class="input-group mb-2">
            <textarea
              class="form-control bg-white"
              id="inlineFormInputGroup"
              @input="
                required_validation(
                  $refs.ST_reportPassage,
                  $refs.ST_reportPassage_error,
                  $i18n.locale
                )
              "
              @focus="
                required_validation(
                  $refs.ST_reportPassage,
                  $refs.ST_reportPassage_error,
                  $i18n.locale
                )
              "
              ref="ST_reportPassage"
              v-model="description"
            ></textarea>
          </div>
          <span
            class="text-danger inputStatus-msg"
            ref="ST_reportPassage_error"
          >
          </span>
        </div>
        <!--report passage input end-->

        <div class="row justify-content-between pb-3">
            <div class="col-12 col-md-6 text-center pe-0 text-md-end">
                <!--upload file btn begin-->
                <div class="d-flex flex-column justify-content-between">
                    <input class="btn noneBg-1-style btn-font" type="file" id="upload" @change="handleFileUpload( $event )" hidden accept=".png,.jpg,.jpeg,.gif,.svg" />
                    <label for="upload" class="btn noneBg-1-style btn-font fit-size">
                        بارگزاری فایل پیوست
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="11.9004" y1="8" x2="11.9004" y2="19" stroke="#1ABC9C" stroke-width="1.5" />
                            <line x1="17" y1="5.75" x2="7" y2="5.75" stroke="#1ABC9C" stroke-width="1.5" />
                            <path d="M15.7783 11.8891L11.8892 8.00001L8.00015 11.8891" stroke="#1ABC9C" stroke-width="1.5" />
                        </svg>
                    </label>

                    <div v-if="file" class="attach-preview-box">                     
                        <span @click="file = ''" class="fit-size">&times;</span>
                        <img src="" ref="attachFile" class="img-thumbnail h-100"/>
                    </div>
                </div>
                <!--upload file btn end-->
            </div>
            <div class="col-12 col-md-3 text-center text-md-start mt-3 mt-md-2">
                <!--send btn begin-->
                <button class="btn py-2 btn-send first-style border-0" type="submit">
                    ارسال
                </button>
                <!--send btn end-->
            </div>
        </div>

      </form>
    </div>

  <!--modal box begin-->
  <div class="harvest-modal d-flex" v-if="show_success_alert">
    <transition name="fade">
      <div
          class="question-box p-5 d-flex flex-column align-items-center"
      >
        <svg
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_978_4115)">
            <path
                d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z"
                fill="#1ABC9C"
            />
            <path
                d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z"
                fill="#179F84"
            />
            <path
                d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z"
                fill="white"
            />
            <path
                d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z"
                fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_978_4115">
              <rect width="51" height="51" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h5>تیکت شما با موفقیت ارسال شد.</h5>
        <div class="answer-btn-box w-75 d-flex justify-content-around">
          <button
              @click="goToList"
              class="btn first-style"
          >
            متوجه شدم
          </button>
        </div>
      </div>
    </transition>
  </div>
  <!--modal box end-->
</div>



</template>

<script>
import formValidation from'@/../mixins/formValidation.js'
import errorhandler from "@/services/error-service";

export default {
  name: "sendTicketForm",
  props: {
    categories: Array,
  },
  data(){
    return{
      title: '',
      category: '1',
      description: '',
      show_success_alert: false,
      file: '',
      alertMessage:'',
      errorsArray:[]
    }
  },
  mixins: [formValidation],
  methods: {
      goToList(){
        this.$router.push('/support/messages');
      },
      sendTicket(){
        this.ST_inputs = document.querySelectorAll('.form-control');
        this.ST_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.ST_reportTitle_error.innerText != "" ||
        this.$refs.ST_selectCategory_error.innerText != ""||
        this.$refs.ST_reportPassage_error.innerText != "")
            return;
        // request with axios for login
        let self = this;
        let formData = new FormData();
        formData.append('files[]', this.file);
        formData.append('title', this.title);
        formData.append('description', this.description);
        formData.append('category', this.category);
        const jwt = localStorage.getItem("jwt");
        this.$http({
          method: 'post',
          url: '/api/ticket/new',
          data: formData,
          headers: {
            Authorization: "Bearer " + jwt,
            'Content-Type': 'multipart/form-data'
          },
        }).then(function (response) {
          if (response.status == 200) {
            self.alertMessage = '';
            self.errorsArray = [];
            self.show_success_alert = true;
          }
        })
            .catch(function(error) {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
      },
      handleFileUpload(event){
        this.file = event.target.files[0];

        const reader = new FileReader
        reader.onload = e => {
        let imageData = e.target.result;
        this.$refs.attachFile.setAttribute("src",imageData);
        }
        reader.readAsDataURL(this.file)
        this.$emit('input', this.file)
      }
    },
};
</script>
<style >
.desc ul {
  padding: 0;
  width: 70%;
  margin: auto;
}
.desc ul li {
  margin: 10px 0;
  direction: rtl;
  text-align: right;
}

.question-box {
  /* width: 50%; */
  margin: auto;
  background: #fff;
  border-radius: 20px;
}
.success-text,
.question-text {
  font-size: 15px;
}
.harvest-modal {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  right: 0
}
</style>

<style scoped>
form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form .form-group input,
textarea,
select {
  border: none;
}

.form-group .form-control {
  border: none;
  box-sizing: border-box;
  background: transparent !important;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px !important;
}

.angle-icon-bottom {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  left: 0;
  top: 0.5rem;
  z-index: 1000;
  padding-left: 0.3rem;
}

.btn-screenshot,
.btn-screenshot:hover {
  background-color: #34495e !important;
  color: #fafafa;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.rounded-row {
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  line-height: 2rem;
  font-size: 1.25rem;
}

.btn-font {
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin: 0.5rem 0.25rem 0 0 !important;
  padding: 0.6rem 0.6rem 0.6rem 0.6rem !important;
}

.btn-send {
  margin: 0 0 0 0 !important;
  padding: 0.6rem 2rem 0.6rem 2rem !important;
}

@media (max-width: 767px) {
  .w-75 {
    width: 100% !important;
  }
  .question-box {
  width: 90% !important;
  margin: auto;
  background: #fff;
  border-radius: 20px;
}
}
</style>
