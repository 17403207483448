<template>
    <div class="container-fluid">
        <center class="w-75 m-auto">
            <img src="@/assets/images/turnOffVpn.png" width="100%"/>
            <p>درحال حاضر دسترسی به سایت تنها برای کاربران ایران امکان پذیر است.<br>لطفا پروکسی یا vpn خود را خاموش کنید و مجدد تلاش کنید</p>
            <button class="btn first-style">بارگذاری مجدد صفحه</button>
        </center>
    </div>
</template>

<script>
export default {
    name :'turnOffVpnPage',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
