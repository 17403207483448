<template>
    <div class="container-fluid pe-0" dir="rtl">
        <div class="row justify-content-center ms-0">
            <dashBoard_sideBar />
            <div class="col-lg-9 col-12 bg-white rounded-min mt-3 main-content">
              <addAdminForm :adminSpecs="adminSpecsObj"/>
            </div>
        </div>
    </div>
</template>


<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import addAdminForm from "@/components/reusable-components/form/addAdminForm.vue";

export default {
  name: "addAdminPage",
  components: {
    dashBoard_sideBar,
    addAdminForm
  },
  props:{
    adminSpecsObj : Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-group .form-control {
  border: 1.5px solid #8d939a;
  box-sizing: border-box;
  border-radius: 5px;
}

form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form .form-group input,
textarea,
select {
  border: 1.5px solid #8d939a !important;
  border-radius: 5px !important;
}

form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 4rem 0 2.5rem 0 !important;
}

.form-check-label-left {
  direction: rtl;
  text-align: right;
}

.text-wrap {
  word-wrap: break-word;
}

@media screen and (max-width: 992px) {
  .w-75 {
    width: 100% !important;
  }
}
</style>
