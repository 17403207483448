<template>
  <div class="container">
    <radial-progress-bar :diameter="230" :completed-steps="percent" 
    :total-steps="totalSteps" startColor="#FF779B" 
    stopColor="#7A59DD" innerStrokeColor="transparent" 
    :strokeWidth="8" 
    :innerStrokeWidth="10" >
      <div class="wallet-specs text-white">
        <h4>موجودی</h4>
        <p class="my-3">
          <span class="lead">{{ theater }}</span>
          <span>تتر</span>
        </p>
        <p>
          <span class="lead">%{{ percent }}</span>
          <span>تکمیل شده </span>
        </p>
      </div>
    </radial-progress-bar>
  </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";
export default {
  name: "wallet_ballance",
  components: {
    RadialProgressBar,
  },
  props: {
    theater: Number,
    percent: Number,
  },
  data() {
    return {
      totalSteps: 100,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.radial-progress-container {
  margin: auto;
}
.radial-progress-inner {
  width: auto !important;
  height: auto;
  background: linear-gradient(224.34deg, #7a59dd 12.7%, #ff779b 85.62%);
  border-radius: 50%;
  margin: 20px;
  padding-top: 30px;
}
.wallet-specs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
