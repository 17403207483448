<template>
  <div class="container-fluid pe-0" dir="rtl">
    <div class="row">
      <dashBoard_sideBar />
      <div class=" col-lg-9 col-12 ">
        <submitNewLinkForm
        :exactLinkId="linkId"
        :exactLinkTitle = "linkTitle"
        :exactLinkUrl = "linkUrl"
        :exactlinkPicture = "linkPicture"
        :exactLinkType="linkType"/>
      </div>
    </div>
  </div>
</template>

<script>
  import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
  import submitNewLinkForm from "./../reusable-components/form/submitNewLinkForm.vue";

  export default {
    name: "submitNewLinkPage",
    components: {
      dashBoard_sideBar,
      submitNewLinkForm,
    },
    props: {
      linkId : Number,
      linkTitle : String,
      linkUrl : String,
      linkPicture : String,
      linkType : String,
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
