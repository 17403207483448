<template>
  <div class="container-fluid" dir="rtl">
    <div class="row">
      <dashBoard_sideBar />
      <div class="col-lg-9 col-md-12 col-sm-12 main-content mt-3">
        <div v-for="(post, index) in teachingPosts.data" :key="index" class="container bg-white rounded-min mb-3 py-md-3 py-2" >
          <div class="row border-1 align-items-center justify-content-center">
            <img v-if="post.picture" :src="post.picture" class="learn-img col-12 col-lg-2 my-2" />
            <img v-if="!post.picture" :src="require('@/assets/images/forgotPassword-idPic.png')" class="learn-img col-12 col-lg-2 my-2" />

            <div class="col-12 col-lg-7 my-2">
              <span class="fw-800">{{ post.name }}</span>
              <p class="text-justify mt-2 mb-0 lh-base">{{ post.lead }}</p>
            </div>

            <div class="col-12 col-lg-2 justify-content-center d-flex my-3 my-md-0" >
              <router-link :to="{ name: 'teachingDetailPageRoute', params: { post_id: post.id }, }" class="bg-transparent text-primary border-0" >
                مشاهده بیشتر
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M16.4414 5.5405L12.0802 9.90168L16.4414 14.2629" stroke="#1ABC9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.90234 1.00003L1.00069 9.90169L9.90234 18.8033" stroke="#1ABC9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
        <b-pagination class="justify-content-center" pills v-model="currentPage" :total-rows="pages" :per-page="perPage" first-number last-number @change="changePage" ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";

export default {
  name: "teachingPage",
  components: {
    dashBoard_sideBar,
  },
  data() {
    return {
      pages: 0,
      perPage: 0,
      currentPage: 0,
      teachingPosts: {},
    };
  },
  methods: {
    async getData() {
      let self = this;
      const jwt = localStorage.getItem("jwt");
      await this.$http({
        method: "get",
        url: "/api/blog",
        headers: {
          Authorization: "Bearer " + jwt,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            let data = response.data;
            this.teachingPosts = data;
            self.currentPage = data.pageIndex;
            self.perPage = data.pageSize;
            self.pages = data.itemsCount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePage(page) {
      this.getData(page);
      this.currentPage = page;
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
<style scoped>
.rounded-min {
  border-radius: 1.25rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.rounded-row {
  border: 1.5px solid #f0f0f0 !important;
  border-radius: 5px;
}

.btn-load-outline,
.btn-load-outline:hover {
  border-color: #1abc9c;
  color: #1abc9c;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.text-primary {
  color: #1abc9c !important;
  text-decoration: none !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

p.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px !important;
}

.profile-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}

.learn-img {
  width: 14.5rem;
  height: 9.6rem;
  border-radius: 1.25rem;
}
</style>