import VueRouter from 'vue-router'

/********* users components begin *********/

import workDeskPage from './../components/user-pages/workDeskPage.vue'
import financialReportPage from './../components/user-pages/financialReportPage.vue'
import moneyTransformPage from './../components/user-pages/user-inner-pages/moneyTransformPage.vue'
import supportMesaasgeDetailPage from './../components/user-pages/user-inner-pages/supportMesaasgeDetailPage.vue'
import teachingDetailPage from './../components/user-pages/user-inner-pages/teachingDetailPage.vue'
import activityReportPage from './../components/user-pages/activityReportPage.vue'
import supportSendTicketPage from './../components/user-pages/supportSendTicketPage.vue'
import supportMessagesPage from './../components/user-pages/supportMessagesPage.vue'
import subCategoriesPage from './../components/user-pages/subCategoriesPage.vue'
import turnOffVpnPage from './../components/user-pages/turnOffVpnPage.vue'
import settingsPage from './../components/user-pages/settingsPage.vue'
import editAccPage from './../components/user-pages/editAccPage.vue'
import twoStepLoginPage from './../components/user/twoStepLoginPage.vue'
import teachingPage from './../components/user-pages/teachingPage.vue'
import homePage from './../components/home/home.vue'
import signUp from './../components/user/signUp.vue'
import forget from './../components/user/forgotPassword'
import signIn from './../components/user/signIn.vue'
import changePassword from './../components/user/newPassword'
import verify from './../components/user/verificationCode.vue'
import logout from './../components/user/logout.vue'
import completeInfo from './../components/user/compeleteInfo.vue'

/********* users components end *********/

/********* admin components begin *********/

import adminReportsPage from './../components/admin-pages/adminReportsPage.vue'
import submitNewLinkPage from './../components/admin-pages/submitNewLinkPage.vue'
import registeredLinkPage from './../components/admin-pages/registeredLinkPage.vue'
import adminSettingsPage from './../components/admin-pages/adminSettingsPage.vue'
import adminSupportTicketsPage from './../components/admin-pages/adminSupportTicketsPage.vue'
import adminSupportMessagesPage from './../components/admin-pages/adminSupportMessagesPage.vue'
import adminWithdrawalRequestPage from './../components/admin-pages/adminWithdrawalRequestPage.vue'
import adminEditAccPage from './../components/admin-pages/adminEditAccPage.vue'

/********* admin components end *********/

/********* super admin components begin *********/

import adsensSettingsPage from './../components/super-admin-pages/adsensSettingsPage.vue'
import addAdminPage from './../components/super-admin-pages/addAdminPage.vue'
import editAdminPage from './../components/super-admin-pages/editAdminPage.vue'
import totalIncomePage from './../components/super-admin-pages/totalIncomePage.vue'
import superAdminSettingsPage from './../components/super-admin-pages/superAdminSettingsPage.vue'
import superAdminEditAccPage from './../components/super-admin-pages/superAdminEditAccPage.vue'
import storiesOfAdminPage from './../components/super-admin-pages/storiesOfAdminPage.vue'
import unpaidUsersPage from './../components/super-admin-pages/unpaidUsersPage.vue'
import paidUsersPage from './../components/super-admin-pages/paidUsersPage.vue'
/********* super admin components end *********/

let router = new VueRouter({
    // mode: 'history',
    routes: [

        /********* users routes begin *********/

        {
            path: '/',
            name: 'workDeskPageRoute',
            component: workDeskPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/financialReport',
            name: 'financialReportPageRoute',
            component: financialReportPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/activityReport',
            name: 'activityReportPageRoute',
            component: activityReportPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/turnOffVpn',
            name: 'turnOffVpnPageRoute',
            component: turnOffVpnPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/main',
            name: 'homeRoute',
            component: homePage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/signup',
            name: 'signupRoute',
            component: signUp,
            props: true
        },
        {
            path: '/forget-password',
            name: 'forgetPasswordRoute',
            component: forget,
        },
        {
            path: '/verify',
            name: 'verifyRoute',
            component: verify,
            props: true
        },
        {
            path: '/signin',
            name: 'signinRoute',
            component: signIn
        },
        {
            path: '/complete-info',
            name: 'completeInfoRoute',
            component: completeInfo,
            props: true
        },
        {
            path: '/change-password',
            name: 'changePasswordRoute',
            component: changePassword,
            props: true
        },
        {
            path: '/logout',
            name: 'logoutRoute',
            component: logout
        },
        {
            path: '/financialReport/moneyTransform',
            name: 'moneyTransformPageRoute',
            component: moneyTransformPage,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/support/SendTicket',
            name: 'supportSendTicketPageRoute',
            component: supportSendTicketPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/support/messages',
            name: 'supportMessagesPageRoute',
            component: supportMessagesPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/support/messages/message-detail/:msg_id',
            name: 'supportMesaasgeDetailPageRoute',
            component: supportMesaasgeDetailPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/subCategories',
            name: 'subCategoriesPageRoute',
            component: subCategoriesPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            name: 'settingsPageRoute',
            component: settingsPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/edit-account',
            name: 'editAccPageRoute',
            component: editAccPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/two-step-login',
            name: 'twoStepLoginPageRoute',
            component: twoStepLoginPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/teaching',
            name: 'teachingPageRoute',
            component: teachingPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/teaching/teaching-detail/:post_id',
            name: 'teachingDetailPageRoute',
            component: teachingDetailPage,
            meta: {
                requiresAuth: true
            }
        },

        /********* users routes end *********/

        /********* admin routes begin *********/
        {
            path: '/admin/admin-reports',
            name: 'adminReportsPageRoute',
            component: adminReportsPage,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/submit-new-link',
            name: 'submitNewLinkPageRoute',
            component: submitNewLinkPage,
            props: true,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/registered-link',
            name: 'registeredLinkPageRoute',
            component: registeredLinkPage,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/admin-settings',
            name: 'adminSettingsPageRoute',
            component: adminSettingsPage,
            props: true,

            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/edit-account',
            name: 'adminEditAccPageRoute',
            component: adminEditAccPage,
            props: true,

            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/admin-tickets',
            name: 'adminSupportTicketsPageRoute',
            component: adminSupportTicketsPage,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/admin-messages/:msg_id',
            name: 'adminSupportMessagesPageRoute',
            component: adminSupportMessagesPage,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: '/admin/withdrawal-request',
            name: 'adminWithdrawalRequestPageRoute',
            component: adminWithdrawalRequestPage,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        /********* admin routes end *********/

        /********* super admin routes begin *********/
        {
            path: '/super-admin/adsens-settings',
            name: 'adsensSettingsPageRoute',
            component: adsensSettingsPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/submit-new-link',
            name: 'superAdminSubmitNewLinkPageRoute',
            component: submitNewLinkPage,
            props: true,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/registered-link',
            name: 'superAdminRegisteredLinkPageRoute',
            component: registeredLinkPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/admin-reports',
            name: 'superAdminReportsPageRoute',
            component: adminReportsPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/add-admin',
            name: 'addAdminPageRoute',
            component: addAdminPage,
            props: true,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/edit-admin',
            name: 'editAdminPageRoute',
            component: editAdminPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/admin-history',
            name: 'storiesOfAdminPageRoute',
            component: storiesOfAdminPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/admin-settings',
            name: 'superAdminSettingsPageRoute',
            component: adminSettingsPage,
            props: true,

            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/unpaid-users',
            name: 'unpaidUsersPageRoute',
            component: unpaidUsersPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/paid-users',
            name: 'paidUsersPageRoute',
            component: paidUsersPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/total-income',
            name: 'totalIncomePageRoute',
            component: totalIncomePage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/super-admin-settings',
            name: 'superAdminSettingsPageRoute',
            component: superAdminSettingsPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/edit-account',
            name: 'superAdminEditAccPageRoute',
            component: superAdminEditAccPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/admin-tickets',
            name: 'superAdminSupportTicketsPageRoute',
            component: adminSupportTicketsPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/admin-messages/:msg_id',
            name: 'superAdminSupportMessagesPageRoute',
            component: adminSupportMessagesPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },
        {
            path: '/super-admin/withdrawal-request',
            name: 'superAdminWithdrawalRequestPageRoute',
            component: adminWithdrawalRequestPage,
            meta: {
                requiresAuth: true,
                isSuperAdmin: true
            }
        },

        /********* super admin routes end *********/
    ]
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null) {
            next({
                path: '/signin',
                params: { nextUrl: to.fullPath }
            })
        } else {
            // let user = JSON.parse(localStorage.getItem('user'))
            let userRole = localStorage.getItem('userType');
            userRole = userRole.split(',');
            if (to.matched.some(record => record.meta.isAdmin)) {
                if (userRole.includes('ROLE_ADMIN')) {
                    next()
                } else {
                    next({ name: 'adminReportsPageRoute' })
                }
            } else if (to.matched.some(record => record.meta.isSuperAdmin)) {
                if (userRole.includes('ROLE_SUPER_ADMIN')) {
                    next()
                } else {
                    next({ name: 'workDeskPageRoute' })
                }
            } else {
                next()
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem('jwt') == null) {
            next()
        } else {
            next({ name: 'workDeskPageRoute' })
        }
    } else {
        next()
    }
})

export default router