<template>
    <div class="container form-container">
        <!--form col begin-->
        <div class="form-box w-50 mr-0 ml-auto">
            <div class="shape-under-form"></div>
            <div class="form">
                <!--newPassword form heading begin-->
                <div @click="$router.go(-1)" class="form-heading">
                    <div class="back-btn">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.75 11.7257L4.75 11.7257" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="title">
                        <h3>{{ $t('twoStepLogin.secondPassTitle') }}</h3>
                    </div>
                    <!--switchLanguage/-->
                </div>
                <div class="form-identifier-photo">
                    <img src="@/assets/images/forgotPassword-idPic.png" alt="forgot-password">
                </div>
                <!--newPassword form heading end-->

                <!--newPassword form main begin-->
                <form @submit.prevent="newPassword_submit()" class="form-main text-right">
                  <div class="w-100 m-auto text-white text-center py-2 rounded-2 mb-2" :class="alertClass" v-show="alertMessage">
                    <p class="m-2">
                      {{ $t('serverMessages.'+alertMessage) }}
                    </p>
                  </div>
                    <div class="form-group">

                      <!--old password input begin-->
                      <div class="input-box" :class="$i18n.locale+'_align'" v-if="showOldPassword">
                        <label class="d-inline-flex align-items-baseline" for="password">{{ $t('inputLabels.current2step') }}
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                          </svg>
                        </label>
                        <div class="input-group form-InputIcon-gp d-flex align-items-center">
                          <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="password">
                                        <svg v-show="showCurrentPassFlag" @click="showCurrentPass" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      <!--when user clicked here able to see password-->
                                            <svg v-show="hideCurrentPassFlag" @click="hideCurrentPass" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                    </span>
                          </div>
                          <div class="input-divider"></div>
                          <input @input="password_validate($refs.NP_old_password,$refs.NP_old_password_error,$i18n.locale)" ref="NP_old_password" class="form-control form-input-style" type="password" name="oldPassword" aria-label="Recipient's " aria-describedby="oldPassword" v-model="oldPassword">
                        </div>
                        <span class="text-danger inputStatus-msg" ref="NP_old_password_error">
                                {{ $t('errors.notNull') }}
                            </span>
                      </div>
                      <!--password input end-->

                        <!--password input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline" for="password">{{ $t('inputLabels.twoStepPasswored') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="password">
                                        <svg v-show="showPassFlag" @click="showPass()" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <!--when user clicked here able to see password-->
                                            <svg v-show="hidePassFlag" @click="hidePass()" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>                                             
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input @input="password_validate($refs.NP_password,$refs.NP_password_error,$i18n.locale)" ref="NP_password" class="form-control form-input-style" type="password" name="password" aria-label="Recipient's " aria-describedby="password" v-model="password">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="NP_password_error">
                                {{ $t('errors.notNull') }}
                            </span>
                        </div>
                        <!--password input end-->

                        <!--confirmPassword input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline" for="confirmPassword">{{ $t('inputLabels.repeatTwoStepPassword') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="confirmPassword">
                                        <svg v-show="showConfPassFlag" @click="showConfPass()" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <!--when user clicked here able to see password-->
                                            <svg v-show="hideConfPassFlag" @click="hideConfPass()" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>                                   
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input @input="confPassword_validate($refs.NP_confPassword,$refs.NP_password,$refs.NP_confPassword_error,$i18n.locale)" ref="NP_confPassword" class="form-control form-input-style" type="password" name="confirmPassword" aria-label="Recipient's " aria-describedby="confirmPassword" v-model="confirmPassword">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="NP_confPassword_error">
                                {{ $t('errors.notNull') }}
                            </span>
                        </div>
                        <!--confirmPassword input end-->

                        <!--newPassword btn begin-->
                        <div class="text-right">
                            <button class="btn btn-style" name="change-password" type="submit">{{ (showOldPassword) ? 'تغییر رمز عبور دو مرحله ای' : 'تنظیم رمز عبور دو مرحله ای'}}</button>
                        </div>
                        <!--newPassword btn end-->
                    </div>
                </form>
                <!--newPassword form main end-->
            </div>
        </div>
        <!--form col end-->

        <!-- verification code modal begin-->
        <transition name="fade">
          <div class="harvest-modal d-flex" v-if="setFormData">
            <div class="bg-white w-50 m-auto rounded py-3">
              <form class="verificationForm" @submit.prevent="compeleteSendData($event)">
                  <span class="close-verification-form" @click="closeVerificationModal()">&times;</span>
                  <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMsg">
                    <p class="mb-0">
                        {{ $t('serverMessages.'+alertMsg) }}
                    </p>
                  </div>
                  <div class="form-identifier-photo text-center">
                    <img src="@/assets/images/validate-idPic.png" class="p-3" alt="validate">
                  </div>
                  <div class="form-group">
                      <p class="text-center ">
                      <span>کد وارد شده به شماره</span>
                      <span class="mx-2">{{phoneNumber}}</span>
                      <span>را وارد کنید</span>
                      </p>
                      <!--verification code input begin-->
                      <div class="d-flex verification-code-box justify-content-around my-5 w-75 mx-auto" dir="ltr">
                      <input @keyup="goNext($event, 'first')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify1">
                      <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify2">
                      <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify3">
                      <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify4">
                      <input @keyup="goNext($event, 'last')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify5">
                      </div>
                      <!--verification code input end-->

                      <!--two step code input begin-->
                      <p class="text-center" dir="rtl">
                          <span>{{showCounter}}</span>
                      </p>
                      <transition name="fade">
                        <p class="text-center resendCode-text" v-if="timeExpired" @click="isGetVerificationCode();timeExpired = false;">
                            <span>ارسال مجدد کد</span>
                        </p>
                      </transition>
                      <!--two step code input end-->

                      <!--signUp btn begin-->
                      <div class="text-center">
                      <button class="btn first-style" name="submit-money-transfer" type="submit">تایید نهایی</button>
                      </div>
                      <!--signUp btn end-->
                  </div>
              </form>
            </div>
          </div>
        </transition>
        <!-- verification code modal end-->
    </div>
</template>

<script>
import '@/assets/scss/formClasses.css'
import '@/assets/scss/langStyle.css'
import formValidation from './../../../mixins/formValidation.js'

export default {
  name: 'twoStepLoginPage',
  mixins: [formValidation],
    components:{
  },
data() {
    return {
      //================ CURRENT PASSWORD FIELD DATA BEGIN ================//
      showCurrentPassFlag:true,
      hideCurrentPassFlag:false,
      //================ CURRENT PASSWORD FIELD DATA END ================//

      //================ PASSWORD FIELD DATA BEGIN ================//
      showPassFlag:true,
      hidePassFlag:false,
      //================ PASSWORD FIELD DATA END ================//

      //================ CONFIRM PASSWORD FIELD DATA BEGIN ================//
      showConfPassFlag:true,
      hideConfPassFlag:false,
      //================ CONFIRM PASSWORD FIELD DATA END ================//
      password:'',
      confirmPassword:'',
      oldPassword: '',
      showOldPassword: false,
      alertMessage: '',
      alertClass: 'bg-danger',
      phoneNumber: '',

      setFormData:false,
      alertMsg:'',

      verify1: '',
      verify2: '',
      verify3: '',
      verify4: '',
      verify5: '',

      showCounter : '',
      timeExpired :false,
      counterFunction : Number,
    }
},
  mounted() {
    this.showOldPassword = JSON.parse(localStorage.getItem("secondPassword"));
    this.phoneNumber = JSON.parse(localStorage.getItem("user")).mobile;
  },
  methods: {
        closeVerificationModal(){
          this.setFormData = false;
          this.verify1 = '';
          this.verify2 = '';
          this.verify3 = '';
          this.verify4 = '';
          this.verify5 = '';
          clearInterval(this.counterFunction);
        },
         //================ CURRENT PASSWORD FIELD BEGIN ================//
        showCurrentPass() {
            this.showCurrentPassFlag = false;
            this.hideCurrentPassFlag = true;
            this.$refs.NP_old_password.type = "text";
        },
        hideCurrentPass() {
            this.hideCurrentPassFlag = false;
            this.showCurrentPassFlag = true;
            this.$refs.NP_old_password.type = "password";
        },
        //================ CURRENT PASSWORD FIELD END ================//

         //================ PASSWORD FIELD BEGIN ================//
        showPass() {
            this.showPassFlag = false;
            this.hidePassFlag = true;
            this.$refs.NP_password.type = "text";
        },
        hidePass() {
            this.hidePassFlag = false;
            this.showPassFlag = true;
            this.$refs.NP_password.type = "password";
        },
        //================ PASSWORD FIELD END ================//

        //================ CONFIRM PASSWORD FIELD BEGIN ================//
        showConfPass() {
            this.showConfPassFlag = false;
            this.hideConfPassFlag = true;
            this.$refs.NP_confPassword.type = "text";
        },
        hideConfPass() {
            this.hideConfPassFlag = false;
            this.showConfPassFlag = true;
            this.$refs.NP_confPassword.type = "password";
        },
        //================ CONFIRM PASSWORD FIELD END ================//

        //================ SUBMIT NEW PASSWORD FORM BEGIN ================//
        newPassword_submit(){
          if(this.$refs.NP_confPassword_error.innerText == "" || this.$refs.NP_password_error.innerText == "" ||
          this.$refs.NP_old_password_error.innerText == ""){
              this.isGetVerificationCode();
          }
        },
        //================ SUBMIT NEW PASSWORD FORM END ================//
        async isGetVerificationCode(){
          // request with axios for login
          const jwt = localStorage.getItem("jwt");
          let self = this;
          // self.alertMessage = "";
          // set parameters based on what action must do
          let url = this.showOldPassword ? "/api/user/second_password/change/request" : "/api/user/second_password/set/request";
          let method = this.showOldPassword ? "put" : "post";
          let data = {
            secondPassword:{
              password: this.password,
              confirm: this.confirmPassword,
            }
          };
          if(this.showOldPassword)
            data.oldSecondPassword = this.oldPassword;

          this.$http({
            method: method,
            url: url,
            data: data,
            headers: {
              Authorization: "Bearer " + jwt,
            },
          })
              .then(function (response) {
                if (response.status == 200) {
                  self.alertMessage = '';
                  self.setFormData = true;
                  self.countDown(response.data.expire_time*60);
                }
              })
              .catch(function (error) {
                self.alertClass = 'bg-danger';
                let errors = error.response.data;
                self.alertMessage = errors.msg;
              });
        },
        async compeleteSendData(){
          if(this.verify1 != '' && this.verify5 != ''){
              // request with axios for login
              const jwt = localStorage.getItem("jwt");
              let self = this;
              // self.alertMessage = "";
              // set parameters based on what action must do
              let url = this.showOldPassword ? "/api/user/second_password/change" : "/api/user/second_password/set";
              let method = this.showOldPassword ? "put" : "post";
              let data = {
                secondPassword:{
                  password: this.password,
                  confirm: this.confirmPassword,
                },
                code : this.verify1+this.verify2+this.verify3+this.verify4+this.verify5
              };
              if(this.showOldPassword)
                data.oldSecondPassword = this.oldPassword;

              this.$http({
                method: method,
                url: url,
                data: data,
                headers: {
                  Authorization: "Bearer " + jwt,
                },
              })
                  .then(function (response) {
                    if (response.status == 200) {
                      self.showOldPassword = true;
                      self.oldPassword = self.password = self.confirmPassword = ''
                      localStorage.setItem('secondPassword', true)
                      self.alertClass = 'bg-success';
                      let msg = response.data;
                      self.alertMessage = msg.msg;

                      self.setFormData = false;
                      self.$router.push({ name: 'workDeskPageRoute' })
                    }
                  })
                  .catch(function (error) {
                    // self.alertClass = 'bg-danger';
                    let errors = error.response.data;
                    self.alertMsg = errors.message;
                    console.log(error);
                  }); 
          }
        },
        goNext($e, position = null) {
          if ( ($e.keyCode >= 48 && $e.keyCode <= 57) || ($e.keyCode >= 97 && $e.keyCode <= 105) ){
            if(position != 'last')
              $e.target.nextElementSibling.focus();
          } else if ($e.keyCode == 8 || $e.keyCode == 37) {
            if(position != 'first')
              $e.target.previousElementSibling.focus();
          }
        },
        countDown(secs){
          const self = this;
          var timeInSecs = Number(secs);
          var ticker = setInterval( function(){
            var sec = timeInSecs;
            if (sec > 0) {
              timeInSecs = timeInSecs-1; 
            }
            else {
              clearInterval(ticker);
              self.timeExpired = true;
            }
            var mins = Math.floor(sec/60);
            sec = sec%60;

            self.showCounter = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (sec < 10) ? "0" : "" ) + sec;
          }, 1000);
          this.counterFunction = ticker;
        },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.verificationForm{
  position:relative;
}
.close-verification-form{
  position: absolute;
    right: 20px;
    font-size: 30px;
    cursor:pointer;
}
</style>
