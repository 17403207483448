<template>
  <div class="container form-container">
    <!--form col begin-->
    <div class="form-box w-50">
      <div class="shape-under-form"></div>
      <div class="form">
        <!--signIn form heading begin-->
        <div class="form-heading">
          <div class="title">
            <!--h3>ورود</h3-->
            <h3>{{ $t("signIn.formTitle") }}</h3>
          </div>
<!--          <switchLanguage />-->
        </div>

        <div class="form-identifier-photo">
          <img src="../../assets/images/signUp-idPic.png" alt="sign-up" />
        </div>
        <!--signIn form heading end-->

        

        <!--signIn form main begin-->
        <form @submit.prevent="signIn_submit($event)" class="form-main">
          <div class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="alertMessage">
            <p class="m-2">
              {{ $t('serverMessages.'+alertMessage) }}
            </p>
          </div>
          <div class="form-group">
            <!--username input begin-->
            <div class="input-box" :class="$i18n.locale + '_align'">
              <label class="d-inline-flex align-items-baseline" for="phone-number" >{{ $t("inputLabels.username") }}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E" />
                </svg>
              </label>
              <div class="input-group form-InputIcon-gp d-flex align-items-center" >
                <div class="input-group-prepend">
                  <span class="input-group-text input-icon-style" id="phone-number" >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z" fill="#34495E" />
                    </svg>
                  </span>
                </div>
                <div class="input-divider"></div>
                <input @focus=" username_validation( $refs.SI_phoneEmail, $refs.SI_phoneEmail_error, $i18n.locale )"
                 @input=" username_validation( $refs.SI_phoneEmail, $refs.SI_phoneEmail_error, $i18n.locale ) "
                  ref="SI_phoneEmail" class="form-control form-input-style" type="text" name="phone-number"
                   placeholder="09xxxxxxxxx" v-model="username_input" />
              </div>
              <span class="text-danger inputStatus-msg" ref="SI_phoneEmail_error" >
              </span>
            </div>
            <!--username input end-->

            <!--password input begin-->
            <div class="input-box" :class="$i18n.locale + '_align'">
              <label class="d-inline-flex align-items-baseline" for="password" >{{ $t("inputLabels.password") }}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E" />
                </svg>
              </label>
              <div class="input-group form-InputIcon-gp d-flex align-items-center" >
                <div class="input-group-prepend">
                  <span class="input-group-text input-icon-style" id="password">
                    <svg v-show="showPassFlag" @click="showPass" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <!--when user clicked here able to see password-->
                    <svg v-show="hidePassFlag" @click="hidePass" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </div>
                <div class="input-divider"></div>
                <input @input=" password_validate( $refs.SI_password, $refs.SI_password_error, $i18n.locale ) " @focus=" password_validate( $refs.SI_password, $refs.SI_password_error, $i18n.locale ) " ref="SI_password" class="form-control form-input-style" type="password" name="password" v-model="password_input" />
              </div>
              <span class="text-danger inputStatus-msg" ref="SI_password_error">
              </span>
            </div>
            <!--password input end-->

            <!--form rememberMe checkBtn begin-->
            <div class=" form-check-inline mr-0 input-box w-100 d-flex justify-content-between align-items-stretch " :class="$i18n.locale + '_changeRow'" >
              <label class="form-check-label d-flex align-items-stretch" :class="$i18n.locale + '_changeRow'" >
                <input type="checkbox" class="form-check-input" />
                <span>{{ $t("rememberMe.message") }}</span>
              </label>
              <router-link to="forget-password" class="forgot-password">{{ $t("forgotPassword.message") }}</router-link>
            </div>
            <!--form rememberMe checkBtn end-->

            <!--signIn btn begin-->
            <button class="btn btn-style" name="sign-in" type="submit">
              {{ $t("signIn.submitButton") }}
            </button>
            <!--signIn btn end-->
          </div>
          <!--help text begin-->
          <p class="text-center help-text">
            {{ $t("signIn.helpText_prepend") }}
            <router-link to="signup">{{
              $t("signIn.helpText_mainLink")
            }}</router-link>
            {{ $t("signIn.helpText_append") }}
          </p>
          <!--help text end-->
        </form>
        <!--signIn form main end-->
      </div>
    </div>
    <!--form col end-->
  </div>
</template>

<script>
import "../../assets/scss/formClasses.css";
import "../../assets/scss/langStyle.css";
import formValidation from "./../../../mixins/formValidation.js";
// import switchLanguage from "./../login-pages/switchLanguage.vue";

export default {
  name: "signIn",
  mixins: [formValidation],
  components: {
    // switchLanguage,
  },
  props: ['from'],
  data() {
    return {
      //================ PASSWORD FIELD DATA BEGIN ================//
      showPassFlag: true,
      hidePassFlag: false,
      username_input: "",
      password_input: "",
      //================ PASSWORD FIELD DATA END ================//
      alertMessage: "",
    };
  },
  methods: {
    //================ PASSWORD FIELD BEGIN ================//
    showPass() {
      this.showPassFlag = false;
      this.hidePassFlag = true;
      this.$refs.SI_password.type = "text";
    },
    hidePass() {
      this.hidePassFlag = false;
      this.showPassFlag = true;
      this.$refs.SI_password.type = "password";
    },
    //================ PASSWORD FIELD END ================//

    //================ SUBMIT SIGN IN FORM BEGIN ================//
    async signIn_submit() {
      this.SI_inputs = document.querySelectorAll('input');
      this.SI_inputs.forEach(function(item){
          item.focus();
      });
      if (
        this.$refs.SI_phoneEmail_error.innerText == "" ||
        this.$refs.SI_password_error.innerText == ""
      ) {
        // request with axios for login
        let self = this;
        this.$http({
          method: "post",
          url: "/api/login_check",
          data: {
            username: this.username_input,
            password: this.password_input,
          },
        })
          .then(function (response) {
            if (response.status == 200) {
              self.alertMessage = '';
              // let is_admin = response.data.user.is_admin
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("jwt", response.data.token);
              localStorage.setItem("userType", response.data.user_type);
              let userRole = localStorage.getItem('userType');
              userRole = userRole.split(',');
              localStorage.setItem("secondPassword", response.data.has_second_password);

              let userObjLC = JSON.parse(localStorage.getItem("user"))
              let userAccesses = userObjLC.accesses;

              if (localStorage.getItem("jwt") != null) {
                if (self.$route.params.nextUrl != null) {
                  self.$router.push(this.$route.params.nextUrl);
                } else {
                  if(!userRole.includes('ROLE_SUPER_ADMIN') && !userRole.includes('ROLE_ADMIN')){
                    self.$router.push({ name : 'workDeskPageRoute' });
                  }else if (!userRole.includes('ROLE_SUPER_ADMIN')){

                    if(userAccesses.includes('user_list')){
                      self.$router.push({ name : 'adminReportsPageRoute' });
                    }
                    else if (userAccesses.includes('withdraw_list')){
                      self.$router.push({ name : 'adminWithdrawalRequestPageRoute' });
                    }
                    else if (userAccesses.includes('ticket_list')){
                      self.$router.push({ name : 'adminSupportTicketsPageRoute' });
                    }
                    else if (userAccesses.includes('ad_new')){
                      self.$router.push({ name : 'submitNewLinkPageRoute' });
                    }
                    else if (userAccesses.includes('ad_list')){
                      self.$router.push({ name : 'registeredLinkPageRoute' });
                    }
                    else{
                      self.$router.push({ name : 'adminSettingsPageRoute' });
                    }

                  }else{
                    self.$router.push({ name : 'superAdminReportsPageRoute' });
                  }
                }
              }
            }
          })
          .catch(function (error) {
            let errors = error.response.data;
            if(errors.message == 'Invalid credentials.' && errors.code == 401){
              self.alertMessage = 'Invalid credentials'
            }else{
              self.alertMessage = errors.message;
            }
          });
      }
    },
    //================ SUBMIT SIGN IN FORM END ================//
  },
  mounted() {
    if(this.from == 'completedInfo')
      this.alertMessage = 'register_completed_please_sign_in'
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
