<template>
  <div class="col-lg-9 col-12 bg-white rounded-min my-3">
    <div class="container-fluid mb-3 align-center">
        <div class="row justify-content-center max-size w-75 mt-5 pt-2">

        </div>
        <div class="row justify-content-center mt-2 pb-5">
            <form class="w-75 px-0" @submit="storeInfos($event)">
              <div class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="alertMessage">
                <p class="m-2">
                  {{ $t('serverMessages.'+alertMessage) }}
                    <router-link v-if="alertMessage == 'dont_have_second_password'" class="text-white" :to="{ name : 'twoStepLoginPageRoute'}">
                    <strong>ساخت رمز دو مرحله ای</strong>
                    </router-link>
                </p>
              </div>
                <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
                    <p class="m-2">
                    {{ $t('errors.field') }} {{ $t('inputLabels.'+item.name) }} {{ $t('errors.'+item.err) }}
                    </p>
                </div>
                <div class="row mt-3 mt-lg-5">

                    <!--phone number input begin-->
                    <div class="form-group col-md-6 mb-4">
                        <label class="mb-2" for="getNum">شماره موبایل</label>
                        <div class="input-group mb-2">
                            <input
                            class="form-control ps-5" dir="ltr" id="getNum" type="text"
                            @focus="phoneNumber_validation($refs.CP_phoneNumber,$refs.CP_phoneNumber_error,$i18n.locale)"
                            @input="phoneNumber_validation($refs.CP_phoneNumber,$refs.CP_phoneNumber_error,$i18n.locale)"
                            ref="CP_phoneNumber" v-model="userInfo.mobile"/>
                            <svg class="inline-left-icons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z"
                                    fill="#34495E"
                                />
                            </svg>
                        </div>
                        <span class="text-danger inputStatus-msg" ref="CP_phoneNumber_error"></span>
                    </div>
                    <!--phone number input end-->

                    <!--wallet address input begin-->
                    <div v-if="$route.name == 'editAccPageRoute'" class="form-group col-md-6 mb-4">
                        <label class="mb-2" for="getAddress">آدرس کیف پول تتر</label>
                        <div class="input-group mb-2">
                            <input
                            @input="walletAddress_validation_optional($refs.CP_walletAddress,$refs.CP_walletAddress_error,$i18n.locale)"
                            @focus="walletAddress_validation_optional($refs.CP_walletAddress,$refs.CP_walletAddress_error,$i18n.locale)"
                            class="form-control ps-5" dir="ltr" id="getAddress"
                            placeholder="" type="text" ref="CP_walletAddress"
                            v-model="userInfo.tetherWallet"/>
                            <svg class="inline-left-icons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.6389 14.3958H17.5906C16.1042 14.3949 14.8993 13.1909 14.8984 11.7045C14.8984 10.218 16.1042 9.01413 17.5906 9.01321H21.6389"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path d="M18.049 11.6429H17.7373" stroke="#34495E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path d="M7.03516 7.53817H12.4341" stroke="#34495E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <span class="text-danger" ref="CP_walletAddress_error"></span>
                    </div>
                    <!--wallet address input end-->

                    <!--email input begin-->
                    <div class="form-group col-md-6 mb-4">
                        <label class="mb-2" for="getEmail">ایمیل</label>
                        <div class="input-group mb-2">
                            <input
                            @input="email_validate($refs.CP_email,$refs.CP_email_error,$i18n.locale)"
                            @focus="email_validate($refs.CP_email,$refs.CP_email_error,$i18n.locale)"
                            class="form-control ps-5" dir="ltr" id="getEmail" type="email" ref="CP_email"
                            v-model="userInfo.email"/>
                            <svg class="inline-left-icons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2676 9.06113L13.0022 12.4954C12.1949 13.1283 11.0634 13.1283 10.2562 12.4954L5.9541 9.06113" stroke="#34495E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                                    stroke="#34495E"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <span class="text-danger" ref="CP_email_error"></span>
                    </div>
                    <!--email input end-->

                    <!--store infos button begin-->
                    <div class="form-group mt-2 text-center text-md-start align-self-center" :class="($route.name != 'editAccPageRoute') ? 'col-md-12' : 'col-md-6'">
                        <button class="btn btn-lg text-white" style="margin: 0.8rem 0 0 0 !important;" type="submit" :disabled="disableFirstForm">
                            ذخیره اطلاعات
                        </button>
                    </div>
                    <!--store infos button end-->
                </div>
            </form>
        </div>
    </div>
    
    <!--two step verification code begin-->
    <transition name="fade">
        <div class="container" v-if="isTrueInfos">
            <!--form col begin-->
            <div class="form-box">
            <div class="form">
                <!--verification code form heading begin-->
                <div class="form-heading">
                <div class="text-center">
                    <h3>{{ $t('verificationCode.formTitle') }}</h3>
                </div>
                </div>
                <!--verification code form heading end-->

                <!--verification code form main begin-->
                <form @submit.prevent="sendData($event)">
                    <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMsg">
                        <p class="mb-0">
                            {{ $t('serverMessages.'+alertMsg) }}
                        </p>
                    </div>
                    <div class="form-group">
                        <p class="text-center ">
                        <span>کد وارد شده به شماره</span>
                        <span class="mx-2">{{userInfo.mobile}}</span>
                        <span>را وارد کنید</span>
                        </p>
                        <!--verification code input begin-->
                        <div class="d-flex verification-code-box justify-content-around my-5 w-75 mx-auto" dir="ltr">
                        <input @keyup="goNext($event, 'first')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify1">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify2">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify3">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify4">
                        <input @keyup="goNext($event, 'last')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify5">
                        </div>
                        <!--verification code input end-->

                        <!--two step code input begin-->
                        <p class="text-center">
                            <span>رمز دو مرحله ای خود را وارد کنید.</span>
                        </p>
                        <div class="form-group col-md-6 mx-auto">
                            <div class="input-group mb-2">
                                <input type="password" class="form-control ps-5 bg-white" dir="ltr" v-model="secondPassword"/>
                            </div>
                        </div>
                        <p class="text-center" dir="rtl">
                            <span>{{showCounter}}</span>
                        </p>
                        <transition name="fade">
                          <p class="text-center resendCode-text" v-if="timeExpired" @click="storeInfos($event);timeExpired = false;">
                              <span>ارسال مجدد کد</span>
                          </p>
                        </transition>
                        <!--two step code input end-->

                        <!--signUp btn begin-->
                        <div class="text-center">
                        <button class="btn first-style" name="submit-money-transfer" type="submit" :disabled="
                        (verify1 != '' && verify2 != '' && verify3 != '' && verify4 != '' && verify5 != '' && secondPassword != '') ? false : true">تایید نهایی</button>
                        </div>
                        <!--signUp btn end-->
                    </div>
                </form>
                <!--verification code form main end-->
            </div>
            </div>
            <!--form col end-->
        </div>
    </transition>
    <!--two step verification code end-->

    <!--modal box begin-->
    <transition name="fade">
        <div class="harvest-modal d-flex" v-if="show_success_alert">
                <div class="question-box text-center p-5 d-flex flex-column align-items-center">
                    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_978_4115)">
                            <path d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z" fill="#1ABC9C" />
                            <path d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z" fill="#179F84" />
                            <path
                                d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z"
                                fill="white"
                            />
                            <path
                                d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_978_4115">
                                <rect width="51" height="51" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h5>تغییرات با موفقیت انجام شد.</h5>
                    <div class="answer-btn-box w-75 d-flex justify-content-around">
                        <button @click="show_success_alert = false; isTrueInfos = false; disableFirstForm = false; $router.go(0);" class="btn first-style">
                            متوجه شدم
                        </button>
                    </div>
                </div>
        </div>
    </transition>
    <!--modal box end-->

</div>

</template>

<script>
import formValidation from "@/../mixins/formValidation.js";
import errorhandler from '@/services/error-service'

export default {
  name: "editAccountForm",
  mixins: [formValidation],
  data() {
      return {
        userDataLocal:'',
        userInfo:{},
        profile_pic : '',
        birthDate : {},
        show_success_alert : false,
        userObj: null,
        alertMessage:'',
        alertMsg:'',
        disableFirstForm:false,

        isTrueInfos:false,
        verify1: '',
        verify2: '',
        verify3: '',
        verify4: '',
        verify5: '',
        secondPassword:'',
        showCounter : '',
        timeExpired :false,
        ticker:Number,
        timeInSecs: Number,

        errorsArray: [],
      }
  },
  props : {
      userToEditId : Number,
  },
  methods: {
      updateLocale_storage(){
          let user = localStorage.getItem('user');
          this.userObj = JSON.parse(user);
          this.userObj.mobile = this.userInfo.mobile;
          this.userObj.email = this.userInfo.email;
          this.userObj.tetherWallet = this.userInfo.tetherWallet;

          localStorage.setItem("user",JSON.stringify(this.userObj));
      },
      storeInfos($e){
        this.CP_inputs = document.querySelectorAll('input');
        this.CP_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.CP_phoneNumber_error.innerText != "" ||
        this.$refs.CP_walletAddress_error.innerText != "" ||
        this.$refs.CP_email_error.innerText != ""){
            $e.preventDefault();
        }else{
            $e.preventDefault();
            this.isGetVerificationCode();
        }
      },
      async isGetVerificationCode(){
        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'put',
            url: '/api/profile/edit/verification/request',
            headers: {
                Authorization: 'Bearer ' + jwt
            },
            data:{
                mobile : this.userInfo.mobile,
                email : this.userInfo.email,
                tetherWallet : this.userInfo.tetherWallet
            }
        })
            .then((response) => {
                if (response.status == 200) {
                  this.alertMessage = '';
                  this.errorsArray = [];
                  this.isTrueInfos = true;
                  this.disableFirstForm = true;
                  this.countDown(response.data.expire_time*60);
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
      },
      async sendData(){
        if(this.verify1 != '' && this.verify5 != '' && this.secondPassword != ''){
        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'put',
            url: '/api/profile/edit/verification',
            headers: {
                Authorization: 'Bearer ' + jwt,
            },
            data:{
                mobile : this.userInfo.mobile,
                email : this.userInfo.email,
                tetherWallet : this.userInfo.tetherWallet,
                secondPassword : this.secondPassword,
                code : this.verify1+this.verify2+this.verify3+this.verify4+this.verify5
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMsg = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                    this.updateLocale_storage();
                }
            })
            .catch((error) => {
                console.log(error)
                let errors = error.response.data;
                this.alertMsg = errors.message;
                self.errorsArray = errorhandler.handleErrors(errors.errors);
            });
        }else{
            alert("کد وارد شده یا رمز عبور دو مرحله ای اشتباه است")
        }
      },
      async getData(){
      const jwt = localStorage.getItem('jwt');
      await this.$http({
        method: 'post',
        url: '/api/profile/edit',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.userInfo = data;
              this.date = new Date(this.userInfo.birthDate);
              this.birthDate = this.date.toLocaleDateString('fa-IR');
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    goNext($e, position = null) {
    if ( ($e.keyCode >= 48 && $e.keyCode <= 57) || ($e.keyCode >= 97 && $e.keyCode <= 105) ){
        if(position != 'last')
          $e.target.nextElementSibling.focus();
      } else if ($e.keyCode == 8 || $e.keyCode == 37) {
        if(position != 'first')
          $e.target.previousElementSibling.focus();
      }else{
          $e.preventDefault();
      }
    },
    countDown(secs){
    const self = this;
    var timeInSecs = Number(secs);
    var ticker = setInterval( function(){
        var sec = timeInSecs;
        if (sec > 0) {
        timeInSecs = timeInSecs-1; 
        }
        else {
        clearInterval(ticker);
        self.timeExpired = true;
        }
        var mins = Math.floor(sec/60);
        sec = sec%60;

        self.showCounter = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (sec < 10) ? "0" : "" ) + sec;
    }, 1000); 
    },
  },
  beforeMount() {
        this.getData();
        const secondPassword = localStorage.getItem('secondPassword');
        if(secondPassword == 'false'){
          this.disableFirstForm = true;
          this.alertMessage = 'dont_have_second_password';
        }
  },
};
</script>

<style scoped>
form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form .form-group input,
textarea,
select {
  border: none;
}

.form-group .form-control {
  border: none;
  box-sizing: border-box;
  background: transparent !important;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px;
}

.inline-left-icons {
  margin: 0 0 0 0.7rem !important;
  position: absolute;
  left: 0;
  top: 0.4rem;
  z-index: 10;
  border-right: 1.5px solid #8d939a;
  padding-right: 0.3rem;
}


form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 4rem 0 2.5rem 0 !important;
}

.form-check-label-left {
  direction: rtl;
  text-align: right;
}

.py-datepicker {
  padding-top: 3px;
  padding-bottom: 3px;
}

.copy-link,
.copy-link :hover {
  position: absolute;
  width: 75px;
  height: 32px;
  right: 0.2rem;
  top: 0.1rem;
  color: #34495e !important;
  background: rgba(26, 188, 156, 0.2);
  border-radius: 5px !important;
  font-size: 0.75rem;
  z-index: 10;
}

.img-avatar{
    width: 7rem;
    height:7rem;
}

@media (max-width: 768px) {
 .w-75{
     width: 100% !important;
 }
 .question-box{
     width: 90% !important;
 }
}
</style>
