<template>
    <div class="container-fluid bg-white mt-3 rounded-min pe-0">
        <div class="row justify-content-center pt-4">
            <form class="w-75" @submit="storeInfos($event)">
                <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
                    <p class="mb-0">
                        {{ $t('serverMessages.'+alertMessage) }}
                    </p>
                </div>
                <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
                    <p class="m-2">
                        {{ item.err }}
                    </p>
                </div>
                <div class="row mt-2">
                    <!--link title input begin-->

                    <div class="form-group col-md-6">
                        <label for="getTitle" class="mb-2">عنوان لینک</label>
                        <div class="input-group mb-2">
                            <input
                            @input="required_validation($refs.SNL_linkTitle,$refs.SNL_linkTitle_error,$i18n.locale)"
                            @focus="required_validation($refs.SNL_linkTitle,$refs.SNL_linkTitle_error,$i18n.locale)"
                            type="text" id="getTitle" class="form-control" ref="SNL_linkTitle" v-model="linkTitle"/>
                        </div>
                        <span class="text-danger" ref="SNL_linkTitle_error"></span>
                    </div>

                    <!--link title input end-->

                    <!--link input begin-->

                    <div class="form-group col-md-6">
                        <label for="getLink" class="mb-2">لینک</label>

                        <div class="input-group mb-2">
                            <input
                            @input="links_validation($refs.SNL_link,$refs.SNL_link_error,$i18n.locale)"
                            @focus="links_validation($refs.SNL_link,$refs.SNL_link_error,$i18n.locale)"
                            type="text" class="form-control text-primary" dir="ltr" ref="SNL_link" v-model="linkUrl"/>
                        </div>
                        <span class="text-danger" ref="SNL_link_error"></span>
                    </div>

                    <!--link input end-->
                </div>

                <div class="row pb-5">
                    <!--choose type input begin-->
                    <div class="form-group col-md-12">
                        <label class="mb-2">انتخاب نوع</label>
                        <div class="input-group d-flex align-items-center mb-2">
                            <select class="form-control bg-white  mb-0" v-model="choosenType">
                                <option value="view" selected>بازدید</option>
                                <option value="click">کلیک</option>
                            </select>
                            <svg class="angle-icon-bottom" height="24" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
                                    fill="#8D939A"
                                ></path>
                            </svg>
                        </div>
                        <!--span class="text-danger inputStatus-msg" ref="ST_selectCategory_error">
                        </span-->
                    </div>
                    <!--choose type input end-->
                    <div class="row my-5 justify-content-between">

                        <!--upload img btn begin-->

                        <input class="btn noneBg-1-style btn-font" type="file" id="upload" hidden @change="chooseFiles($event)" accept=".png,.jpg,.jpeg,.gif,.svg"/>

                        <label for="upload" class="btn noneBg-1-style btn-fontcol-12 col-md-4 col-lg-3">
                            بارگزاری تصویر

                            <svg class="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="11.9004" y1="8" x2="11.9004" y2="19" stroke="#1ABC9C" stroke-width="1.5" />

                                <line x1="17" y1="5.75" x2="7" y2="5.75" stroke="#1ABC9C" stroke-width="1.5" />

                                <path d="M15.7783 11.8891L11.8892 8.00001L8.00015 11.8891" stroke="#1ABC9C" stroke-width="1.5" />
                            </svg>
                        </label>
                        <!--upload img btn end-->

                        <!--link pic img begin-->
                        <div v-if="exactlinkPicture" class="w-25">                     
                            <span @click="exactlinkPicture = ''" class="remove-linkImg">&times;</span>
                            <img :src="exactlinkPicture" class="thumbnail w-100"/>
                        </div>
                        <div v-if="pictureOfLink != ''" class="w-25">                   
                            <span @click="pictureOfLink = ''" class="remove-linkImg">&times;</span>
                            <img src="" ref="linkPic" class="thumbnail w-100">
                        </div>
                        <!--link pic img end-->

                    </div>

                    <div class="row justify-content-center justify-content-md-end my-3">
                        <!--store infos btn begin-->

                        <button type="submit" class="col-12 col-md-3 btn btn-md-lg btn-block py-2 first-style border-0">
                            ذخیره اطلاعات
                        </button>

                        <!--store infos btn end-->
                    </div>
                </div>
            </form>
        </div>
        <!--modal box begin-->
        <div class="harvest-modal d-flex" v-if="show_success_alert">
            <transition name="fade">
            <div
                class="question-box p-5 d-flex flex-column align-items-center"
            >
                <svg
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                <g clip-path="url(#clip0_978_4115)">
                    <path
                        d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z"
                        fill="#1ABC9C"
                    />
                    <path
                        d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z"
                        fill="#179F84"
                    />
                    <path
                        d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z"
                        fill="white"
                    />
                    <path
                        d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_978_4115">
                    <rect width="51" height="51" fill="white" />
                    </clipPath>
                </defs>
                </svg>
                <h5>لینک شما با موفقیت ارسال شد.</h5>
                <div class="answer-btn-box w-75 d-flex justify-content-around">
                <button
                    @click="goToList"
                    class="btn first-style"
                >
                    متوجه شدم
                </button>
                </div>
            </div>
            </transition>
        </div>
        <!--modal box end-->
    </div>
</template>


<script>
import formValidation from'@/../mixins/formValidation.js'
import errorhandler from '@/services/error-service'

export default {
  name: "submitNewLinkForm",
  mixins: [formValidation],
  data() {
      return {
        pictureOfLink:'',
        show_success_alert: false,
        alertMessage:'',
        linkTitle:'',
        linkUrl:'',
        choosenType:'',
        errorsArray:[]
      }
  },
  props:{
      exactLinkId : Number,
      exactLinkTitle : String,
      exactLinkUrl : String,
      exactlinkPicture : String,
      exactLinkType: String,
  },
  methods: {
      storeInfos($e){
        this.SNL_inputs = document.querySelectorAll('input');
        this.SNL_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.SNL_linkTitle_error.innerText != "" ||
        this.$refs.SNL_link_error.innerText != ""){
            $e.preventDefault();
        }else{
            $e.preventDefault();
            if(this.exactLinkId){
                this.sendEditedLink(this.exactLinkId)
            }else{
                this.sendData();
            }
        }
      },
      async sendData(){
        let formData = new FormData();
        formData.append('name', this.linkTitle);
        formData.append('url', this.linkUrl);
        formData.append('pictureFile', this.pictureOfLink);
        formData.append('type', this.choosenType);

        const jwt = localStorage.getItem('jwt');  
        await this.$http({
            method: 'post',
            url: '/api/ad/new',
            headers: {
                Authorization: 'Bearer ' + jwt,
                'Content-Type': 'multipart/form-data'
            },
            data:formData,
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMessage = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
      },
        chooseFiles(event){
            this.pictureOfLink = event.target.files[0];
            if(this.exactlinkPicture)
                this.exactlinkPicture = '';
            const reader = new FileReader
            reader.onload = e => {
            let imageData = e.target.result;
            this.$refs.linkPic.setAttribute("src",imageData);
            }
            reader.readAsDataURL(this.pictureOfLink)
            this.$emit('input', this.pictureOfLink)
        },
      goToList(){

        let userRole = localStorage.getItem('userType');
        userRole = userRole.split(',');

        if (userRole.includes('ROLE_SUPER_ADMIN')) {
            this.$router.push({ name : 'superAdminRegisteredLinkPageRoute'});
        } else {
            this.$router.push({ name : 'registeredLinkPageRoute'});
        }

      },
      async sendEditedLink(link_id){
        let formData = new FormData();
        formData.append('name', this.linkTitle);
        formData.append('url', this.linkUrl);
        formData.append('type', this.choosenType);
        if(this.pictureOfLink != ''){
            formData.append('pictureFile', this.pictureOfLink);
        }else{
            formData.append('pictureFile', null);
        }

        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'post',
            url: '/api/ad/'+link_id+'/edit',
            headers: {
                Authorization: 'Bearer ' + jwt,
            },
            data:formData,
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMessage = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
      },
  },
  beforeMount() {
      if(this.exactLinkId){
        this.linkTitle = this.exactLinkTitle;
        this.linkUrl = this.exactLinkUrl;
        this.choosenType = this.exactLinkType;
      }else{
          this.choosenType = 'view';
      }
  }
};
</script>

<style scoped>
.form-group .form-control {
  border: none;
  box-sizing: border-box;
  background: transparent !important;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px;
}

form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form input.activate {
  background: #f1f1f1;
}

.btn-screenshot,
.btn-screenshot:hover {
  background-color: #34495e;
  color: #fafafa;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}
label.btn-fontcol-12{
height: fit-content;
}
.remove-linkImg{
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
 .question-box{
     width: 90% !important;
     text-align: center;
 }
}

select{
    -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0px !important;
        outline: 0px !important;
}
</style>
