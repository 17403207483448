<template>
  <div class="container form-container">
    <!--form col begin-->
    <div class="form-box w-50 mr-0 ml-auto">
      <div class="shape-under-form"></div>
      <div class="form">
        <!--verification code form heading begin-->
        <div class="form-heading">
          <div @click="$router.go(-1)" class="back-btn">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.75 11.7257L4.75 11.7257" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="title">
            <h3>{{ $t('verificationCode.formTitle') }}</h3>
          </div>
          <!--switchLanguage/-->
        </div>
        <div class="form-identifier-photo">
          <img src="@/assets/images/validate-idPic.png" class="p-3" alt="validate">
        </div>
        <!--verification code form heading end-->

        

        <!--verification code form main begin-->
        <form @submit.prevent="verificationCode_submit($event)" class="form-main ">
          <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-show="alertMessage">
            <p class="m-2">
              {{ $t('serverMessages.'+alertMessage) }}
            </p>
          </div>
          <div class="form-group">
            <p class="text-center ">
              <span> کد پنج رقمی اعتبارسنجی به {{(phoneNumber) ? 'شماره' : 'ایمیل'}}</span>
              <span class="mx-2">{{ (phoneNumber) ? phoneNumber : email }}</span>
              <span>ارسال شد</span>
            </p>
            <!--verification code input begin-->
            <div class="d-flex verification-code-box justify-content-around">
              <input @keyup="goNext($event, 'first')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify1">
              <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify2">
              <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify3">
              <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify4">
              <input @keyup="goNext($event, 'last')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify5">
            </div>
            <!--verification code input end-->

            <!--help text begin-->
            <p class="text-center help-text"><a href="#">{{ $t('verificationCode.helpText_mainLink') }}</a></p>
            <!--help text end-->

            <!--signUp btn begin-->
            <div>
              <button class="btn btn-style" name="sign-in" type="submit">{{
                  $t('verificationCode.submitButton')
                }}
              </button>
            </div>
            <!--signUp btn end-->
          </div>
        </form>
        <!--verification code form main end-->
      </div>
    </div>
    <!--form col end-->
  </div>
</template>

<script>
import './../../assets/scss/formClasses.css'
import './../../assets/scss/langStyle.css'
import formValidation from './../../../mixins/formValidation.js'
//import switchLanguage from './switchLanguage.vue'

export default {
  name: 'verificationCode',
  mixins: [formValidation],
  components: {
    //switchLanguage,
  },
  props: ['phoneNumber', 'type', 'email'],
  data() {
    return {
      verify1: '',
      verify2: '',
      verify3: '',
      verify4: '',
      verify5: '',
      alertMessage: ''
    }
  },
  beforeMount() {
    if ((this.phoneNumber == null && this.email == null) || this.type == null)
      this.$router.push('/signUp')
  },
  methods: {
    goNext($e, position = null) {
      if (($e.keyCode < 48 && $e.keyCode != 8 && $e.keyCode != 37) || ($e.keyCode > 57 && $e.keyCode != 8 && $e.keyCode != 37)) {
        if(position != 'last')
          $e.target.nextElementSibling.focus();
      } else if ($e.keyCode == 8 || $e.keyCode == 37) {
        if(position != 'first')
          $e.target.previousElementSibling.focus();
      }
    },
    //================ SUBMIT SIGN UP FORM BEGIN ================//
    verificationCode_submit() {
      if (this.verify1 != '' && this.verify5 != '') {
        // request with axios for login
        // prepare data object based on email or mobile inserted
        let data = {
          code: this.verify1+this.verify2+this.verify3+this.verify4+this.verify5,
          type: this.type
        };
        this.phoneNumber == null? data.email = this.email: data.mobile = this.phoneNumber;
        let self = this;
        this.$http({
          method: 'post',
          url: '/verification_code',
          data: data
        }).then(function (response) {
          if (response.status == 200) {
            self.alertMessage = '';
            if(response.data.type == 'recovery'){
              self.$router.push({name:'changePasswordRoute', params:{token:response.data.token, phoneNumber:self.phoneNumber, email: self.email}})
              return;
            }
            if(response.data.is_complete == true){
              self.$router.push('/signin');
              return;
            }
            else{
              self.$router.push({name:'completeInfoRoute', params:{token:response.data.token, phoneNumberFrom:self.phoneNumber, emailFrom: self.email}})
              return;
            }
          }
        })
            .catch(function (error) {
              let errors = error.response.data;
              self.alertMessage = errors.message;
            });
      }
    }
    //================ SUBMIT SIGN UP FORM END ================//
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
