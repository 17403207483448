<template>
  <div class="container-fluid" dir="rtl">
    <div class="row">
      <dashBoard_sideBar />
      <div class="col-12 col-lg-9 main-content mt-3 scale-up-hor-right">
        <!--work desk begin-->
        <div id="workDesk-item">
          <!--compelete profile alert begin-->
          <div class="compelete-profile-alert text-center" v-if="!userData.name" >
            <p>
              برای شروع کار خود لطفا فرایند تکمیل اطلاعات را از بخش "تنظیمات
              پروفایل" تکمیل کنید.
            </p>
          </div>
          <!--compelete profile alert end-->
          <!--exit & enter info begin-->
          <div class="exitEnter-info d-flex flex-wrap align-items-center justify-content-md-start justify-content-end mt-0 mt-md-2">
            <!--total subsets begin-->
            <div class="total-subsets exitEnter-info-box">
              <div class="info-val d-flex">
                <p class="info-title my-0">تعداد کل زیرمجموعه ها:</p>
                <span class="subsetNum me-2">{{ userStatus.subCount }} </span>
                <span class="subsetNum"> نفر</span>
              </div>
            </div>
          </div>

          <!--advertise billboard slider begin-->
          <div class="AD-billBoard-slider-box mt-3">
            <div class="sections-title">
              <div class="title-shape"></div>
              &nbsp;
              <div class="title-text">بیلبورد تبلیغاتی</div>
            </div>
            <carousel_slider />
          </div>
          <!--advertise billboard slider end-->
          <!--other activity begin-->
          <div class="other-activity-box">
            <div class="row">
              <!--teaching box begin-->
              <div class="col-md-6 col-lg-3 teaching-col mb-2 pe-0">
                <div class="teaching-box h-100">
                  <div class="sections-title">
                    <div class="title-shape"></div>
                    &nbsp;
                    <div class="title-text">آموزش</div>
                  </div>
                  <div class="teaching-pic">
                    <router-link to="teaching">
                      <img
                          src="@/assets/images/teaching.png"
                          alt=""
                          width="100%"
                      />
                    </router-link>
                  </div>
                </div>
              </div>
              <!--teaching box end-->
              <!--status box begin-->
              <div class="col-md-6 col-lg-3 status-col mb-2 pe-0">
                <div class="status-box h-100">
                  <div class="sections-title">
                    <div class="title-shape"></div>
                    &nbsp;
                    <div class="title-text">وضعیت</div>
                  </div>
                  <div class="status-specs">
                    <ul class="status-list-box mt-0 mt-md-5">
                      <li class="status-li">
                        <p class="my-0">آخرین حضور در میز کار</p>
                        <span class="status-val">{{ (userData.lastLastLogin) ? new Date(userData.lastLastLogin).toLocaleDateString('fa-IR') : '-'}}</span>
                      </li>
                      <li class="status-li">
                        <p class="my-0">تاریخ ثبت نام</p>
                        <span class="status-val">{{ (userData.createdAt) ? new Date(userData.createdAt).toLocaleDateString('fa-IR') : '-' }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--status box end-->

              <!--dedicated code box begin-->
              <div class="col-12 col-lg-6 dedicated-code-col pe-0 mb-2">
                <div class="dedicated-code-box h-100">
                  <div class="sections-title mb-0 mb-md-4">
                    <div class="title-shape"></div>
                    &nbsp;
                    <div class="title-text">کد اختصاصی</div>
                  </div>
                  <div
                    class="
                      d-flex
                      flex-column flex-md-row
                      justify-content-around
                    "
                  >
                    <div class="recommender-code codes-box">
                      <div class="codes-box-title">کد معرف</div>
                      <div v-if="userData.identifierCode" class="main-code">{{ userData.identifierCode }}</div>
                      <div v-else class="main-code">-</div>
                      <div v-if="userData.identifierCode" class="copy-button">
                        <button class="btn" @click="copyClipboard('identifier')" >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M9.71875 16H3.75C2.37146 16 1.25 14.8785 1.25 13.5V5.03125C1.25 3.65271 2.37146 2.53125 3.75 2.53125H9.71875C11.0973 2.53125 12.2188 3.65271 12.2188 5.03125V13.5C12.2188 14.8785 11.0973 16 9.71875 16ZM3.75 3.78125C3.06079 3.78125 2.5 4.34204 2.5 5.03125V13.5C2.5 14.1892 3.06079 14.75 3.75 14.75H9.71875C10.408 14.75 10.9688 14.1892 10.9688 13.5V5.03125C10.9688 4.34204 10.408 3.78125 9.71875 3.78125H3.75ZM14.7188 11.9375V2.5C14.7188 1.12146 13.5973 0 12.2188 0H5.28125C4.93604 0 4.65625 0.279785 4.65625 0.625C4.65625 0.970215 4.93604 1.25 5.28125 1.25H12.2188C12.908 1.25 13.4688 1.81079 13.4688 2.5V11.9375C13.4688 12.2827 13.7485 12.5625 14.0938 12.5625C14.439 12.5625 14.7188 12.2827 14.7188 11.9375Z" fill="#1ABC9C" />
                          </svg>
                          <span>کپی کردن</span>
                        </button>
                      </div>
                    </div>
                    <svg class="d-none d-md-block" width="2" height="138" viewBox="0 0 2 138" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <line x1="0.75" y1="0.75" x2="0.750006" y2="137.25" stroke="#F0F0F0" stroke-width="1.5" stroke-linecap="round" stroke-dasharray="8 8" />
                    </svg>
                    <div class="backUp-code codes-box">
                      <div class="codes-box-title">کد پشتیبان</div>
                      <div class="main-code" v-if="userData.parent">{{ userData.parent.identifierCode }}</div>
                      <div class="main-code" v-else>-</div>
                      <div v-if="userData.parent" class="copy-button">
                        <button class="btn" @click="copyClipboard('')">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M9.71875 16H3.75C2.37146 16 1.25 14.8785 1.25 13.5V5.03125C1.25 3.65271 2.37146 2.53125 3.75 2.53125H9.71875C11.0973 2.53125 12.2188 3.65271 12.2188 5.03125V13.5C12.2188 14.8785 11.0973 16 9.71875 16ZM3.75 3.78125C3.06079 3.78125 2.5 4.34204 2.5 5.03125V13.5C2.5 14.1892 3.06079 14.75 3.75 14.75H9.71875C10.408 14.75 10.9688 14.1892 10.9688 13.5V5.03125C10.9688 4.34204 10.408 3.78125 9.71875 3.78125H3.75ZM14.7188 11.9375V2.5C14.7188 1.12146 13.5973 0 12.2188 0H5.28125C4.93604 0 4.65625 0.279785 4.65625 0.625C4.65625 0.970215 4.93604 1.25 5.28125 1.25H12.2188C12.908 1.25 13.4688 1.81079 13.4688 2.5V11.9375C13.4688 12.2827 13.7485 12.5625 14.0938 12.5625C14.439 12.5625 14.7188 12.2827 14.7188 11.9375Z" fill="#1ABC9C" />
                          </svg>
                          <span>کپی کردن</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--dedicated code box end-->
            </div>
            <div class="row">
              <!--wallet ballance begin-->
              <div class="col-md-6 tether-wallet-col mb-2 pe-0">
                <router-link :to="{ name : 'financialReportPageRoute'}">
                  <div class="tether-wallet-box flex-column h-100">
                    <div class="sections-title">
                      <div class="title-shape"></div>
                      &nbsp;
                      <div class="title-text">کیف پول تتر</div>
                    </div>
                    <div class=" wallet-inventory h-100 align-items-center justify-content-center d-flex " >
                      <wallet_ballance :theater="userStatus.walletBallance_theater" :percent="userStatus.walletBallance_percent" />
                    </div>
                  </div>
                </router-link>
              </div>
              <!--my activity begin-->
              <div class="col-md-6 chart-col pe-0 mb-2">
                <div class="chart-box">
                  <div class="sections-title">
                    <div class="title-shape"></div>
                    &nbsp;
                    <router-link :to="{ name : 'activityReportPageRoute' }">
                      <div class="title-text">فعالیت های من</div>
                    </router-link>
                  </div>
                  <chart/>
                </div>
              </div>
              <!--my activity end-->
            </div>
          </div>
          <!--other activity end-->
          <!--work desk end-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/mainPage.css";
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import wallet_ballance from "./../reusable-components/wallet-ballance/wallet-ballance.vue";
import chart from "./../reusable-components/chart/chart.vue";
import carousel_slider from "./../reusable-components/slider/carouselSlider.vue";

export default {
  name: "workDeskPage",
  components: {
    dashBoard_sideBar,
    wallet_ballance,
    chart,
    carousel_slider,
  },
  data() {
    return {
      userData: "",
      userFaName: "",
      ads: [],
      userStatus: {
        subCount: 0,
        walletBallance_theater: 0,
        walletBallance_percent: 0,
        activity_statistics: {
          monthly: {
            monthlyClick: [],
            monthlyView: [],
          },
          weekly: {
            weeklyClick: [],
            weeklyView: [],
          },
          days: {
            weekly: [],
            monthly: [],
          }
        },
      },
    };
  },
  methods: {
    async copyClipboard(position) {
      if (position == "identifier"){
        navigator.clipboard.writeText(this.userData.identifierCode);
      }else{
        navigator.clipboard.writeText(this.userData.parent.identifierCode);
      }
    },
    updateLocale_storage(api_data,locale_data){
      locale_data.sumEarning = api_data.sumEarning;
      locale_data.username = api_data.username;
      locale_data.name = api_data.name;
      locale_data.sumView = api_data.sumView;
      locale_data.sumClick = api_data.sumClick;
      locale_data.level = api_data.level;
      locale_data.subCount = api_data.subCount;
      locale_data.sumWithdraw = api_data.sumWithdraw;
      locale_data.unreadTickets = api_data.unreadTickets;
      locale_data.walletPercent = api_data.walletPercent;
      locale_data.avatar = api_data.avatar;
      locale_data.minWithdraw = api_data.minWithdraw;

      localStorage.setItem("user",JSON.stringify(locale_data));
    }
  },
  mounted() {
    // get local storage data
    this.userData = JSON.parse(localStorage.getItem("user"));
    const jwt = localStorage.getItem("jwt");
    // get user data
    let self = this;
    this.$http({
      method: "get",
      url: "/api/home/index",
      data: {},
      headers: {
        Authorization: "Bearer " + jwt,
      },
    })
      .then(function (response) {
        if (response.status == 200) {
          let data = response.data.user;
          self.updateLocale_storage(data,self.userData);
          self.userStatus.subCount = data.subCount;
          self.userStatus.walletBallance_theater = data.sumEarning;
          self.userStatus.walletBallance_percent = data.walletPercent;
          //set ads data
          self.ads = response.data.ads;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
  text-decoration: none;
}
</style>
<style>
.subsetNum {
  font-size: 0.75rem !important;
  font-weight: 900;
}

.info-title {
  font-size: 0.85rem;
  font-weight: 500;
}

.scale-up-hor-right {
	-webkit-animation: scale-up-hor-right 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-hor-right 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

</style>
