 <template>
    <div class="container form-container">
        <!--form col begin-->
        <div class="form-box w-50 mr-0 ml-auto">
            <div class="shape-under-form"></div>
            <div class="form">
                <!--compeleteInfo form heading begin-->
                <div class="form-heading">
                    <div @click="$router.go(-1)" class="back-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.75 11.7257L4.75 11.7257" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="title">
                        <h3>{{ $t('compeleteInfo.formTitle') }}</h3>
                    </div>
                    <!--switchLanguage/-->
                </div>
                <!--compeleteInfo form heading end-->

                <!--compeleteInfo form main begin-->
                <form @submit.prevent="compeleteInfo_submit($event)" class="form-main text-right">
                  <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
                    <p class="m-2">
                      {{ $t('errors.field') }} {{ $t('inputLabels.'+item.name) }} {{ $t('errors.'+item.err) }}
                    </p>
                  </div>
                    <div class="form-group">
                        <!--name&last-name input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline"  for="name-lastName">{{ $t('inputLabels.name_lastName') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="name-lastName">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.5789" cy="7.27803" r="4.77803" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="name_validate($refs.CI_name,$refs.CI_name_error,$i18n.locale)"
                                @focus="name_validate($refs.CI_name,$refs.CI_name_error,$i18n.locale)"
                                ref="CI_name" class="form-control form-input-style textInput" type="text" name="name-lastName" aria-label="Recipient's " aria-describedby="name-lastName" v-model="faName">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="CI_name_error">

                            </span>
                        </div>
                        <!--name&last-name input end-->

                        <!--alias input begin-->
                        <div class="input-box" :class="$i18n.locale + '_align'">
                        <label class="d-inline-flex align-items-baseline" for="phone-number" >{{ $t("inputLabels.aliasName") }}
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E" />
                            </svg>
                        </label>
                        <div class="input-group form-InputIcon-gp d-flex align-items-center" >
                            <div class="input-group-prepend">
                            <span class="input-group-text input-icon-style" id="phone-number" >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z" fill="#34495E" />
                                </svg>
                            </span>
                            </div>
                            <div class="input-divider"></div>
                            <input @focus=" aliasName_validation( $refs.CI_aliasName, $refs.CI_aliasName_error, $i18n.locale )"
                            @input=" aliasName_validation( $refs.CI_aliasName, $refs.CI_aliasName_error, $i18n.locale ) "
                            ref="CI_aliasName" class="form-control form-input-style" type="text" name="phone-number" v-model="aliasName_input" />
                        </div>
                        <span class="text-danger inputStatus-msg" ref="CI_aliasName_error" >
                        </span>
                        </div>
                        <!--alias input end-->

                        <!--password input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline"  for="password">{{ $t('inputLabels.password') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="password">
                                        <svg v-show="showPassFlag" @click="showPass" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <!--when user clicked here able to see password-->
                                        <svg v-show="hidePassFlag" @click="hidePass" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                                                                         
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="password_validate($refs.CI_password,$refs.CI_password_error,$i18n.locale)"
                                @focus="password_validate($refs.CI_password,$refs.CI_password_error,$i18n.locale)"
                                ref="CI_password" class="form-control form-input-style" type="password" name="password" aria-label="Recipient's " aria-describedby="password" v-model="password">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="CI_password_error">
                            </span>
                        </div>
                        <!--password input end-->

                        <!--confirmPassword input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline"  for="confirmPassword">{{ $t('inputLabels.confPassword') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                    </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="confirmPassword">
                                        <svg v-show="showConfPassFlag" @click="showConfPass" class="visible-password" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061 10.2535 8.8911 11.9995 8.8911C13.7455 8.8911 15.1615 10.3061 15.1615 12.0531Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.4889 15.806 4.7509 11.998 4.7509H12.002C8.194 4.7509 4.711 7.4889 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <!--when user clicked here able to see password-->
                                            <svg v-show="hideConfPassFlag" @click="hideConfPass" class="invisible-password" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.76069 11.3668C7.18569 10.7928 6.83569 10.0128 6.83569 9.1378C6.83569 7.3848 8.24769 5.9718 9.99969 5.9718C10.8667 5.9718 11.6647 6.3228 12.2297 6.8968" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.1049 9.6989C12.8729 10.9889 11.8569 12.0069 10.5679 12.2409" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.65463 14.4723C3.06763 13.2263 1.72363 11.4063 0.749634 9.1373C1.73363 6.8583 3.08663 5.0283 4.68363 3.7723C6.27063 2.5163 8.10163 1.8343 9.99963 1.8343C11.9086 1.8343 13.7386 2.5263 15.3356 3.7913" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.4477 5.9908C18.1357 6.9048 18.7407 7.9598 19.2497 9.1368C17.2827 13.6938 13.8067 16.4388 9.99965 16.4388C9.13665 16.4388 8.28565 16.2988 7.46765 16.0258" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.887 1.2496L2.11304 17.0236" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>                                      
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="confPassword_validate($refs.CI_confPassword,$refs.CI_password,$refs.CI_confPassword_error,$i18n.locale)"
                                @focus="confPassword_validate($refs.CI_confPassword,$refs.CI_password,$refs.CI_confPassword_error,$i18n.locale)"
                                ref="CI_confPassword" class="form-control form-input-style" type="password" name="confirmPassword" aria-label="Recipient's " aria-describedby="confirmPassword" v-model="confirmPassword">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="CI_confPassword_error">
                            </span>
                        </div>
                        <!--confirmPassword input end-->

                        <!--nationalCode input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline"  for="confirmPassword">{{ $t('inputLabels.nationalCode') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="nationalCode">

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7 11.4557H12.399" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15 11.5H17" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                                                                                                        
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="nationalCode_validate($refs.CI_nationalCode,$refs.CI_nationalCode_error,$i18n.locale)"
                                @focus="nationalCode_validate($refs.CI_nationalCode,$refs.CI_nationalCode_error,$i18n.locale)"
                                ref="CI_nationalCode" class="form-control form-input-style" type="text" name="nationalCode" aria-label="Recipient's " aria-describedby="nationalCode" v-model="nationalCode">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="CI_nationalCode_error">
                            </span>
                        </div>
                        <!--nationalCoded input end-->

                        <!--emailSupport input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'" v-show="emailFrom==null">
                            <label class="d-inline-flex align-items-baseline"  for="emailSupport">{{ $t('inputLabels.supportEmail') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="emailSupport">
                        
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95422 9.06113" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z" stroke="#8D939A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>    
                                                                                                                                                
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="email_validate($refs.CI_email,$refs.CI_email_error,$i18n.locale)"
                                @focus="email_validate($refs.CI_email,$refs.CI_email_error,$i18n.locale)"
                                ref="CI_email" class="form-control form-input-style" type="text" name="emailSupport" aria-label="Recipient's " aria-describedby="emailSupport" v-model="email">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="CI_email_error">
                            </span>
                        </div>
                        <!--emailSupport input end-->

                      <!--phone number input begin-->
                      <div class="input-box" :class="$i18n.locale+'_align'" v-show="phoneNumberFrom==null">
                        <label class="d-inline-flex align-items-baseline" for="mobile">{{ $t('inputLabels.mobile') }}
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                          </svg>
                        </label>
                        <div class="input-group form-InputIcon-gp d-flex align-items-center">
                          <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="mobile">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z" fill="#34495E"/>
                                        </svg>
                                    </span>
                          </div>
                          <div class="input-divider"></div>
                          <input
                              @input="phoneNumber_validation($refs.CI_mobile,$refs.CI_mobile_error,$i18n.locale)"
                              @focus="phoneNumber_validation($refs.CI_mobile,$refs.CI_mobile_error,$i18n.locale)"
                              ref="CI_mobile" class="form-control form-input-style" type="text" name="mobile" placeholder="09xxxxxxxxx" aria-label="Recipient's " aria-describedby="mobile" v-model="phoneNumber">
                        </div>
                        <span class="text-danger inputStatus-msg" ref="CI_mobile_error">
                            </span>
                      </div>
                      <!--phone number input end-->

                        <!--compeleteInfo btn begin-->
                        <div class="text-right mt-5">
                            <button class="btn btn-style" name="compelete-info" type="submit">{{ $t('compeleteInfo.submitButton') }}</button>
                        </div>
                        <!--compeleteInfo btn end-->
                    </div>
                </form>
                <!--compeleteInfo form main end-->
            </div>
        </div>
        <!--form col end-->
        <transition name="fade">
        <div v-if="show_success_alert" class="harvest-modal d-flex">
            <div class="question-box p-5 d-flex flex-column text-center align-items-center">
            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_978_4115)">
                <path d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z" fill="#1ABC9C"/>
                <path d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z" fill="#179F84"/>
                <path d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z" fill="white"/>
                <path d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_978_4115">
                <rect width="51" height="51" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h3>
                عملیات ثبت نام با موفقیت انجام شد.
            </h3>
            <div class="answer-btn-box w-75 d-flex justify-content-around">
                <button @click=" show_success_alert = !show_success_alert; $router.push({name: 'signinRoute', params:{from:'completedInfo'}})" class="btn first-style" >
                متوجه شدم
                </button>
            </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import './../../assets/scss/formClasses.css'
import './../../assets/scss/langStyle.css'
import formValidation from './../../../mixins/formValidation.js'
//import switchLanguage from './switchLanguage.vue'
import errorhandler from './../../services/error-service'

export default {
  name: 'compeleteInfo',
    mixins: [formValidation],
    // components:{
    //     //switchLanguage,
    // },
    props: ['phoneNumberFrom', 'token', 'emailFrom'],
    data() {
        return {
            show_success_alert:false,
            //================ PASSWORD FIELD DATA BEGIN ================//
            showPassFlag:true,
            hidePassFlag:false,
            //================ PASSWORD FIELD DATA END ================//
            //================ CONFIRM PASSWORD FIELD DATA BEGIN ================//
            compeleteInfo_confPassword_error : "این فیلد نباید خالی باشد",
            showConfPassFlag:true,
            hideConfPassFlag:false,
            //================ CONFIRM PASSWORD FIELD DATA END ================//

            //================ EMAIL FIELD DATA BEGIN ================//
            compeleteInfo_email_error : "این فیلد نباید خالی باشد",
            //================ EMAIL FIELD DATA END ================//
            faName: '',
            password: '',
            confirmPassword: '',
            nationalCode: '',
            aliasName_input: '',
            errorsArray: [],
            type: '',
            email: this.emailFrom,
            phoneNumber: this.phoneNumberFrom
        }
    },
    beforeMount() {
      if (this.token == null)
        this.$router.push('/signUp')
      this.type= this.phoneNumberFrom? 'mobile': 'email';
    },
    methods: {
        //================ PASSWORD FIELD BEGIN ================//
        showPass() {
            this.showPassFlag = false;
            this.hidePassFlag = true;
            this.$refs.CI_password.type = "text";
        },
        hidePass() {
            this.hidePassFlag = false;
            this.showPassFlag = true;
            this.$refs.CI_password.type = "password";
        },
        //================ PASSWORD FIELD END ================//

        //================ CONFIRM PASSWORD FIELD BEGIN ================//
        showConfPass() {
            this.showConfPassFlag = false;
            this.hideConfPassFlag = true;
            this.$refs.CI_confPassword.type = "text";
        },
        hideConfPass() {
            this.hideConfPassFlag = false;
            this.showConfPassFlag = true;
            this.$refs.CI_confPassword.type = "password";
        },
        //================ CONFIRM PASSWORD FIELD END ================//

        //================ SUBMIT COMPELETE INFO FORM BEGIN ================//
        compeleteInfo_submit(e){
            this.CI_inputs = document.querySelectorAll('input');
            this.CI_inputs.forEach(function(item){
                item.focus();
            });
            if(this.$refs.CI_name_error.innerText != "" || this.$refs.CI_password_error.innerText != "" ||
            this.$refs.CI_aliasName_error.innerText != "" || this.$refs.CI_confPassword_error.innerText != "" ||
            this.$refs.CI_nationalCode_error.innerText != ""
            ){
                e.preventDefault();
            }else if( (this.$refs.CI_mobile_error.innerText != "" && this.phoneNumberFrom==null) ||
            this.emailFrom==null && this.$refs.CI_email_error.innerText != ""){
                e.preventDefault();
            }
            else{
                this.sendData();
            }
        },
        sendData(){
                // request with axios for login
                let self = this;
                this.$http({
                    method: 'post',
                    url: '/complete_information',
                    data: {
                    mobile: this.phoneNumber,
                    token: this.token,
                    name: this.faName,
                    email: this.email,
                    alias: this.aliasName_input,
                    password: {
                        password: this.password,
                        confirm: this.confirmPassword
                    },
                    nationalCode: this.nationalCode,
                    type: this.type
                    }
                }).then(function (response) {
                    if (response.status == 200) {
                        self.show_success_alert = true;
                    }
                })
                .catch(function(error) {
                    let errors = error.response.data;
                    self.errorsArray = errorhandler.handleErrors(errors.errors);
                });
        }
        //================ SUBMIT COMPELETE INFO FORM END ================//
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
