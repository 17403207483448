<template>
    <div class="container-fluid" dir="rtl">
        <div class="row">
             <dashBoard_sideBar/>
            <div class="col-lg-9 col-md-12 col-sm-12 main-content">
                <div class="container-fluid bg-white rounded-min my-3 pb-3">
                
                    <div class="row justify-content-center pt-5">
                        <div class="col-12 col-md-6">
                            <video v-if="teachContent.video" class="video-fluid z-depth-1" controls preload="metadata">
                                    <source :src="teachContent.video" type="video/mp4" />
                            </video>
                            <img v-if="teachContent.picture" :src="teachContent.picture" class="video-fluid"/>
                        </div>
                    </div>

                    <div class="mt-3 mx-1 mx-md-5 flex-column">
                        <div class="px-1 px-md-3">
                            <span class="fw-800">{{ teachContent.lead }}</span>
                        </div>
                        <div class="px-1 px-md-3 mt-3">
                            {{teachContent.description}}
                        </div>
                        <div class="px-1 px-md-3 mt-5 text-center text-sm-right">
                            <a v-if="teachContent.attachment" :href="teachContent.attachment" class="btn btn-load-outline my-2 my-md-0 px-5 px-md-3" download>
                                    دانلود فایل پیوست
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="12.0996" y1="15.9998" x2="12.0996" y2="4.99976" stroke="#1ABC9C" stroke-width="1.5" />
                                        <line x1="7" y1="18.2498" x2="17" y2="18.2498" stroke="#1ABC9C" stroke-width="1.5" />
                                        <path d="M8.22266 12.1106L12.1117 15.9997L16.0008 12.1106" stroke="#1ABC9C" stroke-width="1.5" />
                                    </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import dashBoard_sideBar from '@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue'

export default {
    name :'teachingDetailPage',
    components:{
        dashBoard_sideBar,
    },
    data() {
        return {
            teachContent : {},
        }
    },
    methods: {
      async getData(post_id){

      const jwt = await localStorage.getItem('jwt');
      await this.$http({
        method: 'get',
        url: '/api/blog/'+post_id+'/show',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.teachContent = data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    },
    watch: {
        '$route.params.post_id': {
            handler: function(post_id) {
                this.getData(post_id);
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rounded-min {
    border-radius: 1.25rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.rounded-row {
    border: 1.5px solid #F0F0F0 !important;
    border-radius: 5px;
}

.btn-load-outline,
.btn-load-outline:hover {
    border-color: #1ABC9C;
    color: #1ABC9C;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.text-primary {
    color: var(--primary-color) !important;
}

.fs-12 {
    font-size: 0.75rem !important;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-800 {
    font-weight: 800;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0px !important;
}

.profile-img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
}

.learn-img {
    width: 14.5rem;
    height: 9.6rem;
}

.video-fluid {
    width: 100%;
    height: 60%;
    border-radius: 1.25rem;
}
</style>