<template>
  <div class="container-fluid" dir="rtl">
    <div class="row justify-content-center ms-0">
      <dashBoard_sideBar />
      <div class=" col-lg-9 col-12 mt-3">
        <customTable :exportFileBtn="true" tableType="reportAdmin" :pagination="true" :nameFilter="true" :existingUserFilter="true" :searchTable="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import customTable from "./../reusable-components/table/customTable.vue";
export default {
  name: "adminReportsPage",
  components: {
    dashBoard_sideBar,
    customTable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
  font-size: 0px !important;
}
</style>
