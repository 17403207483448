<template>
  <div class="container-fluid pe-0" dir="rtl">
    <div class="row">
      <dashBoard_sideBar />
      <div class="col-lg-9 col-12 main-content mt-3">
        <div class="container rounded-min px-0 px-md-5 py-4 bg-white">
          <div class="row">
            <div class="d-flex  justify-content-center">
              <h3 class=" fw-bold w-75">انتقال وجه</h3>
            </div>
            <moneyTransferForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import moneyTransferForm from "@/components/reusable-components/form/moneyTransferForm.vue";
export default {
  name: "moneyTransformPage",
  components: {
    dashBoard_sideBar,
    moneyTransferForm,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
