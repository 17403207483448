<template>
  <div>
    <h1>HOME PAGE</h1>
    <router-link to="signup">
      SignUp
    </router-link>
  </div>
  </template>

<script>
export default {
name: "home"
}
</script>

<style scoped>
  h1{
    text-align: left;
    color: red;
  }
</style>