<template>
  <div class="container-fluid" dir="rtl">
    <div class="row justify-centent-center ms-0" >
      <dashBoard_sideBar />

      <editAccountForm/>

    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import editAccountForm from "./../reusable-components/form/editAccountForm.vue";

export default {
  name: "adminEditAccPage",
  components: {
    dashBoard_sideBar,
    editAccountForm,
  },
};
</script>