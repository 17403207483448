<template>
    <div class="container-fluid" dir="rtl">
        <div class="row">
            <dashBoard_sideBar />
            <div class="col-lg-9 col-md-12 col-sm-12 main-content mt-3">
                <div class="container-fluid rounded-min bg-white">
                    <div class="d-flex flex-column mb-3 pb-5">
                        <div class="d-flex my-3 my-md-0 mx-0 mx-md-5 pt-5 justify-content-between">
                            <router-link :to="{ name: 'adminSupportTicketsPageRoute' }" class="text-primary text-decoration-none border-0">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.1328 11.7256L5.13281 11.7256" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14.083 5.70124L20.133 11.7252L14.083 17.7502" stroke="#34495E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                بازگشت
                            </router-link>
                            <div>شناسه : {{$route.params.msg_id}}</div>
                        </div>
                        <div class="container py-md-3 py-2">
                            <div class="parent rounded-massage mx-0 mx-md-5">
                                <div class="child rounded-massage bg-white">
                                    <img v-if="userMsg.createdBy.avatar" class="img-fluid mx-2 my-3" :src="userMsg.createdBy.avatar" />
                                    <img v-else class="img-fluid mx-2 my-3" :src="require('@/assets/images/forgotPassword-idPic.png')" />
                                    <span class="fw-700 text-primary">{{ userMsg.createdBy.name}}</span>
                                    <div class="container mr-4">
                                        <p class="ml-3 pb-4 lh-base">
                                            {{userMsg.description}}
                                        </p>
                                        <div class="d-flex flex-md-row flex-column justify-content-end my-4">
                                            <a v-if="userMsg.files[0]" :href="userMsg.files[0].src" class="btn noneBg-1-style my-2 my-md-0 me-0 me-md-3" download>
                                                دانلود فایل پیوست
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="12.4824" y1="15.9998" x2="12.4824" y2="4.99976" stroke="#1ABC9C" stroke-width="1.5" />
                                                    <line x1="7.38281" y1="18.2498" x2="17.3828" y2="18.2498" stroke="#1ABC9C" stroke-width="1.5" />
                                                    <path d="M8.60449 12.1106L12.4936 15.9997L16.3827 12.1106" stroke="#1ABC9C" stroke-width="1.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--========================== Begin admin answer part ================================-->

                        <div class="container py-md-3 py-2 ">
                            <div v-for="(replay, index) in userMsg.replays" :key="index" class="parent mt-5 mx-0 mx-md-5 ">
                                <div class=" child bg-white">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="">
                                                <img v-if="replay.createdBy.avatar" class="img-fluid mx-2 my-3" :src="replay.createdBy.avatar" />
                                                <img v-else class="img-fluid mx-2 my-3" :src="require('@/assets/images/forgotPassword-idPic.png')" />
                                                <span class="fw-700 text-primary">{{ replay.createdBy.name }}</span>
                                            </div>
                                            <div class="mx-2">
                                                <span>{{new Date(replay.createdAt).toLocaleString('fa-IR')}}</span>
                                            </div>
                                        </div>
                                        <div class="container mr-4">
                                            <p class="ml-3 pb-4 lh-base break-passage">
                                                {{replay.description}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column text-center p-2">
                                        <div class="d-flex flex-md-row flex-column justify-content-end my-4">
                                            <a v-if="replay.files[0]" :href="replay.files[0].src" class="btn noneBg-1-style my-2 my-md-0 me-0 me-md-3" download>
                                                دانلود فایل پیوست
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="12.4824" y1="15.9998" x2="12.4824" y2="4.99976" stroke="#1ABC9C" stroke-width="1.5" />
                                                    <line x1="7.38281" y1="18.2498" x2="17.3828" y2="18.2498" stroke="#1ABC9C" stroke-width="1.5" />
                                                    <path d="M8.60449 12.1106L12.4936 15.9997L16.3827 12.1106" stroke="#1ABC9C" stroke-width="1.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--========================= Begin user answer part ================================-->
                        <div class="container py-md-3 py-2">
                            <span class="d-block my-2 mx-0 mx-md-5">متن پاسخ</span>
                            <form class="mx-0 mx-md-5 border rounded" @submit="sendAnswer($event)">
                                <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
                                    <p class="mb-0">
                                        {{ $t('serverMessages.'+alertMessage) }}
                                    </p>
                                </div>
                                <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
                                    <p class="m-2">
                                        {{ item.err }}
                                    </p>
                                </div>
                                <div class="form-group col-12">
                                    <div class="input-group border-0 my-2">
                                        <div class="input-group mb-2 mx-3 mt-2">
                                            <textarea
                                            class="form-control bg-white"
                                            @input="required_validation($refs.SMD_answertPassage,$refs.SMD_answertPassage_error,$i18n.locale)"
                                            @focus="required_validation($refs.SMD_answertPassage,$refs.SMD_answertPassage_error,$i18n.locale)"
                                            ref="SMD_answertPassage"
                                            v-model="replayPassage"></textarea>
                                        </div>
                                    </div>
                                    <span class="me-3 text-danger inputStatus-msg" ref="SMD_answertPassage_error">
                                    </span>
                                </div>
                                <div class="d-flex flex-md-row flex-column justify-content-between my-4 mx-3">
                                    <input class="btn noneBg-1-style btn-font" type="file" id="upload" hidden @change="uploadFiles($event)" accept=".png,.jpg,.jpeg,.gif,.svg"/>
                                    <div>
                                        <label for="upload" class="btn noneBg-1-style btn-font fit-size">
                                            بارگزاری فایل پیوست
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <line x1="11.9004" y1="8" x2="11.9004" y2="19" stroke="#1ABC9C" stroke-width="1.5" />
                                                <line x1="17" y1="5.75" x2="7" y2="5.75" stroke="#1ABC9C" stroke-width="1.5" />
                                                <path d="M15.7783 11.8891L11.8892 8.00001L8.00015 11.8891" stroke="#1ABC9C" stroke-width="1.5" />
                                            </svg>
                                        </label>
                                        <div v-if="appendixFiles != ''" class="attach-preview-box">                   
                                            <span @click="appendixFiles = ''" class="fit-size mx-2">&times;</span>
                                            <img src="" ref="appendixFiles" class="img-thumbnail h-100">
                                        </div>
                                    </div>
                                    <button class="btn py-2 my-2 my-md-0 px-4 border-0 text-white first-style fit-size" type="submit">
                                        ارسال
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--modal box begin-->
        <transition name="fade">
            <div class="harvest-modal d-flex" v-if="show_success_alert">
                <div
                    class="question-box text-center p-5 d-flex flex-column align-items-center"
                >
                    <svg
                        width="51"
                        height="51"
                        viewBox="0 0 51 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                    <g clip-path="url(#clip0_978_4115)">
                        <path
                            d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z"
                            fill="#1ABC9C"
                        />
                        <path
                            d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z"
                            fill="#179F84"
                        />
                        <path
                            d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z"
                            fill="white"
                        />
                        <path
                            d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_978_4115">
                        <rect width="51" height="51" fill="white" />
                        </clipPath>
                    </defs>
                    </svg>
                    <h5>پیام شما با موفقیت ارسال شد.</h5>
                    <div class="answer-btn-box w-75 d-flex justify-content-around">
                    <button
                        @click="show_success_alert = false;$router.go(0);"
                        class="btn first-style"
                    >
                        متوجه شدم
                    </button>
                    </div>
                </div>
            </div>
        </transition>
        <!--modal box end-->
    </div>
</template>


<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import formValidation from'@/../mixins/formValidation.js'
import errorhandler from "@/services/error-service";

export default {
  name: "supportMesaasgeDetailPage",
  mixins: [formValidation],
  components: {
    dashBoard_sideBar,
  },
  data() {
    return {
      userMsg : {},
      appendixFiles : '',
      parentReplay : Number,
      show_success_alert: false,
      alertMessage:'',
      replayPassage:'',
      errorsArray:[]
    }
  },
  methods: {
    sendAnswer($e){
        this.SMD_inputs = document.querySelectorAll('textarea');
        this.SMD_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.SMD_answertPassage_error.innerText != ""){
            $e.preventDefault();
        }else{
            $e.preventDefault();
            this.sendReplay();
        }
    },
    async sendReplay(){
        let formData = new FormData();
        formData.append('files[]', this.appendixFiles);
        formData.append('description', this.replayPassage);
        formData.append('parent', this.parentReplay);

        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'post',
            url: '/api/ticket/new/reply',
            headers: {
                Authorization: 'Bearer ' + jwt,
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMessage = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                    this.replayPassage = '';
                    this.appendixFiles = '';
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
    },
      async getData(msg_id){

      const jwt = await localStorage.getItem('jwt');
      await this.$http({
        method: 'get',
        url: '/api/ticket/'+msg_id+'/show',
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      })
          .then((response) => {
            if (response.status == 200) {
              let data = response.data;
              this.userMsg = data;
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    uploadFiles(event){
        this.appendixFiles = event.target.files[0];
        const reader = new FileReader
        reader.onload = e => {
            let imageData = e.target.result;
            this.$refs.appendixFiles.setAttribute("src",imageData);
        }
        reader.readAsDataURL(this.appendixFiles)
        this.$emit('input', this.appendixFiles)
    },
  },
    watch: {
        '$route.params.msg_id': {
            handler: function(msg_id) {
                this.getData(msg_id);
                this.parentReplay = msg_id;
            },
            deep: true,
            immediate: true
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form .form-group input,
textarea,
select {
  border: none;
}
.form-group .form-control {
  border: none;
  box-sizing: border-box;
  background: transparent !important;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px;
}
.parent {
  transform: rotate(2.27deg);
  border: 1.5px solid #1abc9c;
  z-index: 1000;
  border-radius: 5px;
}

.text-primary {
  color: #34495e !important;
}

.rounded-massage {
  border: 1.5px solid #f0f0f0 !important;
  border-radius: 5px;
}

.child {
  transform: rotate(-2.27deg);
  border: 1.5px solid #1abc9c;
  border-radius: 5px;
}

.child img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
}

.contain {
  font-size: 1.5rem;
  font-weight: 700;
}

.border-2 {
  border: 1.5px solid #8d939a;
  box-sizing: border-box;
  /* Box Shadow */
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.05));
}

@media (max-width: 768px) {
 .question-box{
     width: 90% !important;

 }
}
</style>
