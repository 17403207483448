<template>
  <div class="container-fluid" dir="rtl">
    <div class="row justify-centent-center ms-0">
      <dashBoard_sideBar />
      <compeleteProfileForm/>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import compeleteProfileForm from "./../reusable-components/form/compeleteProfileForm.vue";

export default {
  name: "superAdminSettingsPage",
  components: {
    dashBoard_sideBar,
    compeleteProfileForm,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
<style scoped>

</style>
