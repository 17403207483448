<template>
  <div class="container-fluid" dir="rtl">
    <div class="row justify-content-center ms-0">
      <dashBoard_sideBar />
      <div class="col-lg-9 mt-3 col-12 main-content">
        <customTable tableType="paidUsers" :pagination="true" />
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import customTable from "./../reusable-components/table/customTable.vue";

export default {
  name: "paidUsersPage",
  components: {
    dashBoard_sideBar,
    customTable,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rounded-min {
  border-radius: 1.3rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.form-control {
  /* user-select: none; */
  cursor: pointer;
}

.content-wrapper .form-group .form-control {
  border: 1.5px solid #8d939a;
  box-sizing: border-box;
  border-radius: 5px;
}

form label {
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1rem !important;
}

form .form-group input,
textarea,
select {
  border: 1.5px solid #8d939a !important;
  border-radius: 5px !important;
}

form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 4rem 0 2.5rem 0 !important;
}

form input.activate {
  background: #f1f1f1;
}

.angle-icon-bottom {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  left: 0;
  top: 0.5rem;
  z-index: 1000;
  padding-left: 0.3rem;
}

.btn-load-outline,
.btn-load-outline:hover {
  border-color: #1abc9c;
  color: #1abc9c;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.btn-screenshot,
.btn-screenshot:hover {
  background-color: #34495e;
  color: #fafafa;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.form-check-label-left {
  direction: rtl;
  text-align: right;
}
</style>
