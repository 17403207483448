import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './services/route'
import Axios from 'axios';
import store from 'store'
import VueApexCharts from 'vue-apexcharts'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';


Vue.component('date-picker', VuePersianDatetimePicker);
Vue.use(VuePersianDatetimePicker, {
    name: 'custom-date-picker',
    props: {
        format: 'YYYY-MM-DD HH:mm',
        displayFormat: 'jYYYY-jMM-jDD',
        editable: false,
        inputClass: 'form-control my-custom-class-name',
        placeholder: 'روز/ماه/سال',
        altFormat: 'YYYY-MM-DD',
        color: '#00acc1',
        autoSubmit: true,
        //...
        //... And whatever you want to set as default.
        //...
    }
});

Vue.use(VueApexCharts)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.use(VueRouter)
Axios.defaults.baseURL = 'https://admin.ziafat24.com';
Vue.prototype.$http = Axios;



const messages = {
    en: {
        rememberMe: {
            message: 'Remember me',
        },
        forgotPassword: {
            message: 'Forgot password'
        },
        inputLabels: {
            profilePicture: "profile picture",
            phone_email: 'Email or Phone number',
            mobile: 'Mobile number',
            email: 'email',
            password: 'Password',
            confPassword: 'Repeat the password',
            idCode: 'Recommender code',
            name_lastName: 'Name & last-name',
            nationalCode: 'National code',
            supportEmail: 'Email support',
            twoStepPasswored: 'Two-step password',
            oldTwoStepPasswored: 'Old Two-step password',
            repeatTwoStepPassword: 'Repeat two-step password',
            currentPassword: 'Current password',
            current2step: 'Current two step password',
            aliasName: 'alias'
        },
        errors: {
            notNull: 'This field should not be empty',
            field: 'field',
            already_in_used: 'This value is already',
            invalid_national_code: 'Invalid national code'
        },
        signIn: {
            formTitle: 'Sign in',
            submitButton: 'Sign in',
            helpText_prepend: "You don't have an account! You can ",
            helpText_mainLink: 'register ',
            helpText_append: 'here'
        },
        signUp: {
            formTitle: 'Sign up',
            submitButton: 'Sign up',
            helpText_prepend: "if you have an account, you can ",
            helpText_mainLink: 'login ',
            helpText_append: 'here'
        },
        newPassword: {
            formTitle: 'Forget password',
            submitButton: 'Change password',
            helpText_prepend: "You do not have an account! You can ",
            helpText_mainLink: 'register ',
            helpText_append: 'here'
        },
        compeleteInfo: {
            formTitle: 'Complete information',
            submitButton: 'Submit information'
        },
        verificationCode: {
            formTitle: 'Validation',
            validationAlert_prepend: 'five-digit validation code to number',
            validationAlert_append: 'sent',
            helpText_mainLink: 'Resend the code',
            submitButton: 'Sign in'
        },
        twoStepLogin: {
            formTitle: 'Forget password',
            secondPassTitle: 'Two-step password',
            submitButton: 'Change password',
        },
        changePassword: {
            formTitle: 'Change password',
            submitButton: 'Change password',
        }
    },

    fa: {
        rememberMe: {
            message: 'مرا به خاطر بسپار',
        },
        forgotPassword: {
            message: 'فراموشی رمز عبور'
        },
        inputLabels: {
            profilePicture: "تصویر پروفایل",
            phone_email: 'ایمیل یا شماره موبایل',
            email: 'ایمیل',
            username: 'نام کاربری',
            mobile: 'شماره همراه',
            password: 'رمز عبور',
            confPassword: 'تکرار رمز عبور',
            idCode: 'کد معرف',
            name_lastName: 'نام و نام خانوادگی',
            nationalCode: 'کدملی / شماره اتباع',
            supportEmail: 'ایمیل پشتیبانی',
            twoStepPasswored: 'رمز عبور دو مرحله ای',
            repeatTwoStepPassword: 'تکرار رمز عبور دو مرحله ای',
            currentPassword: 'رمز عبور فعلی',
            current2step: 'رمز عبور دو مرحله ای فعلی',
            aliasName: 'نام مستعار'
        },
        errors: {
            notNull: 'این فیلد نباید خالی باشد',
            field: 'برای فیلد',
            already_in_used: 'این مقدار قبلا استفاده شده است',
            returned_empty_data: 'هیچ داده ای وجود ندارد',
            invalid_national_code: 'کد ملی نامعتبر است'
        },
        signIn: {
            formTitle: 'ورود',
            submitButton: 'ورود',
            helpText_prepend: "حساب ندارید میتوانید از اینجا ",
            helpText_mainLink: 'ثبت نام ',
            helpText_append: 'کنید'
        },
        signUp: {
            formTitle: 'ثبت نام',
            submitButton: 'ثبت نام',
            helpText_prepend: "اگر حساب دارید میتوانید از اینجا ",
            helpText_mainLink: 'وارد ',
            helpText_append: 'شوید'
        },
        newPassword: {
            formTitle: 'فراموشی رمز عبور',
            submitButton: 'تغییر رمز عبور',
            helpText_prepend: "حساب ندارید! میتوانید از اینجا ",
            helpText_mainLink: 'ثبت نام ',
            helpText_append: 'کنید'
        },
        compeleteInfo: {
            formTitle: 'تکمیل اطلاعات',
            submitButton: 'ثبت اطلاعات'
        },
        verificationCode: {
            formTitle: 'اعتبار سنجی',
            validationAlert_prepend: 'کد پنج رقمی اعتبار سنجی به شماره ',
            validationAlert_append: 'ارسال شد.',
            helpText_mainLink: 'ارسال مجدد کد',
            submitButton: 'ورود'
        },
        twoStepLogin: {
            formTitle: 'فراموشی رمز عبور',
            secondPassTitle: 'رمز دو مرحله ای',
            submitButton: 'تغییر رمز عبور',
        },
        changePassword: {
            formTitle: 'تغییر رمز عبور',
            submitButton: 'تغییر رمز عبور',
        },
        wallet_transactions: {
            deposit: 'انتقال به حساب من',
            income: 'درآمد',
            withdraw: 'برداشت',
            transfer: 'انتقال از حساب من'
        },
        serverMessages: {
            "inventory_not_enough": "مبلغ وارد شده از موجودی کیف پول شما بیشتر است",
            "time_out": "زمان ارسال کد به پایان رسیده است",
            "not_allow": "مجاز به وارد کردن کد معرف خودتان نمی باشید",
            "Invalid credentials": "کلمه عبور یا نام کاربری اشتباه است",
            "invalid_national_code": "کد ملی نامعتبر است",
            "account_disabled": "حساب کاربری شما غیرفعال شده است با مدیر سایت تماس بگیرید",
            "account_deleted": "حساب کاربری شما پاک شده است",
            "request_pre_determined": "این درخواست قبلا پاسخ داده شده است",
            "request_invalid": "درخواست نامعتبر است",
            "no_reject_reason": "دلیل رد درخواست را مشخص کنید",
            "already_set": "رمز دوم شما از پیش تعیین شده است",
            "less_than_min_withdraw": "موجودی کیف پول از حداقل میزان برداشت کمتر است",
            "Validation Failed": "ارزشیابی با خطا مواجه شده است",
            "Invalid_Invitation_Code": "کد معرف نامعتبر است",
            "code_incorrect": "کد وارد شده صحیح نمی باشد",
            "already_registered": "شما قبلا ثبت نام کرده اید",
            "user_disabled": "حساب کاربری شما غیرفعال شده است. لطفا با پشتیبانی سایت تماس بگیرید",
            "user_deleted": "حساب کاربری شما حذف شده است لطفا با پشتیبانی تماس بگیرید",
            "user_not_found": "کاربر پیدا نشد",
            "invalid_invitation_code": "کد معرف نامعتبر است",
            "identifier_disabled": "کد معرف وارد شده نامعتبر است",
            "register_completed_please_sign_in": "ثبت نام شما با موفقیت انجام شد، لطفا وارد شوید",
            "already_not_registered": "چنین کاربری وجود ندارد",
            "successful": "با موفقیت ثبت گردید",
            "incorrect_old_password": "کلمه عبور فعلی شما صحیح نیست",
            "dont_have_second_password": "شما رمز دو مرحله ای ندارید",
            "second_password_incorrect": "رمز دوم خود را اشتباه وارد کرده اید",
            already_withdraw_request: "درخواست قبلی شما هنوز تعیین وضعیت نشده",
            "more_than_max_withdraw": "مقدار درخواستی ار حداکثر مجار بیشتر است",
            "empty_tether_wallet": "آدرس کیف پول را وارد کنید",
            "price_not_valid": "مبلغ نامعتبر است"
        }
    },
};


Vue.config.productionTip = false

VueRouter.prototype.open = function(routeObject) {
    const { href } = this.resolve(routeObject)
    window.open(href, '_blank')
}

const i18n = new VueI18n({
    locale: 'fa',
    messages
});

new Vue({
    render: h => h(App),
    i18n,
    store,
    router: router,
}).$mount('#app');