<template>
  <div>
    <b-form-select
      class="date-filter"
      v-model="selected"
      @change="setDate_onChart($event)"
      :options="options"
    ></b-form-select>
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";
export default {
  name: "chart",
  components: {
    apexchart,
  },
  props:{
    // activityStatistics : Object,
  },
  data() {
    return {
      series: [
        { name: "کلیک", data: [] },
        { name: "بازدید", data: [] },
      ],
      chartOptions: {
        colors: ["#7A59DD", "#FF779B"],
        chart: {
          height: 350,
          type: "line",
          fontFamily: "iranYekan_medium",
          zoom: {
            enabled: false,
          },
          // methods:{
          //   chart.addXaxisAnnotation({
          //     x: 40,
          //     label: {
          //       text: 'Lorem Ipsum'
          //     },
          //   })
          // }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: 'category',
          categories: [],
          //type: "datetime",     
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: "", // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            stops: [0, 50, 100],
          },
        },
        legend: {
          position: "top",
        },
        markers: {
          colors: "#fff",
          strokeColors: ["#7A59DD", "#FF779B"],
          strokeWidth: 4,
          strokeOpacity: 1,
        },
      },
      options: [
        { value: "month", text: "30 روز گذشته" },
        { value: "week", text: "7 روز گذشته" },
      ],
      selected: "week",

      month: ["روز5م", "روز10م", "روز15م", "روز20م", "روز25م", "روز30م"],
      week: [
        // "شنبه",
        // "یکشنبه",
        // "دوشنبه",
        // "سه شنبه",
        // "چهارشنبه",
        // "پنجشنبه",
        // "جمعه",
      ],
      userStatus: {
        activity_statistics: {
          monthly: {
            monthlyClick: [],
            monthlyView: [],
          },
          weekly: {
            weeklyClick: [],
            weeklyView: [],
          },
          days: {
            weekly: [],
            monthly: [],
          }
        },
      },
    };
  },
  methods: {
    setDate_onChart(e) {
      if (e == "week") {
        this.selected = "week"
        this.chartOptions = {
          xaxis: {
            categories: this.week,
          },
        };
        this.series.length = 0;
        this.series = [
          { name: "کلیک", data: this.userStatus.activity_statistics.weekly.weeklyClick },
          { name: "بازدید", data: this.userStatus.activity_statistics.weekly.weeklyView },
        ];
      } else {
        this.selected = "month"
        this.chartOptions = {
          xaxis: {
            categories: this.month,
          },
        };

        this.series.length = 0;
        this.series = [
          { name: "کلیک", data: this.userStatus.activity_statistics.monthly.monthlyClick },
          { name: "بازدید", data: this.userStatus.activity_statistics.monthly.monthlyView },
        ];

      }

    },
    getChartData(){
    // this.chartOptions.xaxis.categories = ['aaa','aa','a']
    const jwt = localStorage.getItem("jwt");
    const self = this;
    this.$http({
      method: "get",
      url: "/api/home/chart",
      data: {
        daysAgo: 30,
      },
      headers: {
        Authorization: "Bearer " + jwt,
      },
    })
      .then(function (response) {
        if (response.status == 200) {
          let data = response.data;
          data.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          for(let index = 0; index < data.length; index++) {
            data[index].createdAt = new Date(data[index].createdAt).toLocaleDateString('fa-IR',{month:'short' , day:'numeric'});
            if(index < 7){
              self.userStatus.activity_statistics.days.weekly.push(data[index].createdAt);

              self.week = self.userStatus.activity_statistics.days.weekly;
              self.chartOptions.xaxis.categories.push(data[index].createdAt);

              self.userStatus.activity_statistics.weekly.weeklyClick.push(data[index].clicks);
              self.userStatus.activity_statistics.weekly.weeklyView.push(data[index].views);

              self.userStatus.activity_statistics.days.monthly.push(data[index].createdAt);
              self.userStatus.activity_statistics.monthly.monthlyClick.push(data[index].clicks);
              self.userStatus.activity_statistics.monthly.monthlyView.push(data[index].views);
            }else{
              self.userStatus.activity_statistics.days.monthly.push(data[index].createdAt);
              
              self.month = self.userStatus.activity_statistics.days.monthly;

              self.userStatus.activity_statistics.monthly.monthlyClick.push(data[index].clicks);
              self.userStatus.activity_statistics.monthly.monthlyView.push(data[index].views);
            }
          }
          self.setDate_onChart('week')
          self.week.reverse();
          self.month.reverse();
          self.chartOptions.xaxis.categories.reverse();
          self.userStatus.activity_statistics.weekly.weeklyClick.reverse();
          self.userStatus.activity_statistics.weekly.weeklyView.reverse();
          self.userStatus.activity_statistics.monthly.monthlyClick.reverse();
          self.userStatus.activity_statistics.monthly.monthlyView.reverse();


          self.series.length = 0;
          self.series = [
            { name: "کلیک", data: self.userStatus.activity_statistics.weekly.weeklyClick },
            { name: "بازدید", data: self.userStatus.activity_statistics.weekly.weeklyView },
          ];

        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  beforeMount() {
    this.getChartData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.date-filter {
  background: #f7f7f7;
  border-radius: 1.1rem;
  border: none;
  padding: 5px 10px;
  width: 25%;
  font-size: 0.75rem;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .date-filter {
  width: 50%;
}
}
</style>
