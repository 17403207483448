<template>
  <div class="container-fluid" dir="rtl">
    <div class="row">
      <dashBoard_sideBar />
      <div class="col-lg-9 col-md-12 col-sm-12 pe-0 main-content">
        <div class="container-fluid pe-0">
          <div class="row mt-3">
            <div class="table-area">
              <customTable
                tableType="subset"
                :nameFilter="true"
                :searchTable="true"
                :showSubsetCount="true"
                :levelFilter="true"
                :pagination="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import customTable from "./../reusable-components/table/customTable.vue";

export default {
  name: "subCategoriesPage",
  components: {
    dashBoard_sideBar,
    customTable,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
<style scoped>
.angle-icon-bottom {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  left: 0;
  top: 1.2rem;
  z-index: 1000;
  padding-left: 0.3rem;
}

.inline-right-icons {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  right: 0;
  top: 1.2rem;
  z-index: 1000;
  border-left: 1.5px solid #8d939a;
  padding-left: 0.3rem;
}

.row input {
  font-size: 0.7rem;
  height: 100% !important;
}

.row .card {
  border-radius: 5px;
  border: none;
  height: 4rem;
  font-size: 1rem, 500;
  color: var(--text-color);
}

.row .input-group {
  border: none;
  height: 4rem;
  border-radius: 5px;
}

select option {
  font-size: 0.75rem;
  font-weight: 500 !important;
  background: #fff !important;
}
</style>
