<template>
  <div>
    <div class="bg-danger w-75 m-auto text-white compelete-profile-alert text-center" v-show="alertMsg">
      <p>
        {{ $t('serverMessages.'+alertMsg) }}
      </p>
    </div>
    <div class="bg-danger w-75 m-auto text-white compelete-profile-alert text-center" v-if="!hasSecondPassword">
      <p>
        {{ $t('serverMessages.dont_have_second_password') }}
        <router-link class="text-white" :to="{ name : 'twoStepLoginPageRoute'}">
          <strong>ساخت رمز دو مرحله ای</strong>
        </router-link>
      </p>
    </div>
    <div class="d-flex justify-content-center" dir="rtl">
      <form class="w-75 justify-content-center" @submit="moneyTransfer($event)" >
        <div class="row mt-3 mt-lg-5">
          <div class="form-group col-md-6 pe-0">
            <!--svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E" />
            </svg-->

                        <label for="inputEmail4 " class="mb-2">کد معرف</label>

                        <div class="input-group mb-2">
                            <input @input=" required_validation( $refs.MT_idCode, $refs.MT_idCode_error, $i18n.locale ) " @focus=" required_validation( $refs.MT_idCode, $refs.MT_idCode_error, $i18n.locale ) " ref="MT_idCode" type="text" class="form-control ps-5 bg-white" dir="ltr" v-model="identifierCode" />

                            <svg class="inline-left-icons" width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.1995 7.21085V4.53658H20.4963V0.376709H3.29319V4.53654H9.58953V7.22112C4.5035 7.48118 0.693359 8.54698 0.693359 9.82244C0.693359 11.0979 4.5035 12.1637 9.58953 12.4238V20.7576C10.3683 20.9168 11.1745 21.0001 12.0003 21.0001C12.7517 21.0001 13.4869 20.931 14.1995 20.7988V12.434C19.3913 12.1931 23.3073 11.1155 23.3073 9.82244C23.3073 8.5294 19.3913 7.45183 14.1995 7.21085ZM12.0003 11.4662C6.32698 11.4662 1.72784 10.6115 1.72784 9.55699C1.72784 8.65676 5.0795 7.90197 9.58958 7.70065V10.7584C10.3542 10.8686 11.1246 10.924 11.8946 10.924C12.665 10.924 13.4354 10.8686 14.1995 10.7584V7.69132C18.8159 7.87891 22.2724 8.64251 22.2724 9.55694C22.2723 10.6115 17.6731 11.4662 12.0003 11.4662Z" fill="#34495E" />
                            </svg>
                        </div>
                        <span class="text-danger inputStatus-msg" ref="MT_idCode_error">

                        </span>
                    </div>

                    <div class="form-group col-md-6 pe-0">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E" />
                        </svg>
                        <label for="inputEmail4" class="mb-2">مبلغ واریز(تتر)</label>
                        <div class="input-group mb-2">
                            <input 
                            @input=" float_number_withoutZero_validation( $refs.MT_depositAmount, $refs.MT_depositAmount_error, $i18n.locale ) "
                            @focus=" float_number_withoutZero_validation( $refs.MT_depositAmount, $refs.MT_depositAmount_error, $i18n.locale ) " ref="MT_depositAmount" type="text" class="form-control ps-5 bg-white" dir="ltr" v-model="total" />
                            <svg class="inline-left-icons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8569 3H5.14308C2.85766 3 1.00107 4.84262 1.00107 7.12134L1 9.87555C0.999849 10.2662 1.28859 10.5891 1.66328 10.6402L1.76744 10.6473C2.60598 10.6473 3.2273 11.231 3.2273 12.0011C3.2273 12.7975 2.57944 13.4407 1.76744 13.4407C1.3436 13.4407 1 13.786 1 14.2121V16.8787C1 19.1577 2.85585 21 5.14201 21H18.858C21.1441 21 23 19.1577 23 16.8787V14.2121C23 13.786 22.6564 13.4407 22.2326 13.4407C21.4206 13.4407 20.7727 12.7975 20.7727 12.0011C20.7727 11.2043 21.4209 10.5604 22.2326 10.5604C22.6565 10.5604 23.0002 10.2148 23 9.78867L22.9989 7.12103C22.9989 4.84262 21.1423 3 18.8569 3ZM18.8569 4.54286L19.0286 4.54835C20.3903 4.63579 21.4641 5.75603 21.4641 7.12134L21.4641 9.11691L21.3818 9.13909C20.1416 9.50301 19.2378 10.6432 19.2378 12.0011L19.2429 12.1769C19.317 13.4564 20.1954 14.5142 21.3818 14.8621L21.4651 14.8841V16.8787C21.4651 18.3013 20.3007 19.4571 18.858 19.4571H14.6602V17.9247L14.6532 17.82C14.6023 17.4435 14.2812 17.1532 13.8927 17.1532C13.4689 17.1532 13.1253 17.4986 13.1253 17.9247V19.4571H5.14201L4.97027 19.4517C3.60799 19.3642 2.53488 18.2444 2.53488 16.8787V14.8831L2.61821 14.8621C3.85849 14.4984 4.76218 13.3588 4.76218 12.0011L4.75714 11.8262C4.68344 10.5557 3.81015 9.54859 2.62127 9.21916L2.53386 9.19714L2.53595 7.12164C2.53595 5.69915 3.70101 4.54286 5.14308 4.54286H13.1253V6.51802L13.1323 6.6227C13.1831 6.99924 13.5042 7.28945 13.8927 7.28945C14.3166 7.28945 14.6602 6.94407 14.6602 6.51802V4.54286H18.8569ZM13.8927 8.66085C14.2812 8.66085 14.6023 8.95106 14.6532 9.3276L14.6602 9.43227V14.391C14.6602 14.8171 14.3166 15.1624 13.8927 15.1624C13.5042 15.1624 13.1831 14.8722 13.1323 14.4957L13.1253 14.391V9.43227C13.1253 9.00623 13.4689 8.66085 13.8927 8.66085Z" fill="#8D939A" />
                            </svg>
                        </div>
                        <span class="text-danger inputStatus-msg" ref="MT_depositAmount_error">
                        </span>
                    </div>
                </div>

                <button dir="rtl" type="submit" class="btn btn-lg text-white float-start" :disabled="isTrueInfos || !hasSecondPassword">
                    انتقال وجه
                </button>
            </form>
        </div>

        <!--two step verification code begin-->
        <transition name="fade">
            <div class="container" v-if="isTrueInfos && hasSecondPassword">
                <!--form col begin-->
                <div class="form-box">
                <div class="form">
                    <!--verification code form heading begin-->
                    <div class="form-heading">
                    <div class="text-center">
                        <h3>{{ $t('verificationCode.formTitle') }}</h3>
                    </div>
                    </div>
                    <!--verification code form heading end-->

                    <!--verification code form main begin-->
                    <form @submit.prevent="sendData($event)">
                        <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
                        <p class="mb-0">
                            {{ $t('serverMessages.'+alertMessage) }}
                        </p>
                        </div>
                        <div class="form-group">
                            <p class="text-center ">
                            <span>کد وارد شده به شماره</span>
                            <span class="mx-2">{{userDataLocal.mobile}}</span>
                            <span>را وارد کنید</span>
                            </p>
                            <!--verification code input begin-->
                            <div class="d-flex verification-code-box justify-content-around my-5 w-75 mx-auto" dir="ltr">
                            <input @keyup="goNext($event, 'first')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify1">
                            <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify2">
                            <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify3">
                            <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify4">
                            <input @keyup="goNext($event, 'last')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify5">
                            </div>
                            <!--verification code input end-->

                            <!--two step code input begin-->
                            <p class="text-center">
                                <span>رمز دو مرحله ای خود را وارد کنید.</span>
                            </p>
                            <div class="form-group col-md-6 mx-auto">
                                <div class="input-group mb-2">
                                    <input type="password" class="form-control ps-5 bg-white" dir="ltr" v-model="secondPassword"/>
                                </div>
                            </div>
                            <p class="text-center" dir="rtl">
                                <span>{{showCounter}}</span>
                            </p>
                            <transition name="fade">
                              <p class="text-center resendCode-text" v-if="timeExpired" @click="moneyTransfer($event);timeExpired = false;">
                                  <span>ارسال مجدد کد</span>
                              </p>
                            </transition>
                            <!--two step code input end-->

                            <!--signUp btn begin-->
                            <div class="text-center">
                              <button class="btn first-style" name="submit-money-transfer" type="submit" :disabled="
                              (verify1 != '' && verify2 != '' && verify3 != '' && verify4 != '' && verify5 != '' && secondPassword != '') ? false : true">تایید نهایی</button>
                            </div>
                            <!--signUp btn end-->
                        </div>
                    </form>
                    <!--verification code form main end-->
                </div>
                </div>
                <!--form col end-->
            </div>
        </transition>
        <!--two step verification code end-->

        <!--modal box begin-->
        <div class="container-fluid px-0">
            <transition name="fade">
                <div v-if="show_success_alert" class="harvest-modal d-flex">
                    <transition name="fade">
                        <div v-if="show_success_alert" class="question-box p-5 d-flex flex-column align-items-center">
                            <p class="success-text">
                              عملیات انتقال وجه انجام شد
                            </p>
                            <div class="answer-btn-box w-75 d-flex justify-content-around">
                                <button @click=" show_success_alert = !show_success_alert; $router.push({ name : 'financialReportPageRoute' });" class="btn first-style" >
                                    متوجه شدم
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
        <!--modal box end-->
    </div>
</template>



<script>
import formValidation from "@/../mixins/formValidation.js";

export default {
  name: "moneyTransferForm",
  mixins: [formValidation],
  data() {
    return {
      userDataLocal:'',
      total: "",
      identifierCode: "",
      alertMessage: "",
      alertMsg: "",
      show_success_alert: false,
      hasSecondPassword:Boolean,

      isTrueInfos:false,
      verify1: '',
      verify2: '',
      verify3: '',
      verify4: '',
      verify5: '',
      secondPassword:'',
      showCounter : '',
      timeExpired :false,
      ticker:Number,
      timeInSecs: Number,
    }
  },
  methods: {
    moneyTransfer($e) {
        this.MT_inputs = document.querySelectorAll('input');
        this.MT_inputs.forEach(function(item){
            item.focus();
        });
      if (this.$refs.MT_idCode_error.innerText != "" ||
        this.$refs.MT_depositAmount_error.innerText != "") {
          $e.preventDefault();
      }else{
        $e.preventDefault();
        this.isGetVerificationCode();
      }
    },
      async isGetVerificationCode(){
        let formData = new FormData();
        formData.append('identifierCode', this.identifierCode);
        formData.append('total', this.total);
        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'post',
            url: '/api/financial/new/transfer/verification/request',
            headers: {
            Authorization: 'Bearer ' + jwt
            },
            data: formData
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMsg = '';
                    this.isTrueInfos = true;
                    this.countDown(response.data.expire_time*60);
                }
            })
            .catch((error) => {
              let errors = error.response.data;
              this.alertMsg = errors.msg;
            });
      },
      async sendData(){
        if(this.verify1 != '' && this.verify5 != '' && this.secondPassword != ''){
          let formData = new FormData();
          formData.append('identifierCode', this.identifierCode);
          formData.append('total', this.total);
          formData.append('secondPassword', this.secondPassword);
          formData.append('code', this.verify1+this.verify2+this.verify3+this.verify4+this.verify5);
          let self = this;
          const jwt = localStorage.getItem("jwt");
          this.$http({
            method: "post",
            url: "/api/financial/new/transfer",
            headers: {
              Authorization: "Bearer " + jwt,
            },
            data: formData
          })
            .then(function (response) {
              if (response.status == 200) {
                self.alertMessage = ""; 
                self.show_success_alert = true;
                self.total =
                  self.identifierCode =
                  self.destinationTetherWallet = "";
              }
            })
            .catch(function (error) {
              let errors = error.response.data;
              self.alertMessage = errors.message;
            });
        }else{
            alert("کد وارد شده یا رمز عبور دو مرحله ای اشتباه است")
        }
      },
      goNext($e, position = null) {
        if ( ($e.keyCode >= 48 && $e.keyCode <= 57) || ($e.keyCode >= 97 && $e.keyCode <= 105) ) {
          if(position != 'last')
            $e.target.nextElementSibling.focus();
        } else if ($e.keyCode == 8 || $e.keyCode == 37) {
          if(position != 'first')
            $e.target.previousElementSibling.focus();
        }
      },
      countDown(secs){
        const self = this;
        var timeInSecs = Number(secs);
        var ticker = setInterval( function(){
          var sec = timeInSecs;
          if (sec > 0) {
            timeInSecs = timeInSecs-1; 
          }
          else {
            clearInterval(ticker);
            self.timeExpired = true;
          }
          var mins = Math.floor(sec/60);
          sec = sec%60;

          self.showCounter = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (sec < 10) ? "0" : "" ) + sec;
        }, 1000);
      }
  },
  beforeMount() {
    this.userDataLocal = JSON.parse(localStorage.getItem("user"));
    this.hasSecondPassword = JSON.parse(localStorage.getItem('secondPassword'));
  }
};
</script>

<style >
.desc ul {
  padding: 0;
  width: 70%;
  margin: auto;
}
.desc ul li {
  margin: 10px 0;
  direction: rtl;
  text-align: right;
}

.question-box {
  width: 50%;
  margin: auto;
  background: #fff;
  border-radius: 20px;
}
.success-text,
.question-text {
  font-size: 15px;
}
.harvest-modal {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  right: 0;
}
</style>

<style scoped>
form .form-group input,
textarea,
select {
  border: none;
}

.form-group .form-control {
  border: none;
  box-sizing: border-box;
  background: transparent !important;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px !important;
}

.inline-left-icons {
  margin: 0 0 0 0.7rem !important;
  position: absolute;
  left: 0;
  top: 0.4rem;
  z-index: 10;
  border-right: 1.5px solid #8d939a;
  padding-right: 0.3rem;
}

.inline-right-icons {
  margin: 0 0.7rem 0 0 !important;
  position: absolute;
  right: 0;
  top: 0.4rem;
  z-index: 10;
  border-left: 1.5px solid #8d939a;
  padding-left: 0.3rem;
}

form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 4rem 0 2.5rem 0 !important;
}

.work-with-accBtn {
  width: fit-content;
  padding: auto;
}

ol {
  font-size: 0.75rem;
}
.border-dashed-left {
  border-left: 3px dashed #f5f6fa;
}

.text-wrap {
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .question-box {
  width: 90% !important;
}
}
</style>
