
class ErrorService {
    handleErrors(errorObj){
        let childs = errorObj.children;
        let errorsArr = [];
        for (let child in childs){
            if(childs[child] != null && childs[child].errors != null && childs[child].errors.length != 0)
                errorsArr.push({err:childs[child].errors[0], name:child})
        }
        return errorsArr;
    }
}

export default new ErrorService();