<template>
    <div class="container form-container">
        <!--form col begin-->
        <div class="form-box w-50 mr-0 ml-auto">
            <div class="shape-under-form"></div>
            <div class="form">
                <!--signUp form heading begin-->
                <div class="form-heading">
                    <div class="title">
<!--                        <h3>{{ $t('signUp.formTitle') }}</h3>-->
                    </div>
                    <!--switchLanguage/-->
                </div>
                <div class="form-identifier-photo">
                    <img src="../../assets/images/signUp-idPic.png" alt="sign-up">
                </div>
                <!--signUp form heading end-->

              <div v-for="item in errorsArray" :key="item" class="compelete-profile-alert text-center " v-show="errorsArray">
                <p>
                  {{ item }}
                </p>
              </div>
                <!--signUp form main begin-->
                <form @submit.prevent="signUp_submit($event)" class="form-main ">
                  <div class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="alertMessage">
                <p class="m-2">
                  {{ $t("serverMessages."+alertMessage) }}
                </p>
              </div>
                    <div class="form-group">
                        <!--phone number input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label class="d-inline-flex align-items-baseline" for="phone-number">{{ $t('inputLabels.phone_email') }}
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z" fill="#FF4E4E"/>
                                </svg>
                            </label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="phone-number">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47044 2.00173L6.49706 2.00344C7.02095 2.04687 7.47597 2.30722 7.89133 2.71305C8.68068 3.48398 10.3757 5.73367 10.5036 6.3459C10.6453 7.02891 10.4938 7.47252 10.0424 8.25724L9.93299 8.45064C9.72584 8.82898 9.69417 8.98521 9.75831 9.16409C10.7439 11.5763 12.4259 13.258 14.8106 14.232C15.0364 14.3138 15.2069 14.2669 15.6842 13.9931L15.9332 13.8522C16.6042 13.4842 17.0294 13.3679 17.6565 13.4985C18.2666 13.6264 20.5176 15.3224 21.2873 16.1108C21.6926 16.5257 21.9535 16.9816 21.999 17.5399C22.0469 18.8972 20.3908 20.8082 19.3805 21.3828C18.2317 22.2046 16.7737 22.191 15.139 21.4301C10.8641 19.6513 4.325 13.1539 2.58238 8.88432L2.47807 8.64549C1.81637 7.06399 1.83071 5.69766 2.58898 4.66758C3.28088 3.52176 5.14635 1.94049 6.47044 2.00173ZM6.38962 3.46397L6.30146 3.46849C5.62187 3.55878 4.25903 4.73207 3.80517 5.47834L3.72676 5.59502C3.33578 6.23205 3.3811 7.10687 3.92544 8.3047L4.02947 8.54822C5.785 12.4882 11.8633 18.4822 15.7284 20.0911L15.9412 20.1858C17.0595 20.6598 17.906 20.6388 18.5913 20.1528L18.642 20.1202C18.8541 19.9729 19.3431 19.5285 19.7198 19.0938C20.2489 18.4833 20.5486 17.9239 20.5389 17.6262C20.5283 17.4988 20.4301 17.3271 20.2407 17.1332C19.6685 16.5472 17.5874 14.9791 17.3574 14.9309C17.1399 14.8856 16.9614 14.9465 16.4732 15.2273L16.2276 15.3652C15.5084 15.7531 15.0014 15.8573 14.2851 15.5973C11.5091 14.4643 9.53832 12.4938 8.39328 9.68981C8.12206 8.9401 8.26806 8.40968 8.73971 7.58764L8.85209 7.38993C9.06635 7.00305 9.11169 6.83891 9.07132 6.6442C9.02298 6.41294 7.4559 4.33301 6.86908 3.75988C6.67476 3.57003 6.50402 3.47233 6.37624 3.46174L6.38962 3.46397Z" fill="#34495E"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="phoneEmail_validate($refs.SU_phoneEmail,$refs.SU_phoneEmail_error,$i18n.locale)"
                                @focus="phoneEmail_validate($refs.SU_phoneEmail,$refs.SU_phoneEmail_error,$i18n.locale)"
                                ref="SU_phoneEmail" class="form-control form-input-style" type="text" name="phone-number" placeholder="09xxxxxxxxx" aria-label="Recipient's " aria-describedby="phone-number" v-model="username_input">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="SU_phoneEmail_error">
                            </span>
                        </div>
                        <!--phone number input end-->

                        <!--identifier code input begin-->
                        <div class="input-box" :class="$i18n.locale+'_align'">
                            <label for="identifier-code">{{ $t('inputLabels.idCode') }}</label>
                            <div class="input-group form-InputIcon-gp d-flex align-items-center">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-icon-style" id="identifier-code">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8569 3H5.14308C2.85766 3 1.00107 4.84262 1.00107 7.12134L1 9.87555C0.999849 10.2662 1.28859 10.5891 1.66328 10.6402L1.76744 10.6473C2.60598 10.6473 3.2273 11.231 3.2273 12.0011C3.2273 12.7975 2.57944 13.4407 1.76744 13.4407C1.3436 13.4407 1 13.786 1 14.2121V16.8787C1 19.1577 2.85585 21 5.14201 21H18.858C21.1441 21 23 19.1577 23 16.8787V14.2121C23 13.786 22.6564 13.4407 22.2326 13.4407C21.4206 13.4407 20.7727 12.7975 20.7727 12.0011C20.7727 11.2043 21.4209 10.5604 22.2326 10.5604C22.6565 10.5604 23.0002 10.2148 23 9.78867L22.9989 7.12103C22.9989 4.84262 21.1423 3 18.8569 3ZM18.8569 4.54286L19.0286 4.54835C20.3903 4.63579 21.4641 5.75603 21.4641 7.12134L21.4641 9.11691L21.3818 9.13909C20.1416 9.50301 19.2378 10.6432 19.2378 12.0011L19.2429 12.1769C19.317 13.4564 20.1954 14.5142 21.3818 14.8621L21.4651 14.8841V16.8787C21.4651 18.3013 20.3007 19.4571 18.858 19.4571H14.6602V17.9247L14.6532 17.82C14.6023 17.4435 14.2812 17.1532 13.8927 17.1532C13.4689 17.1532 13.1253 17.4986 13.1253 17.9247V19.4571H5.14201L4.97027 19.4517C3.60799 19.3642 2.53488 18.2444 2.53488 16.8787V14.8831L2.61821 14.8621C3.85849 14.4984 4.76218 13.3588 4.76218 12.0011L4.75714 11.8262C4.68344 10.5557 3.81015 9.54859 2.62127 9.21916L2.53386 9.19714L2.53595 7.12164C2.53595 5.69915 3.70101 4.54286 5.14308 4.54286H13.1253V6.51802L13.1323 6.6227C13.1831 6.99924 13.5042 7.28945 13.8927 7.28945C14.3166 7.28945 14.6602 6.94407 14.6602 6.51802V4.54286H18.8569ZM13.8927 8.66085C14.2812 8.66085 14.6023 8.95106 14.6532 9.3276L14.6602 9.43227V14.391C14.6602 14.8171 14.3166 15.1624 13.8927 15.1624C13.5042 15.1624 13.1831 14.8722 13.1323 14.4957L13.1253 14.391V9.43227C13.1253 9.00623 13.4689 8.66085 13.8927 8.66085Z" fill="#34495E"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="input-divider"></div>
                                <input
                                @input="idCode_validation($refs.SU_idCode,$refs.SU_idCode_error,$i18n.locale)"
                                @focus="idCode_validation($refs.SU_idCode,$refs.SU_idCode_error,$i18n.locale)"
                                ref="SU_idCode" class="form-control form-input-style" type="text" name="identifier-code" aria-label="Recipient's " aria-describedby="identifier-code" v-model="identify_input">
                            </div>
                            <span class="text-danger inputStatus-msg" ref="SU_idCode_error"></span>
                        </div>
                        <!--identifier code input end-->

                        <!--form rememberMe checkBtn begin-->
                        <div class="input-box d-flex align-items-stretch" :class="$i18n.locale+'_justify'">
                            <label class="form-check-label d-flex align-items-stretch" :class="$i18n.locale+'_changeRow'">
                                  <input type="checkbox" class="form-check-input">
                                  <span>{{ $t('rememberMe.message') }}</span>
                            </label>
                        </div>
                        <!--form rememberMe checkBtn end-->

                        <!--signUp btn begin-->
                        <div>
                            <button class="btn btn-style" name="sign-up" type="submit">{{ $t('signUp.submitButton') }}</button>
                        </div>
                        <!--signUp btn end-->
                    </div>
                    <!--help text begin-->
                    <p class="text-center help-text">{{ $t('signUp.helpText_prepend') }}
                      <router-link
                          to="signin">
                        {{ $t('signUp.helpText_mainLink') }}
                      </router-link>
                      {{ $t('signUp.helpText_append') }}</p>
                    <!--help text end-->
                </form>
                <!--signUp form main end-->
            </div>
        </div>
        <!--form col end-->
    </div>
</template>

<script>
import '../../assets/scss/formClasses.css'
import '../../assets/scss/langStyle.css'
import formValidation from './../../../mixins/formValidation.js'
//import switchLanguage from './switchLanguage.vue'
import errorhandler from './../../services/error-service'

export default {
  name: 'signUp',
  mixins: [formValidation],
    components:{
       //switchLanguage,
  },
  data(){
    return{
      username_input: '',
      identify_input: '',
      errorsArray: [],
      alertMessage: "",
    }
  },
  methods: {
    //================ SUBMIT SIGN UP FORM BEGIN ================//
    signUp_submit(){
        this.SU_inputs = document.querySelectorAll('input');
        this.SU_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.SU_phoneEmail_error.innerText == "" && this.$refs.SU_idCode_error.innerText == ""){
          // request with axios for login

          //check email or mobile inserted and add it to data object
          let data = {
            mobile: null,
            email: null,
            InvitationCode: this.identify_input,
          };
          let email_exp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          (email_exp.test(String(this.username_input).toLowerCase()))? data.email = this.username_input: data.mobile = this.username_input;

          let self = this;
          this.$http({
            method: 'post',
            url: '/register',
            data: data
          }).then(function (response) {
            if(response.status == 200) {
              self.alertMessage = '';
              self.errorsArray = [];
              self.$router.push({name:'verifyRoute', params:{phoneNumber:data.mobile, email: data.email, type:'signup'}})
            }
          }).catch(function(error) {
            let errors = error.response;
            if(errors.data){
              if(errors.data.errors != undefined){
                self.alertMessage = 'already_registered';
              }else{
                if(errors.data.msg != undefined)
                  self.alertMessage = errors.data.msg;
                else
                  self.alertMessage = errors.data.message;
              }
            }else{
              self.errorsArray = errorhandler.handleErrors(errors.errors);
            }
          });
        }
    }
    //================ SUBMIT SIGN UP FORM END ================//
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
