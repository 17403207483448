<template>
<div class="container-fluid pe-0" dir="rtl">
    <div class="row">
        <dashBoard_sideBar />
        <div class="col-lg-9 col-12 main-content pe-0 mt-5 pt-3 pt-md-0 mt-md-3">
            <adsenseSettingsForm/>
        </div>
    </div>
</div>

</template>

<script>
import dashBoard_sideBar from "@/components/reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import adsenseSettingsForm from "@/components/reusable-components/form/adsenseSettingsForm.vue";

export default {
  name: "adsensSettingsPage",
  components: {
    dashBoard_sideBar,
    adsenseSettingsForm
  },
};
</script>
