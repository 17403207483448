<template>
  <div class="container-fluid pe-0" dir="rtl">
    <div class="row ">
      <dashBoard_sideBar />
      <div class="col-lg-9 col-12  main-content mt-3">
        <sendTicketForm :categories="categories" />

        <!--frequently asked questions begin-->
        <div class="container mt-2 my-md-4 align-center">
          <div class="row ">
            <div class="col-12 text-center">
              <h2 class="h2">سوالات متداول</h2>
            </div>
          </div>
        </div>

        <div class="container-fluid ">
          <div v-for="(faq, index) in faqArr" :key="index">
            <!--faq collapse begin-->
            <a @click="active_collapse($event)" v-b-toggle :href="'#collapse' + faq.id" @click.prevent class="row bg-white justify-content-around rounded-row align-items-center my-4" >
              <div class="col-12 col-md-10 my-4">
                {{ faq.question }}
              </div>
              <div class="col-12 col-md-1 text-center">
                <svg fill="transparent" height="40" viewbox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M5.58813 14.9174C6.67396 10.2883 10.2883 6.67395 14.9174 5.58813C18.2604 4.80396 21.7396 4.80396 25.0826 5.58813C29.7117 6.67395 33.3261 10.2883 34.4119 14.9174C35.196 18.2604 35.196 21.7396 34.4119 25.0826C33.326 29.7117 29.7117 33.3261 25.0826 34.4119C21.7396 35.196 18.2604 35.196 14.9174 34.4119C10.2884 33.3261 6.67396 29.7117 5.58813 25.0826C4.80396 21.7396 4.80396 18.2604 5.58813 14.9174Z" stroke="#1ABC9C" stroke-width="2.5" ></path>
                  <path d="M24.1663 20H15.833M19.9997 24.1667L19.9997 15.8333" stroke="#1ABC9C" stroke-linecap="round" stroke-width="2.5" ></path>
                </svg>
              </div>
            </a>

            <b-collapse :id="'collapse' + faq.id" class="mt-2">
              <b-card>
                {{ faq.answer }}
              </b-card>
            </b-collapse>
            <!-- faq collapse end-->
          </div>

          <!--frequently asked questions end-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
  import sendTicketForm from "./../reusable-components/form/sendTicketForm.vue";

  export default {
    name: "supportSendTicketPage",
    components: {
      dashBoard_sideBar,
      sendTicketForm,
    },
    data() {
      return {
        faqArr: [],
      };
    },
    methods: {
      active_collapse(e) {
        var target = e.currentTarget;
        target.classList.toggle("active-border");
        target.querySelector("svg").classList.toggle("deactive-svg");
      },
    },
    beforeMount() {
      let self = this;
      const jwt = localStorage.getItem("jwt");
      this.$http({
        method: 'get',
        url: '/api/ticket/initial',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
        }
      }).then(function (response) {
        if (response.status == 200) {
          self.faqArr = response.data.faq;
          self.categories = response.data.categories;
        }
      })
          .catch(function (error) {
            console.log(error)
          });
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .fileLabel {
    display: inline-block;

    padding: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
  }
</style>
<style scoped>
  a {
    color: inherit;
    text-decoration: none;
  }
  .active-border {
    border-color: #1abc9c !important;
  }
  .deactive-svg {
    transform: rotate(45deg);
  }
  .deactive-svg path {
    stroke: #d1d1d1;
  }

  form label {
    font-weight: 400;
    line-height: 2rem;
    margin-left: 1rem !important;
  }

  form label {
    font-weight: 400;
    line-height: 2rem;
    margin-left: 1rem !important;
  }

  form .form-group input,
  textarea,
  select {
    border: 1.5px solid #8d939a !important;
    border-radius: 5px !important;
  }

  .angle-icon-bottom {
    margin: 0 0.7rem 0 0 !important;
    position: absolute;
    left: 0;
    top: 0.5rem;
    z-index: 1000;
    padding-left: 0.3rem;
  }

  .btn-screenshot,
  .btn-screenshot:hover {
    background-color: #34495e !important;
    color: #fafafa;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .rounded-row {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    line-height: 2rem;
    font-size: 1.25rem;
  }

  .btn-font {
    font-size: 1rem !important;
    font-weight: 400 !important;
    margin: 0.5rem 0.25rem 0 0 !important;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem !important;
  }

  .btn-send {
    margin: 0 0 0 0 !important;
    padding: 0.6rem 2rem 0.6rem 2rem !important;
  }
</style>
