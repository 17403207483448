<template>
  <div class="container-fluid px-0" dir="rtl">
    <div class="row ">
      <dashBoard_sideBar/>
      <div class="col-lg-9 col-12 main-conten mt-3">
        <div class="container-fluid pe-0">
          <div class="row bg-white p-3 mb-3 rounded-min">
            <div class="bg-danger w-75 m-auto text-white compelete-profile-alert text-center" v-if="!hasSecondPassword">
              <p>
                {{ $t('serverMessages.dont_have_second_password') }}
                <router-link class="text-white" :to="{ name : 'twoStepLoginPageRoute'}">
                  <strong>ساخت رمز دو مرحله ای</strong>
                </router-link>
              </p>
            </div>
            <div class="bg-danger w-75 m-auto text-white compelete-profile-alert text-center" v-if="alertMsg">
              <p>
                {{ $t('serverMessages.'+alertMsg) }}
              </p>
            </div>
            <div class="col-md-4 col-12 wallet-area border-dashed-left  px-3">
              <div class="d-flex flex-column align-items-center">

                <wallet_ballance :theater="walletBallance_theater" :percent="walletBallance_percent" />

                <div class="work-with-accBtn my-3">
                  <button @click="StartHarvest()" :disabled="walletBallance_percent<100 || isTrueInfos || !hasSecondPassword"
                  class="btn px-4" :class="(walletBallance_percent<100 || isTrueInfos || !hasSecondPassword) ? 'first-style-off' : 'first-style'">
                    درخواست برداشت
                  </button>
                </div>
                <router-link :to="{ name: 'moneyTransformPageRoute' }" class="work-with-accBtn" >
                  <button class="btn first-style px-5 mb-3">انتقال وجه</button>
                </router-link>
              </div>
            </div>

            <div class="col-md-8 col-12 money-transfer-desc p-0 p-md-3 ">
              <div class="container">
                <div class="justify-content-start h-50">
                  <ol class="text-wrap" style="list-style-type: disc">
                    <li class="text-wrap">
                      <p class="text-wrap mr-3">
                        واریزی ها به حساب زمانی انجام خواهد شد که درخواست برداشت
                        خود را از ۲۸ تا 30 ام هر ماه ارسال کنید
                      </p>
                    </li>
                    <li class="text-justify">
                      <p class="text-wrap mr-3">
                        تاریخ واریز کارکرد مستقیم شما از اول تا پنجم هر ماه خواهد
                        بود و در صورتی انجام می شود که حداقل موجودی شما 100 تتر
                        باشد
                      </p>
                    </li>
                    <li class="text-justify">
                      <p class="text-wrap mr-3">
                        جهت دریافت واریزی های تیم خود می بایست حداقل 4000 بازدید
                        در طول ماه در فعالیت های خود داشته باشید در غیر اینصورت
                        این پورسانت برای شما محاسبه نشده و به حساب پشتیبان شما
                        واریز خواهد شد
                      </p>
                    </li>
                  </ol>
                </div>

                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <div  v-if="wallet_code" class="container">
                      <div>
                        <span class="d-block text-justify fw-bold">
                          آدرس کیف پول تتر
                        </span>
                      </div>
                      <div class="d-block mt-3">
                        <span class="text-justify text-wrap fw-bold">
                          {{ wallet_code }}
                        </span>
                      </div>

                      <div class="mt-3 text-md-start text-center">
                        <button class="btn noneBg-2-style" @click="copyClipboard">کپی آدرس</button>
                      </div>
                    </div>
                  </div>
                  <div v-if="wallet_code" class="QR-code-area col-12 col-md-4 my-3 text-center">
                    <!--img src="@/assets/images/QRcode.png" class="img-fluid" /-->
                    <VueQrcode :value="wallet_code" :options="{ width: 200 }"></VueQrcode>
                  </div>
                </div>
              </div>
            </div>

    <!--two step verification code begin-->
    <transition name="fade">
        <div class="container" v-if="isTrueInfos && hasSecondPassword">
            <!--form col begin-->
            <div class="form-box">
            <div class="form">
                <!--verification code form heading begin-->
                <div class="form-heading">
                <div class="text-center">
                    <h3>{{ $t('verificationCode.formTitle') }}</h3>
                </div>
                </div>
                <!--verification code form heading end-->

                <!--verification code form main begin-->
                <form @submit.prevent="sendPayRequest($event)">
                    <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
                      <p class="mb-0">
                          {{ $t('serverMessages.'+alertMessage) }}
                      </p>
                    </div>
                    <div class="form-group">
                        <p class="text-center ">
                        <span>کد وارد شده به شماره</span>
                        <span class="mx-2">{{userDataLocal.mobile}}</span>
                        <span>را وارد کنید</span>
                        </p>
                        <!--verification code input begin-->
                        <div class="d-flex verification-code-box justify-content-around my-5 w-75 mx-auto" dir="ltr">
                        <input @keyup="goNext($event, 'first')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify1">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify2">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify3">
                        <input @keyup="goNext($event)" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify4">
                        <input @keyup="goNext($event, 'last')" type="tel" maxlength="1" pattern="[0-9]" class="text-center" v-model="verify5">
                        </div>
                        <!--verification code input end-->

                        <!--two step code input begin-->
                        <p class="text-center">
                            <span>رمز دو مرحله ای خود را وارد کنید.</span>
                        </p>
                        <div class="form-group col-md-6 mx-auto">
                            <div class="input-group mb-2">
                                <input type="password" class="form-control ps-5 bg-white" dir="ltr" v-model="secondPassword"/>
                            </div>
                        </div>
                        <p class="text-center" dir="rtl">
                            <span>{{showCounter}}</span>
                        </p>
                        <transition name="fade">
                          <p class="text-center resendCode-text" v-if="timeExpired" @click="StartHarvest();timeExpired = false;">
                              <span>ارسال مجدد کد</span>
                          </p>
                        </transition>
                        <!--two step code input end-->

                        <!--signUp btn begin-->
                        <div class="text-center">
                        <button class="btn first-style" name="submit-money-transfer" type="submit" :disabled="
                        (verify1 != '' && verify2 != '' && verify3 != '' && verify4 != '' && verify5 != '' && secondPassword != '') ? false : true">تایید نهایی</button>
                        </div>
                        <!--signUp btn end-->
                    </div>
                </form>
                <!--verification code form main end-->
            </div>
            </div>
            <!--form col end-->
        </div>
    </transition>
    <!--two step verification code end-->

          </div>
          <customTable tableType="transaction" :datePicker="true" :pagination="true" :TAoperationTypeFilter="true"/>
        </div>
      </div>
    </div>

    <!--modal box begin-->
    <transition name="fade">
      <div v-if="noWithdrawal" class="harvest-modal d-flex">
        <div class="question-box p-5 d-flex flex-column text-center align-items-center">
          <p class="question-text">
            به دلیل اینکه درصد کیف پول شما کمتر از 100 است درخواست برداشت امکان پذیر نیست.
          </p>
          <div class="answer-btn-box w-75 d-flex justify-content-around">
            <button @click=" noWithdrawal = !noWithdrawal; " class="btn first-style" >
              متوجه شدم
            </button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="show_success_alert" class="harvest-modal d-flex">
        <div class="question-box p-5 d-flex flex-column text-center align-items-center">
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_978_4115)">
          <path d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z" fill="#1ABC9C"/>
          <path d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z" fill="#179F84"/>
          <path d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z" fill="white"/>
          <path d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_978_4115">
          <rect width="51" height="51" fill="white"/>
          </clipPath>
          </defs>
        </svg>
          <h3>
            درخواست برداشت شما با موفقیت ارسال شد.
          </h3>
          <p class="question-text">
            نتیجه درخواست تا حداکثر 48 ساعت آینده به ایمیل یا شماره موبایل شما ارسال خواهد شد.
          </p>
          <div class="answer-btn-box w-75 d-flex justify-content-around">
            <button @click=" show_success_alert = !show_success_alert; isTrueInfos = !isTrueInfos; $router.go(0);" class="btn first-style" >
              متوجه شدم
            </button>
          </div>
        </div>
      </div>
    </transition>
    <!--modal box end-->
  </div>
</template>

<script>
import dashBoard_sideBar from "./../reusable-components/dashBoard-sideBar/dashBoard-sideBar.vue";
import wallet_ballance from "./../reusable-components/wallet-ballance/wallet-ballance.vue";
import customTable from "./../reusable-components/table/customTable.vue";
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: "financialReportPage",
  components: {
    dashBoard_sideBar,
    wallet_ballance,
    customTable,
    VueQrcode
  },
  data() {
    return {
      userDataLocal:'',
      wallet_code: '',
      walletBallance_theater : 0,
      walletBallance_percent: 0,
      alertMessage:'',
      alertMsg:'',
      isTrueInfos:false,
      noWithdrawal:false,
      show_success_alert:false,
      verify1: '',
      verify2: '',
      verify3: '',
      verify4: '',
      verify5: '',
      secondPassword:'',
      showCounter : '',
      timeExpired :false,
      ticker:Number,
      timeInSecs: Number,
      hasSecondPassword:Boolean,
    };
  },
  methods: {
    StartHarvest(){
      if(this.walletBallance_percent == 100){
        this.isGetVerificationCode();
      }else{
        this.noWithdrawal = !this.noWithdrawal;
      }
    },
    async isGetVerificationCode(){
      const jwt = localStorage.getItem('jwt');
      await this.$http({
          method: 'post',
          url: '/api/financial/new/withdraw/verification/request',
          headers: {
          Authorization: 'Bearer ' + jwt
          }
      })
          .then((response) => {
              if (response.status == 200) {
                  this.alertMsg = '';
                  this.isTrueInfos = true;
                  this.countDown(response.data.expire_time*60);
              }
          })
          .catch((error) => {
              let errors = error.response.data;
              this.alertMsg = errors.msg;
          });
    },
    sendPayRequest(){
      if(this.verify1 != '' && this.verify5 != '' && this.secondPassword != ''){
        //this.userData = JSON.parse(localStorage.getItem("user"));
        const jwt = localStorage.getItem("jwt");
        let self = this;
        // send new request
        this.$http({
          method: "post",
          url: "/api/financial/new/withdraw",
          headers: {
            Authorization: "Bearer " + jwt,
          },
          data:{
            secondPassword : this.secondPassword,
            code : this.verify1+this.verify2+this.verify3+this.verify4+this.verify5
          },
        })
            .then(function (response) {
              if (response.status == 200) {
                self.alertMessage = '';
                self.show_success_alert = !self.show_success_alert;
              }
            })
            .catch(function (error) {
              let errors = error.response.data;
              self.alertMessage = errors.message;
            });
      }else{
            alert("کد وارد شده یا رمز عبور دو مرحله ای اشتباه است")
        }
    },
    copyClipboard() {
      navigator.clipboard.writeText(this.wallet_code);
    },
    goNext($e, position = null) {
      if ( ($e.keyCode >= 48 && $e.keyCode <= 57) || ($e.keyCode >= 97 && $e.keyCode <= 105) ){
        if(position != 'last')
          $e.target.nextElementSibling.focus();
      } else if ($e.keyCode == 8 || $e.keyCode == 37) {
        if(position != 'first')
          $e.target.previousElementSibling.focus();
      }
    },
    countDown(secs){
      const self = this;
      var timeInSecs = Number(secs);
      var ticker = setInterval( function(){
        var sec = timeInSecs;
        if (sec > 0) {
          timeInSecs = timeInSecs-1; 
        }
        else {
          clearInterval(ticker);
          self.timeExpired = true;
        }
        var mins = Math.floor(sec/60);
        sec = sec%60;

        self.showCounter = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (sec < 10) ? "0" : "" ) + sec;
      }, 1000); 
    },
    async getChangeableData(){
      const jwt = localStorage.getItem('jwt');
      await this.$http({
          method: 'get',
          url: '/api/update_changeable_data',
          headers: {
              Authorization: 'Bearer ' + jwt,
          },
      })
          .then((response) => {
              if (response.status == 200) {
                let data = response.data;
                this.walletBallance_theater = data.sumEarning;
                this.walletBallance_percent = data.walletPercent;
              }
          })
          .catch((error) => {
              console.log(error)
          });
    }
  },
  beforeMount() {
    this.getChangeableData()
    this.userDataLocal = JSON.parse(localStorage.getItem('user'));
    this.hasSecondPassword = JSON.parse(localStorage.getItem('secondPassword'));
    this.wallet_code = this.userDataLocal.tetherWallet;
    // this.walletBallance_theater = this.userDataLocal.sumEarning;
    // this.walletBallance_percent = this.userDataLocal.walletPercent;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@media only screen and (max-width: 767px) {
  .border-dashed-left {
    border-left: none !important;
  }
  body {
    backdrop-filter: blur(5px);
  }
}

.desc ul {
  padding: 0;
  width: 70%;
  margin: auto;
}
.desc ul li {
  margin: 10px 0;
  direction: rtl;
  text-align: right;
}
</style>
<style scoped>
.question-box {
  width: 50%;
  margin: auto;
  background: #fff;
  border-radius: 20px;
}
.success-text,
.question-text {
  font-size: 15px;
}
.harvest-modal {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
}

.work-with-accBtn {
  width: fit-content;
  padding: auto;
}

ol {
  font-size: 0.75rem;
}
.border-dashed-left {
  border-left: 3px dashed #f5f6fa;
}

.text-wrap {
  word-wrap: break-word;
}

@media (max-width: 768px) {

 .question-box{
     width: 90% !important;
 }
}
</style>
