<template>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <form class="w-75" @submit.prevent="setAdmin($event)">
                <div class="bg-danger w-100 m-auto text-white text-center py-3 rounded-2 mb-2" v-if="alertMessage">
                  <p class="mb-0">
                      {{ $t('serverMessages.'+alertMessage) }}
                  </p>
                </div>
                <div v-for="item in errorsArray" :key="item" class="bg-danger w-100 m-auto text-white text-center py-2 rounded-2 mb-2" v-show="errorsArray">
                    <p class="m-2">
                    {{ $t('errors.field') }} {{ $t('inputLabels.'+item.name) }} {{ $t('errors.'+item.err) }}
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="form-group col-md-6">
                        <label for="getName" class="mb-2">نام ادمین</label>
                        <div class="input-group mb-2">
                            <input
                                @input="
                  name_validate(
                    $refs.AA_adminName,
                    $refs.AA_adminName_error,
                    $i18n.locale
                  )
                "
                                @focus="
                  name_validate(
                    $refs.AA_adminName,
                    $refs.AA_adminName_error,
                    $i18n.locale
                  )
                "
                                type="text"
                                id="getTitle"
                                class="form-control"
                                ref="AA_adminName"
                                v-model="AA_adminName"
                            />
                        </div>
                        <span class="text-danger" ref="AA_adminName_error"></span>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="username" class="mb-2">نام کاربری</label>
                        <div class="input-group mb-2">
                            <input
                                @input="
                  username_validation(
                    $refs.AA_username,
                    $refs.AA_username_error,
                    $i18n.locale
                  )
                "
                                @focus="
                  username_validation(
                    $refs.AA_username,
                    $refs.AA_username_error,
                    $i18n.locale
                  )
                "
                                class="form-control"
                                dir="ltr"
                                ref="AA_username"
                                v-model="AA_adminUsername"
                            />
                        </div>
                        <span class="text-danger" ref="AA_username_error"></span>
                    </div>
                    <div class="form-group col-md-6 mt-0 mt-4">
                        <label for="password" class="mb-2">رمز عبور</label>
                        <div class="input-group mb-2">
                            <input
                              @input=" !(adminSpecs && !editPassword) ? password_validate($refs.AA_password,$refs.AA_password_error,$i18n.locale) :false"
                              @focus=" !(adminSpecs && !editPassword) ? password_validate($refs.AA_password,$refs.AA_password_error,$i18n.locale) : false"
                              type="password"
                              class="form-control"
                              dir="ltr"
                              ref="AA_password"
                              v-model="AA_adminPassword"
                              :disabled="(adminSpecs && !editPassword) ? changePasswordStatus(AA_adminPassword,$refs.AA_password,$refs.AA_password_error) : false"
                            />
                        </div>

                        <span class="text-danger" ref="AA_password_error"></span>
                        <br v-if="adminSpecs">
                        <input v-if="adminSpecs" type="checkbox" v-model="editPassword"/>
                        <label v-if="adminSpecs" for="password" class="mb-2">تغییر رمز عبور ادمین</label>
                    </div>
                    <div class="form-group col-md-6 mt-0 mt-4">
                        <label for="getNum" class="mb-2">شماره تلفن</label>
                        <div class="input-group mb-2">
                            <input
                                @input="
                  phoneNumber_validation(
                    $refs.AA_phoneNember,
                    $refs.AA_phoneNember_error,
                    $i18n.locale
                  )
                "
                                @focus="
                  phoneNumber_validation(
                    $refs.AA_phoneNember,
                    $refs.AA_phoneNember_error,
                    $i18n.locale
                  )
                "

                                type="text"
                                class="form-control"
                                dir="ltr"
                                ref="AA_phoneNember"
                                v-model="AA_adminMobile"
                            />
                        </div>
                        <span class="text-danger" ref="AA_phoneNember_error"></span>
                    </div>
                    <div class="row justify-content-center pb-5">
                        <div>
                            <h2 class="h3 my-4 mt-md-5">دسترسی ها</h2>
                            <b-alert show dismissible fade variant="info" class="d-flex desc-alert-box my-2">
                              <div>
                                <P class="mb-0">درصورت غیر فعال کردن گزینه نمایش لیست لینک ها گزینه های حذف لینک و ویرایش لینک نیز غیرفعال می شوند.</p>
                                <P class="mb-0">درصورت غیر فعال کردن گزینه نمایش لیست کاربران گزینه های حذف کاربر و ویرایش کاربر و فعال کردن کاربر نیز غیرفعال می شوند.</p>
                              </div>
                            </b-alert>
                            <div class="row">
                                <div v-for="(item, index) in adminPermission" :key="index" class="col-12 col-md-6 col-lg-4 px-0">
                                    <div class="form-check-label-input my-3 my-md-4">
                                        <input
                                            @change="
                        checkPermission(
                          check_adminPermission,
                          $refs.AA_checkPermission_error,
                          $i18n.locale
                        )
                      "
                                            @focus="
                        checkPermission(
                          check_adminPermission,
                          $refs.AA_checkPermission_error,
                          $i18n.locale
                        )
                      "
                                            type="checkbox"
                                            :value="item.value"
                                            v-model="check_adminPermission"
                                            :disabled="(!check_adminPermission.includes('user_list') && (item.value == 'user_enable' || item.value == 'user_delete' || item.value == 'user_edit') ) || 
                                            (!check_adminPermission.includes('ad_list') && (item.value == 'ad_edit' || item.value == 'ad_delete') )"
                                        />
                                        <label class="ml-1">{{ item.permission }}</label>
                                    </div>
                                </div>
                                <span class="text-danger" ref="AA_checkPermission_error"></span>
                            </div>
                            <div class="row" dir="ltr">
                                <div class="col-12 col-md-3 my-md-4">
                                    <button type="submit" class="btn py-2 my-md-0 text-white btn-primary float-left border-0">
                                        ثبت
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!--modal box begin-->
        <transition name="fade">
          <div class="harvest-modal d-flex" v-if="show_success_alert">
                  <div class="question-box p-5 d-flex flex-column align-items-center">
                      <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_978_4115)">
                              <path d="M25.5 51C39.5833 51 51 39.5833 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 39.5833 11.4167 51 25.5 51Z" fill="#1ABC9C" />
                              <path d="M18.9844 37.0029L32.0964 50.1149C42.956 47.219 51.0002 37.3247 51.0002 25.4998C51.0002 25.2585 51.0002 25.0171 51.0002 24.7758L40.7036 15.2837L18.9844 37.0029Z" fill="#179F84" />
                              <path
                                  d="M26.1429 31.211C27.2691 32.3372 27.2691 34.2678 26.1429 35.394L23.8101 37.7268C22.6839 38.853 20.7533 38.853 19.6271 37.7268L9.41104 27.4302C8.28486 26.3041 8.28486 24.3735 9.41104 23.2473L11.7439 20.9145C12.87 19.7883 14.8006 19.7883 15.9268 20.9145L26.1429 31.211Z"
                                  fill="white"
                              />
                              <path
                                  d="M35.0721 13.4335C36.1983 12.3073 38.1289 12.3073 39.255 13.4335L41.5878 15.7663C42.714 16.8925 42.714 18.8231 41.5878 19.9493L23.8907 37.566C22.7645 38.6922 20.8339 38.6922 19.7077 37.566L17.3749 35.2332C16.2487 34.107 16.2487 32.1764 17.3749 31.0502L35.0721 13.4335Z"
                                  fill="white"
                              />
                          </g>
                          <defs>
                              <clipPath id="clip0_978_4115">
                                  <rect width="51" height="51" fill="white" />
                              </clipPath>
                          </defs>
                      </svg>
                      <h5>عملیات با موفقیت انجام شد</h5>
                      <div class="answer-btn-box w-75 d-flex justify-content-around">
                          <button @click="show_success_alert = false; redirect_to();" class="btn first-style">
                              متوجه شدم
                          </button>
                      </div>
                  </div>
                </div>
        </transition>
        <!--modal box end-->
    </div>
</template>


<script>
import formValidation from "@/../mixins/formValidation.js";
import errorhandler from '@/services/error-service'

export default {
  name: "addAdminForm",
  mixins: [formValidation],
  props: {
    adminSpecs : Object,
  },
  data() {
    return {
      adminPermission: [
        {
          permission: "نمایش لیست لینک ها",
          value: "ad_list",
        },
        {
          permission: "حذف لینک",
          value: "ad_delete",
        },
        {
          permission: "ویرایش لینک",
          value: "ad_edit",
        },
        {
          permission: "افزودن لینک جدید",
          value: "ad_new",
        },
        {
          permission: "درخواست پرداخت ها",
          value: "withdraw_list",
        },
        {
          permission: "نمایش لیست تیکت ها",
          value: "ticket_list",
        },
        {
          permission: "نمایش لیست کاربران",
          value: "user_list",
        },
        {
          permission: "ویرایش کاربر",
          value: "user_edit",
        },
        {
          permission: "فعال کردن کاربر",
          value: "user_enable",
        },
        {
          permission: "حذف کاربر",
          value: "user_delete",
        },
      ],
      AA_adminMobile : '',
      AA_adminPassword : '',
      AA_adminName : '',
      AA_adminUsername : '',
      check_adminPermission: [],
      show_success_alert : false,
      alertMessage:'',
      editPassword:true,
      errorsArray:[]
    };
  },

  methods: {
    setAdmin($e){
        this.AA_inputs = document.querySelectorAll('input');
        this.AA_inputs.forEach(function(item){
            item.focus();
        });
        if(this.$refs.AA_username_error.innerText != "" ||
        this.$refs.AA_adminName_error.innerText != "" ||
        this.$refs.AA_password_error.innerText != "" ||
        this.$refs.AA_phoneNember_error.innerText != "" ||
        this.$refs.AA_checkPermission_error.innerText != ""){
            $e.preventDefault();
        }else{
          if(this.adminSpecs){
            this.sendEditedAdmin(this.adminSpecs.adminId)
          }else{
            this.submitAdmin();
          }
        }
    },
    async submitAdmin(){
        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'post',
            url: '/api/admin/new',
            headers: {
                Authorization: 'Bearer ' + jwt,
            },
            data:{
                'mobile': this.AA_adminMobile ,
                'password': this.AA_adminPassword ,
                'name': this.AA_adminName ,
                'username': this.AA_adminUsername ,
                'accesses': this.check_adminPermission,
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMessage = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
    },
      async sendEditedAdmin(admin_id){
        const jwt = localStorage.getItem('jwt');
        await this.$http({
            method: 'put',
            url: '/api/admin/'+admin_id+'/edit',
            headers: {
                Authorization: 'Bearer ' + jwt,
            },
            data:{
                'mobile': this.AA_adminMobile ,
                'password': this.AA_adminPassword ,
                'name': this.AA_adminName ,
                'username': this.AA_adminUsername ,
                'accesses': this.check_adminPermission,
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    this.alertMessage = '';
                    this.errorsArray = [];
                    this.show_success_alert = true;
                }
            })
            .catch((error) => {
                let errors = error.response.data;
                this.alertMessage = errors.message;
                this.errorsArray = errorhandler.handleErrors(errors.errors);
            });
      },
    redirect_to(){
      this.$router.push({ name: "editAdminPageRoute"})
    },
  },
  watch:{
    check_adminPermission:function(values){
      if( (!values.includes('user_list') && (values.includes('user_edit') || values.includes('user_delete') || values.includes('user_enable'))  )  ){
        values.forEach(function(item,index,arr){
          if(item == 'user_edit' || item == 'user_delete' || item == 'user_enable'){
            arr.splice(index,1)
          }
        })
      }else if ( (!values.includes('ad_list') && (values.includes('ad_edit') || (values.includes('ad_delete')) ) ) ){
        values.forEach(function(item,index,arr){
          if(item == 'ad_edit' || item == 'ad_delete'){
            arr.splice(index,1)
          }
        })
      }
    }
  },
  beforeMount() {
      if(this.adminSpecs){
        this.AA_adminName = this.adminSpecs.adminName;
        this.AA_adminUsername = this.adminSpecs.adminUsername;
        this.AA_adminMobile = this.adminSpecs.adminMobile;
        this.check_adminPermission = this.adminSpecs.adminAccess;
        this.AA_adminPassword = this.adminSpecs.adminPassword;
      }
  }
};
</script>

<style scoped>
.form-group .form-control {
  border: none;
  box-sizing: border-box;
}

.input-group {
  border: 1.5px solid #8d939a;
  border-radius: 5px;
}
form label {
  font-weight: 400;
  line-height: 2rem;
  margin:0 10px !important;
}

form button {
  background: #1abc9c !important;
  border-radius: 5px;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin: 1.5rem 0 !important;
}

.form-check-label-left {
  direction: rtl;
  text-align: right;
}

.text-wrap {
  word-wrap: break-word;
}
</style>
