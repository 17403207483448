<template>
    <div class="slider-box">
        <VueSlickCarousel v-bind="settings" v-if="slider_content.length">

            <div class="slide" v-for="(slide, index) in slider_content" :key="index">
                <div v-if="slide != null" class="slide-box">
                  <a target="_blank" @click="redirectToDestination(slide.type, slide.id, $event)">
                    <img v-if="slide.picture" :src="slide.picture" class="mx-auto"/>
                    <img v-else :src="require(`@/assets/images/zarmakaron-company.jpg`)" class="mx-auto"/>
                    <div class="slide-cap">
                      <p class="text-center py-2">{{slide.name}}</p>
                    </div>
                  </a>
                </div>
            </div>

        </VueSlickCarousel>


      <!--temp iframe for store data to destination website (DIY)-->
      <iframe id="receiver" src="https://diy724.com/tiny-house-lighting-design" width="1" height="1">
        <p>Your browser does not support iframes.</p>
      </iframe>
      <iframe id="receiver-auth" src="https://diy724.com/tiny-house-lighting-design" width="1" height="1">
        <p>Your browser does not support iframes.</p>
      </iframe>
      <!--temp iframe for store data to destination website (DIY)-->
    </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name :'carousel_slider',
    components:{
        VueSlickCarousel
    },
  data () {
    return {
        settings:{
            "infinite": true,
            "initialSlide": 2,
            "speed": 1000,
            "slidesToShow": 4,
            "slidesToScroll": 1,
            "swipeToSlide": true,
            "autoplay": true,
            "autoplaySpeed": 3000,
            "centerMode": true,
            "centerPadding": "20px",
            "arrows":true,
            "responsive": [
                {
                "breakpoint": 992,
                    "settings": {
                        "slidesToShow": 3,
                        "slidesToScroll": 3,
                        "infinite": true,
                        "dots": false
                    }
                },
                {
                "breakpoint": 768,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 1,
                        "infinite": true,
                        "dots": false
                    }
                },
                {
                "breakpoint": 530,
                    "settings": {
                        "slidesToShow": 1,
                        "slidesToScroll": 1,
                        "infinite": true,
                        "dots": false
                    }
                },
            ]
        },
        slider_content:[]
    }
  },
  methods: {
      addActivity(type, adId){

        const jwt = localStorage.getItem("jwt");
        // get user data
        // let self = this;
        this.$http({
          method: "post",
          url: "/api/activity/new",
          data: {
            type: type,
            ad: adId
          },
          headers: {
            Authorization: "Bearer " + jwt,
          },
        })
            .then(function (response) {
              if (response.status == 200) {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },

    sendMessage(item, receiver = 'receiver'){
      if(receiver == 'receiver'){
        let receiver = document.getElementById('receiver').contentWindow;
        receiver.postMessage(item, 'https://diy724.com');
      }else{
        let receiverAuth = document.getElementById('receiver-auth').contentWindow;
        receiverAuth.postMessage(item, 'https://diy724.com');
      }
    },

      redirectToDestination(type, adId, e){
        e.stopPropagation()

        //get ads array from localStorage
        let ads = localStorage.getItem('adObj')

        ads = (ads == undefined) ? []:JSON.parse(ads);
        let ctrlFlag = false;
        let localUrl = null;
        console.log(ads)
        if(ads != undefined){
          ads.forEach(storedAd => {
            let storedAdObj = JSON.parse(storedAd);
            if(storedAdObj.adId === adId){
              localUrl = storedAdObj.url
              ctrlFlag = true;
            }
          })
        }
        if(!ctrlFlag){
          const jwt = localStorage.getItem("jwt");
          // get user data
          // let self = this;
          this.$http({
            method: "post",
            url: "/api/activity/new-generate",
            data: {
              ad: adId
            },
            headers: {
              Authorization: "Bearer " + jwt,
            },
          })
              .then(function (response) {
                if (response.status == 200) {
                  console.log(response)

                  //store target ad in local storage
                  let adObj = {
                    'url': response.data.url, 'code':response.data.code, 'adId': adId, 'destination':response.data.destinationUrl
                  };
                  ads.push(JSON.stringify(adObj))
                  localStorage.setItem('adObj', JSON.stringify(ads));
                  //set local url
                  localUrl = response.data.url
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }

        //set local storage in destination web page
        this.sendMessage(localStorage.getItem('adObj'), 'receiver');

        //set bearer code in destination web page
        this.sendMessage('Bearer '+localStorage.getItem('jwt'), 'receiverAuth');

        // remove localStorage
        localStorage.removeItem('adObj');

        // redirect to target
        window.open(localUrl, '_blank').focus();

        // temporary remove ad from slider
        for(let i = 0; i < this.slider_content.length; i++){
          if(this.slider_content[i].id == adId)
            this.slider_content.splice(i, 1);
        }

      }
  },
  beforeMount() {
    // get user data
    const jwt = localStorage.getItem("jwt");
    let self = this;
    this.$http({
      method: "get",
      url: "/api/home/index",
      data: {},
      headers: {
        Authorization: "Bearer " + jwt,
      },
    })
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data.ads)
            self.slider_content = response.data.ads;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.slider-box{
    width:90%;
    height:100%;
    margin:25px auto;
}
.slide-box a{
  text-decoration: none;
}
.slick-slider button::before,
.slick-slider button::before{
    color:#34495E !important;
}
.slick-slider .slick-arrow::before,
.slick-slider .slick-arrow::after{
    font-size:25px !important;
    opacity:1 !important;
}
.slick-slider .slick-list{
  padding:20px !important;
}
.slide .slide-box{
    width:210px;
    height:118px;
    padding:0 10px;
}
.slide .slide-box img{
    border-radius:10px 10px 0 0;
    width:100%;
    height:100%;
}
.slide .slide-box .slide-cap{
    background-color:#1ABC9C;
    color:#fff;
    border-radius:0 0 10px 10px;
}
.slide{
  cursor: pointer;
}
</style>
